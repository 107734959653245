import axios from 'axios';

class MeetingApi {

    static getIcsForMeeting = (meetingId, personType, personId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}meeting/ics/single`, {
            meeting: meetingId,
            personType,
            personId
        })
    };

    static getVCard = (uuid) => {
        return axios.get(`${process.env.REACT_APP_API_URL}contact/${uuid}`, {
            responseType: 'blob'
        })
    }

    static addPresentationToTimetable = (meetingId, investorId) => {
        return axios.post(`${process.env.REACT_APP_API_URL}meeting/addToTimetable/${meetingId}/${investorId}`)
    }

    static removePresentationFromTimetable = (meetingId, investorId) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}meeting/removeFromTimetable/${meetingId}/${investorId}`)
    }

    static getToken = (meetingId, personType, personId) => {
        return axios.get(`${process.env.REACT_APP_API_URL}meeting/token/${meetingId}/${personType}/${personId}`)
    }

    static setZoomSessionId = (meetingId, sessionId) => {
        return axios.put(`${process.env.REACT_APP_API_URL}meeting/zoomSessionId/${meetingId}`, {
            sessionId
        });
    }
}

export default MeetingApi;
