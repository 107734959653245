import {
    AUTHORIZATION_CLOSE_SELECT_TIMEZONE,
    AUTHORIZATION_LOGIN_SUCCESS,
    AUTHORIZATION_LOGOUT,
    AUTHORIZATION_OPEN_SELECT_TIMEZONE,
    AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN,
    AUTHORIZATION_SET_SELECTED_DAY,
    AUTHORIZATION_SET_SELECTED_PERSON,
    AUTHORIZATION_SET_SELECTED_TIMEZONE
} from "../../config/actions";

export const authorizationLoginSuccess = (bearerToken, username, roles) => {
    return {
        type: AUTHORIZATION_LOGIN_SUCCESS,
        bearerToken,
        username,
        roles
    }
};

export const authorizationLogout = () => {
    return {
        type: AUTHORIZATION_LOGOUT
    }
};

export const authorizationSetSelectedPerson = (selectedPersonType, selectedPersonId) => {
    return {
        type: AUTHORIZATION_SET_SELECTED_PERSON,
        selectedPersonType,
        selectedPersonId
    }
};

export const authorizationSetSelectedDay = (selectedDay) => {
    return {
        type: AUTHORIZATION_SET_SELECTED_DAY,
        selectedDay
    }
};

export const authorizationSetSelectedTimezone = (timezone) => {
    return {
        type: AUTHORIZATION_SET_SELECTED_TIMEZONE,
        timezone
    }
};

export const authorizationOpenSelectTimezone = () => {
    document.body.classList.add(['noScroll']);
    return {
        type: AUTHORIZATION_OPEN_SELECT_TIMEZONE
    }
};

export const authorizationCloseSelectTimezone = () => {
    document.body.classList.remove(['noScroll']);
    return {
        type: AUTHORIZATION_CLOSE_SELECT_TIMEZONE
    }
};

export const authorizationSetHasSeenWelcomeScreen = () => {
    return {
        type: AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN
    }
};
