import efDashboard from './store/rootReducer';
import persistState from 'redux-localstorage';
import {compose, createStore} from "redux";

const enhancer = compose(
    persistState('authorization', {key: 'ef-dashboard'})
);

const store = createStore(efDashboard, enhancer);
export {store};
