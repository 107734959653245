const TeleDial = () => (
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 232.4 232.4">
  <g id="MotgF2">
    <g>
      <path class="cls-1" fill="currentColor" d="M130,179.3c.7.2,1.4.4,2.1.5,4.5.9,8.9.9,13.2-.8,8.1-3.1,13.7-8.7,16.8-16.8,1-2.8.7-5.3-1.6-7.4-.2-.2-.3-.4-.5-.5-6.4-5.9-13.4-11.1-20.8-15.8-.5-.3-1-.6-1.5-.8-1.5-.6-2.9,0-4.2.7-1.9,1-3.5,2.5-5,4-.9.9-1.9,1.7-2.9,2.4-1.1.8-2.4.9-3.6,0-.9-.6-1.9-1.3-2.6-2.1-2.6-3.1-5.3-6.2-7.7-9.4-6.1-8.3-10.2-17.5-12.3-27.6-.7-3.5-1.6-6.9-1.2-10.6.2-1.6.6-2.3,2.2-2.7,1.7-.4,3.5-.7,5.2-1,2-.3,4.1-.7,5.9-1.8,1.4-.8,2.1-2,2.2-3.6.3-6.9.7-13.9.9-20.8,0-2.3,0-4.6-.7-6.9-.8-2.4-2.2-4.1-4.7-4.9-2.7-.9-5.4-1.5-8.2-1.5-4.7-.1-9.2.5-13.3,2.8-7.4,4.2-11.8,10.6-14.1,18.6-1.2,4.3-1.5,8.7-1.3,13.1.4,7.9,2.1,15.5,4.4,23,3,9.7,7.2,18.9,11.8,27.9,5.8,11.4,12.9,21.8,22,30.8,5.5,5.5,11.8,9.3,19.4,11.1Z"/>
      <path class="cls-1" fill="currentColor" d="M130,179.3c-7.5-1.8-13.9-5.6-19.4-11.1-9-9.1-16.2-19.5-22-30.8-4.6-9-8.8-18.2-11.8-27.9-2.3-7.5-4.1-15.1-4.4-23-.2-4.4,0-8.8,1.3-13.1,2.3-8,6.7-14.4,14.1-18.6,4.1-2.3,8.6-3,13.3-2.8,2.8,0,5.5.7,8.2,1.5,2.5.8,3.9,2.5,4.7,4.9.7,2.2.8,4.5.7,6.9-.3,6.9-.6,13.9-.9,20.8,0,1.6-.8,2.8-2.2,3.6-1.8,1.1-3.8,1.5-5.9,1.8-1.8.3-3.5.6-5.2,1-1.6.4-2,1.1-2.2,2.7-.4,3.6.5,7.1,1.2,10.6,2.2,10,6.2,19.3,12.3,27.6,2.4,3.3,5.1,6.3,7.7,9.4.7.8,1.7,1.4,2.6,2.1,1.2.8,2.5.8,3.6,0,1-.7,2-1.5,2.9-2.4,1.5-1.5,3.1-3,5-4,1.3-.7,2.7-1.3,4.2-.7.5.2,1,.5,1.5.8,7.3,4.7,14.3,9.8,20.8,15.8.2.2.3.4.5.5,2.3,2.1,2.6,4.6,1.6,7.4-3,8.2-8.7,13.7-16.8,16.8-4.3,1.6-8.7,1.7-13.2.8-.7-.2-1.4-.3-2.1-.5Z"/>
    </g>
  </g>
  <g id="Gruppe_85" data-name="Gruppe 85">
    <path id="Pfad_29" data-name="Pfad 29" class="cls-1" fill="currentColor" d="M116.2,232.4C52,232.4,0,180.4,0,116.2,0,52,52,0,116.2,0c64.2,0,116.2,52,116.2,116.2h0c0,64.2-52.1,116.2-116.2,116.2ZM116.2,18.6c-53.9,0-97.6,43.7-97.6,97.6,0,53.9,43.7,97.6,97.6,97.6,53.9,0,97.6-43.7,97.6-97.6h0c0-53.9-43.7-97.5-97.6-97.6Z"/>
  </g>
</svg>
)

export default TeleDial;