import {
    AUTHORIZATION_CLOSE_SELECT_TIMEZONE,
    AUTHORIZATION_LOGIN_SUCCESS,
    AUTHORIZATION_LOGOUT,
    AUTHORIZATION_OPEN_SELECT_TIMEZONE,
    AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN,
    AUTHORIZATION_SET_SELECTED_DAY,
    AUTHORIZATION_SET_SELECTED_PERSON,
    AUTHORIZATION_SET_SELECTED_TIMEZONE
} from "../../config/actions";
import moment from 'moment-timezone';

let defaultState = {
    bearerToken: null,
    username: null,
    roles: [],
    selectedPersonType: null,
    selectedPersonId: null,
    selectedDay: null,
    currentTimezone: moment.tz.guess(true),
    showSelectTimezone: true,
    hasSeenWelcomeScreen: false,
    lastLoginAt: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case AUTHORIZATION_LOGIN_SUCCESS:
            return {
                ...state,
                bearerToken: action.bearerToken,
                username: action.username,
                roles: action.roles,
                lastLoginAt: moment(),
                selectedDay: defaultState.selectedDay
            };
        case AUTHORIZATION_SET_SELECTED_PERSON:
            return {
                ...state,
                selectedPersonType: action.selectedPersonType,
                selectedPersonId: action.selectedPersonId
            };
        case AUTHORIZATION_SET_SELECTED_DAY:
            return {
                ...state,
                selectedDay: action.selectedDay
            };
        case AUTHORIZATION_LOGOUT:
            return {
                ...defaultState,
                selectedPersonType: state.selectedPersonType,
                selectedPersonId: state.selectedPersonId,
                currentTimezone: state.currentTimezone,
                showSelectTimezone: state.showSelectTimezone
            };
        case AUTHORIZATION_SET_SELECTED_TIMEZONE:
            return {
                ...state,
                currentTimezone: action.timezone
            };
        case AUTHORIZATION_OPEN_SELECT_TIMEZONE:
            return {
                ...state,
                showSelectTimezone: true
            };
        case AUTHORIZATION_CLOSE_SELECT_TIMEZONE:
            return {
                ...state,
                showSelectTimezone: false
            };
        case AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN:
            return {
                ...state,
                hasSeenWelcomeScreen: true
            };
        default:
            return state;
    }
}
