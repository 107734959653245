const VideoUnmuted = () => (
  <svg id="deactivate_video" xmlns="http://www.w3.org/2000/svg" width="35.55" height="35.55" viewBox="0 0 35.55 35.55" className="VideoUnmuted">
    <path id="Pfad_1020" data-name="Pfad 1020"
          d="M41.3,29.917,45.269,32.1a.83.83,0,0,0,1.208-.69v-8.4a.8.8,0,0,0-1.208-.69L41.3,24.51Z"
          transform="translate(-17.543 -9.439)" fill="currentColor"/>
    <g id="Gruppe_2069" data-name="Gruppe 2069" transform="translate(6.73 11.217)">
      <path id="Pfad_1021" data-name="Pfad 1021"
            d="M24.47,19.5H13.656A1.94,1.94,0,0,0,11.7,21.456V30.6a1.94,1.94,0,0,0,1.956,1.956H24.413A1.94,1.94,0,0,0,26.369,30.6V21.513A1.906,1.906,0,0,0,24.47,19.5Z"
            transform="translate(-11.7 -19.5)" fill="currentColor"/>
    </g>
    <g id="Gruppe_2072" data-name="Gruppe 2072">
      <path id="Pfad_1023" data-name="Pfad 1023"
            d="M17.775,35.55A17.775,17.775,0,1,1,35.55,17.775,17.823,17.823,0,0,1,17.775,35.55Zm0-32.674a14.9,14.9,0,1,0,14.9,14.9A14.9,14.9,0,0,0,17.775,2.876Z"
            fill="currentColor"/>
    </g>
  </svg>
)

export default VideoUnmuted;