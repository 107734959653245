import axios from 'axios';

class AuthorizationApi {
    static login = (username, password) => {
        return axios.post(`${process.env.REACT_APP_API_URL}login`, {
            username,
            password
        });
    };

    static sendLoginToken = (username) => {
        return axios.post(`${process.env.REACT_APP_API_URL}client/loginToken/${username}`);
    }

    static passwordToken = (username) => {
        return axios.post(`${process.env.REACT_APP_API_URL}client/passwordToken`, {
            username
        });
    };

    static checkResetPasswordToken = (token) => {
        return axios.get(`${process.env.REACT_APP_API_URL}client/checkResetPasswordToken/${token}`);
    };

    static resetPassword = (token, password, acceptedPrivacy) => {
        return axios.put(`${process.env.REACT_APP_API_URL}client/resetPasswordByToken/` + token, {
            token, password, acceptedPrivacy
        });
    };
}

export default AuthorizationApi;
