import React from 'react';

class SubmitButton extends React.Component {
    render() {
        const {alignment, children} = this.props;
        return (
            <div className={`c-button submit-button-container text-${alignment ? alignment : 'center'}`}>
                <button type="submit">
                    {children}
                </button>
            </div>
        )
    }
}

export default SubmitButton
