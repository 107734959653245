import { useContext, useEffect, useRef, useState } from "react";
import { ZoomContext } from "../../../context/ZoomContext";
import moment from "moment-timezone";
import classNames from "classnames";
import Send from "../../../../../themes/equityforum/gfx/meeting/Send";
import ZoomHelper from "../../../../../config/zoomHelper";

const Chat = () => {
  const zoomClient = useContext(ZoomContext);
  const messagesElement = useRef(null);

  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isScrollbarActive, setIsScrollbarActive] = useState(false);

  useEffect(() => {
    setCurrentUserId(zoomClient.getCurrentUserInfo()?.userId);
  }, [zoomClient]);

  useEffect(() => {
    setMessages(currentMessages => {
      const chat = zoomClient.getChatClient();
      return chat.getHistory()?.filter((chatMessage) => !currentMessages?.find(m => m.id === chatMessage.id));
    });
  }, [zoomClient]);

  const receiveChatMessage = (chatMessage) => {
    if (messages?.find(m => m.id === chatMessage.id)) {
      setMessages(current => current.map(c => c.id === chatMessage.id ? chatMessage : c));
    } else {
      setMessages(current => [...current, chatMessage]);
    }
  }

  useEffect(() => {
    if (messagesElement.current) {
      messagesElement.current.scrollTo({
        top: messagesElement.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);

  useEffect(() => {
    const checkScrollbar = () => {
      if (messagesElement.current) {
        const isActive = messagesElement.current.scrollHeight > messagesElement.current.clientHeight;
        setIsScrollbarActive(isActive);
      }
    };

    checkScrollbar(); // Check immediately when messages update
    window.addEventListener('resize', checkScrollbar);

    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, []);

  useEffect(() => {
    zoomClient.on('chat-on-message', receiveChatMessage);
    return () => {
      zoomClient.off('chat-on-message', receiveChatMessage);
    };
  }, [zoomClient]);

  const sendChatMessage = () => {
    if (messageInput?.length > 0) {
      const chat = zoomClient.getChatClient();
      chat.sendToAll(messageInput).then(() => {
        setMessageInput('');
      });
    }
  };

  return (
    <div className="Chat">
      <div className={classNames("messages", {"scrollbar-active": isScrollbarActive})} ref={messagesElement}>
        {messages.map((message, mI) => (
          <div key={mI} className={classNames("chatMessage", { 'is-sender': message.sender.userId === currentUserId })}>
            <div className="title-row">
              <div className="sender">
                {ZoomHelper.extractUserInformation(message.sender.name)?.displayName}
              </div>
              <div className="time">
                {moment(message.timestamp).format('HH:mm')}
              </div>
            </div>
            <div className="message">
              {message.message}
            </div>
          </div>
        ))}
      </div>
      <div className="new-message">
        <textarea value={messageInput} onChange={(e) => setMessageInput(e.target.value)} rows={4}
                  placeholder="Your message..."/>
        <button onClick={sendChatMessage}>
          <Send/>
        </button>
      </div>
    </div>
  );
};

export default Chat;
