const CircleCamera = () => (
  <svg id="deactivate_video" xmlns="http://www.w3.org/2000/svg" width="31.799" height="31.799"
       viewBox="0 0 31.799 31.799">
    <path id="Pfad_1020" data-name="Pfad 1020"
          d="M41.3,29.106l3.55,1.955a.742.742,0,0,0,1.081-.617V22.931a.714.714,0,0,0-1.081-.617L41.3,24.269Z"
          transform="translate(-20.049 -10.788)" fill="currentColor"/>
    <g id="Gruppe_2069" data-name="Gruppe 2069" transform="translate(6.02 10.034)">
      <path id="Pfad_1021" data-name="Pfad 1021"
            d="M23.123,19.5H13.449A1.735,1.735,0,0,0,11.7,21.249v8.181a1.735,1.735,0,0,0,1.749,1.749h9.622a1.735,1.735,0,0,0,1.749-1.749V21.3A1.7,1.7,0,0,0,23.123,19.5Z"
            transform="translate(-11.7 -19.5)" fill="currentColor"/>
    </g>
    <g id="Gruppe_2072" data-name="Gruppe 2072">
      <path id="Pfad_1023" data-name="Pfad 1023"
            d="M15.9,31.8A15.9,15.9,0,1,1,31.8,15.9,15.943,15.943,0,0,1,15.9,31.8Zm0-29.227A13.327,13.327,0,1,0,29.227,15.9,13.33,13.33,0,0,0,15.9,2.573Z"
            fill="currentColor"/>
    </g>
  </svg>
)

export default CircleCamera;