import {useContext, useState} from "react";
import {ZoomContext} from "../../../context/ZoomContext";
import IconStop from "../../../../../themes/equityforum/gfx/meeting/IconStop";
import Play from "../../../../../themes/equityforum/gfx/meeting/Play";

const PhoneAsMicrophone = () => {
  const zoomClient = useContext(ZoomContext);

  const [view, setView] = useState('button');
  const [countryCode, setCountryCode] = useState('+49');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCalling, setIsCalling] = useState(false);

  const handleStart = () => {
    setCountryCode('+49');
    setPhoneNumber('');
    setView('form');
  }

  const handleCancel = () => {
    setView('button');
    if (isCalling) {
      const mediaStream = zoomClient.getMediaStream();
      let phoneNumberToCall = phoneNumber?.replace(/^0+/, '');
      mediaStream.cancelInviteByPhone(countryCode, phoneNumberToCall).then((result) => {
        console.log('success', 'canceled invite by phone');
      }).catch(error => {
        console.log('error', 'cancelling invite by phone was not successfull', error);
      });
      setIsCalling(false);
    }
  }

  const startCallOut = () => {
    setIsCalling(true);
    let phoneNumberToCall = phoneNumber?.replace(/^0+/, '');
    const mediaStream = zoomClient.getMediaStream();
    mediaStream.inviteByPhone(countryCode, phoneNumberToCall, zoomClient.getCurrentUserInfo()?.displayName, {
      callMe: true
    }).then((result) => {
      console.log('success', 'invite by phone was successful', result);
    }).catch(error => {
      console.log('error', 'invite by phone was not successful', error);
      setIsCalling(false);
    });

    zoomClient.on('dialout-state-change', (payload) => {
      if (payload?.code === 3) {
        setView('button');
        setIsCalling(false);
      }
    })
  }

  return (
    <div>
      {view === 'button' ? (
        <div className="MicroPhone">
          <button onClick={handleStart}>
            Use Phone as Microphone
          </button>
        </div>
      ) : view === 'form' ? (
        <div className="MicroPhone">
          <label>Country code</label>
          <input className="form-control" value={countryCode} onChange={e => setCountryCode(e.target.value)}
                 placeholder="Country code"/>
          <label>Phone Number</label>
          <input className="form-control" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                 placeholder="Your Phone Number"/>
          <div className="buttoncontainer">
            <button onClick={startCallOut} disabled={isCalling} className="button--call">
            <Play/>Call me
            </button>
            {isCalling ? (
              <div>
                The dial-out will start within a minute.
              </div>
            ) : null}
            <button onClick={handleCancel} className="button--cancel">
              <IconStop/>Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PhoneAsMicrophone;