import React from 'react';
import Left from "./Left";
import Login from "./Login";
import {Route, Switch} from "react-router";
import ForgotPassword from "./ForgotPassword";
import SentToken from "./SentToken";
import ResetPassword from "./ResetPassword";
import ProjectApi from "../../api/ProjectApi";


class Authentication extends React.Component {

    state = {
        someActiveProject: true
    };

    componentDidMount() {
        ProjectApi.someActive()
            .then(response => {
                this.setState({
                    someActiveProject: response.data?.data?.someProjectActive
                });
            });
    }

    render() {
        return (
            <div className="page page-authentication container">
                <Left/>
                <div className="row">
                    <div className="col-authentification col-6 col-md-4 offset-md-1 px-0-md col-lg-3 offset-lg-3">
                        <div className="w-100 authentication-form-container">
                            {this.state.someActiveProject ? (
                                <Switch>
                                    <Route path="/forgotPassword" component={ForgotPassword}/>
                                    <Route path="/sentToken" component={SentToken}/>
                                    <Route path="/passwordReset/:token" component={ResetPassword}/>
                                    <Route path="/login/:token" component={Login}/>
                                    <Route path="/" component={Login}/>
                                </Switch>
                            ) : (
                                <h2 className="noProjectActive c-input text-input-container">
                                    Currently no event available
                                </h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Authentication;
