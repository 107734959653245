import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {meetingSetAudioMuted, meetingSetVideoMuted} from "../../../store/meeting/meetingAction";
import CircleSpeaker from "../../../themes/equityforum/gfx/meeting/CircleSpeaker";
import CircleCamera from "../../../themes/equityforum/gfx/meeting/CircleCamera";
import VideoMuted from "../../../themes/equityforum/gfx/meeting/VideoMuted";
import AudioMuted from "../../../themes/equityforum/gfx/meeting/AudioMuted";


const SelfVideo = ({phoneAsSource}) => {
  const meetingStore = useSelector(state => state.meeting);
  const dispatch = useDispatch();

  return (
    <div className="SelfVideo">
      <div className="buttons">
        <button className={classNames('button button-microphone', meetingStore?.audioMuted ? '' : 'active')} disabled={phoneAsSource}
                onClick={() => dispatch(meetingSetAudioMuted(!meetingStore?.audioMuted))}>
          <CircleSpeaker/>
          <AudioMuted/>
        </button>
        <button className={classNames('button button-camera', meetingStore?.videoMuted ? '' : 'active')}
                onClick={() => dispatch(meetingSetVideoMuted(!meetingStore?.videoMuted))}>
          <CircleCamera/>
          <VideoMuted/>
        </button>
      </div>
    </div>
  )
}

export default SelfVideo;