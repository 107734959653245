import React from 'react';
import ScrollToTop from "../../elements/helpers/ScrollToTop";
import {MeetingsIconPhone, MeetingsIconMail, PrivacyButtonPrevious, PrivacyLogoWhite} from "../../elements/helpers/GfxPaths";
import EquityTheme from "../../elements/helpers/EquityTheme";

class Imprint extends React.Component {
    render() {
        return (
            <div className="page page-imprint">
                <ScrollToTop/>
                <EquityTheme>
                    <button className="button-prevPage" onClick={() => window.history.back()}>
                        <img className="icon" src={PrivacyButtonPrevious} alt=""/>
                            <span>
                            previous Page
                        </span>
                    </button>
                </EquityTheme>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-4  py-lg-5 imprint-orange">
                            <div className="contact-container">
                                <div className="header-imprint">
                                    Imprint
                                </div>
                                <p className="subtitle-imprint">
                                    EF Equity Forum UG as the provider of this website is responsible for the provided content in accordance to § 55.2 RStV.
                                </p>
                                <div className="d-flex flex-wrap flex-lg-nowrap">
                                    <div className="contact-block d-flex">
                                        <div className="logo">
                                            <img src={PrivacyLogoWhite} alt=""/>
                                        </div>
                                        <div className="text">
                                            <p>
                                                EF Equity Forum UG<br/>
                                                <i>(entrepreneurial company with limited liability)</i>
                                            </p>
                                            <p>
                                                Berger Straße 145<br/>
                                                60385 Frankfurt Germany
                                            </p>
                                        </div>
                                    </div>
                                    <div className="buttons">
                                        <div className="contact-row">
                                            <div className="buttons-text">
                                                <img className="icon" src={MeetingsIconPhone} alt=""/>
                                                <div>
                                                    <a href="tel:+496975006625">+49 (0)69 750066 25</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-row">
                                            <div className="buttons-text">
                                                <img className="icon" src={MeetingsIconMail} alt=""/>
                                                <div>
                                                    <a href="mailto:1on1@equityforum.de">1on1@equityforum.de</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-contact">
                                    <p>
                                        <strong>Local court:</strong> Amtsgericht Frankfurt am Main, HRB 104182
                                    </p>
                                    <p>
                                        <strong>Managing director:</strong> Wolfram Koch | VAT.-ID.: DE 304894670
                                    </p>
                                    <p>
                                        <strong>Technical realisation:</strong> Backslash Seven GmbH
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2  py-lg-5 imprint-text">
                            <div className="text">
                                <p className="strong font-big"><strong>Liability and Copyright:</strong></p>
                                <p>The information provided on this website has been compiled with care and is constantly updated. Despite careful control, we cannot guarantee the absence of errors. We therefore exclude any liability or guarantee with regard to the accuracy, completeness and timeliness of the information provided on this website. This applies in particular to websites referred to by hyperlink (“external links”). These are external websites over which we have no control and we are also not responsible for the data protection precautions of the operators of such websites. The information on this website is provided for general information purposes only.</p>
                                <p>In case this website contains links to third-party websites (“external links”) those websites are subject to the liability of the respective operators. We are not responsible for this external contents. By following those links to external websites the user is acting on his own risk (see also court decision, May 12, 1998, "liability for links", Az 312 o 85/98, Amtsgericht Hamburg).</p>
                                <p>All rights reserved. Text, images, graphics, sound and animation as well as their arrangement on this website are protected by copyright and other protective laws. The content of this website may be copied, distributed, changed or made accessible to third parties for commercial purposes only with a written confirmation of EF Equity Forum UG as the provider of this website. Some pages also contain images that are subject to the copyright of third parties.</p>
                                <p className="strong font-big"><strong>© EF Equity Forum UG, Frankfurt am Main, 2020. All rights reserved.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Imprint;
