const IconPeople = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.062" height="29.062" viewBox="0 0 29.062 29.062">
    <g id="Gruppe_3270" data-name="Gruppe 3270" transform="translate(0 0)">
      <path id="Pfad_1289" data-name="Pfad 1289"
            d="M171.293,8.057a4.607,4.607,0,1,0,4.607,4.607,4.607,4.607,0,0,0-4.607-4.607"
            transform="translate(-156.762 -1.101)" fill="currentColor"/>
      <path id="Pfad_1288" data-name="Pfad 1288"
            d="M170.359.446a14.531,14.531,0,1,0,14.53,14.532A14.548,14.548,0,0,0,170.359.446m8.979,22.761V21.491a3.542,3.542,0,0,0-2.49-3.381l-.57-.176a3.541,3.541,0,0,0-2.452.13l-2.065.89a3.542,3.542,0,0,1-2.8,0l-2.065-.89a3.541,3.541,0,0,0-2.452-.13l-.569.176a3.542,3.542,0,0,0-2.491,3.381v1.716a12.2,12.2,0,1,1,17.96,0"
            transform="translate(-155.827 -0.446)" fill="currentColor"/>
    </g>
  </svg>
)

export default IconPeople;