import React from 'react';

class SimpleCheckbox extends React.Component {
    render() {
        const {label, checked, onChange, error} = this.props;

        return (
            <div className={`c-input simple-checkbox-container${error ? ' error': ''}`}>
                <label>
                    <input type="checkbox" checked={checked} onChange={onChange}/>
                    <span className="radioBtn"/>
                    <span dangerouslySetInnerHTML={{__html: label}}/>
                </label>
            </div>
        )
    }
}

export default SimpleCheckbox;
