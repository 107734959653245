import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import {
    MeetingsIconContact,
    MeetingsIconPhone,
    MeetingsIconMail,
    MeetingsIconCopy,
    MeetingsIconContactMobile, MeetingsIconContactEquity
} from "../../elements/helpers/GfxPaths";
import {FormRow} from "react-bootstrap/Form";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import EquityTheme from "../../elements/helpers/EquityTheme";

class ContactWidgetLink extends React.Component {

    state = {
        openContact: false
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                openContact: false
            });
        }
    };

    render() {
        return (
          <Fragment>
              <EigenkapitalTheme>
                <div className={`contact-widget-link d-flex ${this.state.openContact ? "show" : "" }`}
                     onMouseEnter={() => this.setState({openContact: true})}
                     onMouseLeave={() => this.setState({openContact: false})}
                     onClick={() => this.setState({openContact: true})}
                >
                    <img src={MeetingsIconContact} alt=""/>
                    <div className="text text-uppercase flex-grow-1 d-flex flex-wrap align-items-center">
                        <div className="d-none d-md-inline-block first-line">contact </div><div className="text-lowercase">1on1</div> team
                    </div>
                    <div className="contact-container">
                        <div className="contact-row">
                            <img className="icon" src={MeetingsIconPhone} alt=""/>
                            <div className="contact-text">
                                <a href="tel:+496975006625">+49 (0)69 750066 25</a>
                            </div>
                            <button onClick={() => {navigator.clipboard.writeText("+49 (0)69 750066 25")}}>
                                <img className="icon icon-copy" src={MeetingsIconCopy} alt=""/>
                            </button>
                        </div>
                        <div className="contact-row">
                            <img className="icon" src={MeetingsIconMail} alt=""/>
                            <div className="contact-text">
                                <a href="mailto:1on1@equityforum.de">1on1@equityforum.de</a>
                            </div>
                            <button onClick={() => {navigator.clipboard.writeText("1on1@equityforum.de")}}>
                                <img className="icon icon-copy" src={MeetingsIconCopy} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
              </EigenkapitalTheme>
              <EquityTheme>
                  <div className={`contact-widget-link ${this.state.openContact ? "show" : null }`}
                       onMouseEnter={() => this.setState({openContact: true})}
                       onMouseLeave={() => this.setState({openContact: false})}
                       onClick={() => this.setState({openContact: true})}
                  >
                      <img src={MeetingsIconContactEquity} alt=""/>
                      <img className="imgMobile" src={MeetingsIconContactMobile} alt=""/>
                      <div className="contact-container">
                          <div className="contact-row">
                              <div className="contact-text">
                                  <img className="icon" src={MeetingsIconPhone} alt=""/>
                                  <div>
                                      <a href="tel:+496975006626">+49 (0)69 750066 26</a>
                                  </div>
                              </div>
                          </div>
                          <div className="contact-row">
                              <div className="contact-text">
                                  <img className="icon" src={MeetingsIconMail} alt=""/>
                                  <div>
                                      <a href="mailto:1on1@equityforum.de">1on1@equityforum.de</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </EquityTheme>
          </Fragment>
        )
    }
}

export default ContactWidgetLink;
