// Dynamic Base Path
const basePath = `${process.env.PUBLIC_URL}/image/${process.env.REACT_APP_THEME_NAME}`;

//Documents
export const DocumentsButtonSearch = `${basePath}/documents/Search_Button.svg`;
export const DocumentsIconConferenceBook = `${basePath}/documents/conferenceBook.png`;

// Login
export const LoginBackgroundLG = `${basePath}/login/bg-lg.jpg`;
export const LoginBackgroundMD = `${basePath}/login/bg-md.jpg`;
export const LoginBackgroundXS = `${basePath}/login/bg-xs.jpg`;
export const LoginButtonBackToLogin = `${basePath}/login/btn-backToLogin.svg`;
export const LoginButtonBackToLoginMo = `${basePath}/login/btn-backToLogin-mo.svg`;
export const LoginButtonClose = `${basePath}/login/close.svg`;
export const LoginButtonResetPassword = `${basePath}/login/btn-resetPassword-mo.svg`;
export const LoginButtonResetPasswordMo = `${basePath}/login/btn-resetPassword.svg`;
export const LoginButtonSave = `${basePath}/login/btn-save.svg`;
export const LoginButtonSaveMo = `${basePath}/login/btn-save-mo.svg`;
export const LoginButtonSaveXS = `${basePath}/login/btn-save-xs.svg`;
export const LoginButtonSubmitLG = `${basePath}/login/btn-submit-lg.svg`;
export const LoginButtonSubmitXS = `${basePath}/login/btn-submit-xs.svg`;
export const LoginButtonSubmitXSMo = `${basePath}/login/btn-submit-xs-mo.svg`;
export const LoginLogo = `${basePath}/login/logo.svg`;
export const LoginLogo2 = `${basePath}/login/logo2.svg`;
export const LoginLogo2Blue = `${basePath}/login/logo2-blue.svg`;
export const LoginLogoBlue = `${basePath}/login/logo-blue.svg`;
export const LoginLogoFullBlue = `${basePath}/login/logo-fullBlue.svg`;
export const LoginLogoMo = `${basePath}/login/logo-mo.svg`;

// Meetings
export const MeetingsHeader1on1SM = `${basePath}/meetings/meeting-header-1on1-sm.jpg`;
export const MeetingsHeader1on1MD = `${basePath}/meetings/meeting-header-1on1-md.jpg`;
export const MeetingsHeader1on1LG = `${basePath}/meetings/meeting-header-1on1-lg.jpg`;
export const MeetingsHeader1on1XL = `${basePath}/meetings/meeting-header-1on1-xl.jpg`;
export const MeetingsHeaderGroupSM = `${basePath}/meetings/meeting-header-group-sm.jpg`;
export const MeetingsHeaderGroupMD = `${basePath}/meetings/meeting-header-group-md.jpg`;
export const MeetingsHeaderGroupLG = `${basePath}/meetings/meeting-header-group-lg.jpg`;
export const MeetingsHeaderGroupXL = `${basePath}/meetings/meeting-header-1on1-xl.jpg`;
export const MeetingsIconAddToCalendarMD = `${basePath}/meetings/addToCalendar-md.svg`;
export const MeetingsIconCanvas = `${basePath}/meetings/leinwand.svg`;
export const MeetingsIconCanvasGray = `${basePath}/meetings/leinwand-gray.svg`;
export const MeetingsIconCanvasWhite = `${basePath}/meetings/leinwand-whiteblue.svg`;
export const MeetingsIconClock = `${basePath}/meetings/time.svg`;
export const MeetingsIconCloseDetails = `${basePath}/meetings/close-details.svg`;
export const MeetingsIconCloseDetailsMo = `${basePath}/meetings/close-details-blue.svg`;
export const MeetingsIconCloseDetailsMoBl = `${basePath}/meetings/close-details-orange.svg`;
export const MeetingsIconCompanyBlack = `${basePath}/meetings/companyIconBlack.svg`;
export const MeetingsIconCompanyGray = `${basePath}/meetings/companyIconGray.svg`;
export const MeetingsIconCompanyWhite = `${basePath}/meetings/companyIconWhite.svg`;
export const MeetingsIconContact = `${basePath}/meetings/1on1.svg`;
export const MeetingsIconCopy = `${basePath}/meetings/contact/copylink.svg`;
export const MeetingsIconExhibition = `${basePath}/meetings/exhibition.svg`;
export const MeetingsIconHandout = `${basePath}/meetings/document-white.svg`;
export const MeetingsIconJoinActive = `${basePath}/meetings/join-active.svg`;
export const MeetingsIconJoinActiveMo = `${basePath}/meetings/join-active-mo.svg`;
export const MeetingsIconJoinActiveMobile = `${basePath}/meetings/join-active-mobile.svg`;
export const MeetingsIconJoinDetailsActive = `${basePath}/meetings/join-details-active.svg`;
export const MeetingsIconJoinDetailsInactive = `${basePath}/meetings/join-details-inactive.svg`;
export const MeetingsIconJoinInactive = `${basePath}/meetings/join-inactive.svg`;
export const MeetingsIconJoinInactiveMobile = `${basePath}/meetings/join-inactive-mobile.svg`;
export const MeetingsIconMail = `${basePath}/meetings/contact/buttonMail.svg`;
export const MeetingsIconNow = `${basePath}/meetings/contact/now-lg.svg`;
export const MeetingsIconPersonBlue = `${basePath}/meetings/personIconBlue.svg`;
export const MeetingsIconPersonGray = `${basePath}/meetings/personIconGray.svg`;
export const MeetingsIconPersonWhite = `${basePath}/meetings/personIconWhite.svg`;
export const MeetingsIconPhone = `${basePath}/meetings/contact/buttonCall.svg`;
export const MeetingsIconSponsorClose = `${basePath}/meetings/sponsors/close.svg`;
export const MeetingsIconSponsorCloseMo = `${basePath}/meetings/sponsors/close-mo.svg`;
export const MeetingsIconSponsorInfo = `${basePath}/meetings/sponsors/info.svg`;
export const MeetingsIconSponsorWorld = `${basePath}/meetings/sponsors/world.svg`;
export const MeetingsIconVcard = `${basePath}/meetings/vcard.svg`;
export const MeetingsLineLG = `${basePath}/meetings/linie-lg.png`;
export const MeetingsLineMD = `${basePath}/meetings/linie-md.png`;
export const MeetingsLineXL = `${basePath}/meetings/linie-xl.png`;
export const MeetingsLineXS = `${basePath}/meetings/linie-xs.png`;
export const MeetingsLogo2 = `${basePath}/meetings/logo2.svg`;
export const MeetingsStoerer = `${basePath}/meetings/nowStoerer.svg`;
export const MeetingsIconContactMobile = `${basePath}/meetings/contact-mobile.svg`;
export const MeetingsIconContactEquity = `${basePath}/meetings/contact.svg`;

// TimeZonePopup
export const TimeZonePopupButtonSave = `${basePath}/timeZonePopup/SaveButton.svg`;
export const TimeZonePopupButtonSaveMo = `${basePath}/timeZonePopup/SaveButton_Mouseover.svg`;
export const TimeZonePopupButtonStart = `${basePath}/timeZonePopup/StartButton.svg`;
export const TimeZonePopupButtonStartMo = `${basePath}/timeZonePopup/StartButton_Mouseover.svg`;

// TopBar
export const TopBarIconDocuments = `${basePath}/topBar/document-white.svg`;
export const TopBarIconLogout = `${basePath}/topBar/logout.svg`;
export const TopBarIconLogoutMo = `${basePath}/topBar/logout-mo.svg`;
export const TopBarIconMeetings = `${basePath}/topBar/meetings.svg`;
export const TopBarIconMeetingsMo = `${basePath}/topBar/meetings-mo.svg`;
export const TopBarIconProfile = `${basePath}/topBar/profile.svg`;
export const TopBarIconProfileMo = `${basePath}/topBar/profile-mo.svg`;
export const TopBarLogo = `${basePath}/topBar/logo.svg`;
export const TopBarLogoMo = `${basePath}/topBar/logo-mo.svg`;
export const TopBarLogoMobile = `${basePath}/topBar/logo-mobile.svg`;
export const TopBarLogoMobileMo = `${basePath}/topBar/logoMo-mobile.svg`;

// Profile
export const ProfileButtonDontChangePassword = `${basePath}/profile/dont-change-password.svg`;
export const ProfileButtonDontChangePasswordMo = `${basePath}/profile/dont-change-password-mo.svg`;
export const ProfileButtonDontChangePasswordMobile = `${basePath}/profile/dont-change-password-mobile.svg`;
export const ProfileButtonSave = `${basePath}/profile/save.svg`;
export const ProfileButtonSaveMo = `${basePath}/profile/save-mo.svg`;
export const ProfileButtonTestMeetingLG = `${basePath}/profile/start_testmeeting_lg.svg`;
export const ProfileButtonTestMeetingLGMo = `${basePath}/profile/start_testmeeting_lg_mo.svg`;
export const ProfileButtonTestMeetingMD = `${basePath}/profile/start_testmeeting_md.svg`;
export const ProfileButtonTestMeetingMDMo = `${basePath}/profile/start_testmeeting_md_mo.svg`;
export const ProfileButtonTestMeetingXS = `${basePath}/profile/start_testmeeting_xs.svg`;
export const ProfileButtonTestMeetingXSMo = `${basePath}/profile/start_testmeeting_xs_mo.svg`;

// Privacy
export const PrivacyButtonPrevious = `${basePath}/privacy/ButtonPrevious.svg`;
export const PrivacyLogoGrey = `${basePath}/privacy/EF_grey.svg`;
export const PrivacyLogoWhite = `${basePath}/privacy/EF_white.svg`;

// vCard
export const VcardButtonSave = `${basePath}/vcardPopup/SaveButton.svg`;
export const VcardButtonSaveMo = `${basePath}/vcardPopup/SaveButton_Mouseover.svg`;
export const VcardIconClose = `${basePath}/vcardPopup/close.svg`;
export const VcardIconCloseMo = `${basePath}/vcardPopup/close-mo.svg`;

// Welcome
export const WelcomeBackground = `${basePath}/welcome/bg.jpg`;
export const WelcomeLogo = `${basePath}/welcome/logo.svg`;
export const WelcomeLogo2 = `${basePath}/welcome/logo2.svg`;
export const WelcomeVid = `${basePath}/welcome/welcome.mp4`;
export const WelcomeVidLg = `${basePath}/welcome/welcome-lg.mp4`;