import {useContext, useEffect} from "react";
import {ZoomContext} from "../context/ZoomContext";
import {MeetingParticipantsContext} from "../context/MeetingParticipantsContext";
import {largestRect} from "rect-scaler";

const widthExcludingPadding = el => !el ? 0 : el.clientWidth - parseInt(window.getComputedStyle(el).paddingLeft) - parseInt(window.getComputedStyle(el).paddingRight);

const GalleryView = ({visible}) => {
  const zoomClient = useContext(ZoomContext);
  const participants = useContext(MeetingParticipantsContext);

  useEffect(() => {
    const videoContainer = document.getElementById('zoomVideoContainer');
    const otherParticipants = participants
    if (otherParticipants?.length > 0 && videoContainer && visible) {
      const containerWidth = widthExcludingPadding(videoContainer);
      const containerHeight = window.innerHeight - document.querySelector('.top-bar').offsetHeight;
      const calcAndSetChildWidth = () => {
        let {width: newWidth} = largestRect(containerWidth, containerHeight, otherParticipants?.length, 16, 9);
        document.documentElement.style.setProperty('--video-gallery-item-width', `${newWidth}px`);
      }


      window.addEventListener('resize', calcAndSetChildWidth);
      calcAndSetChildWidth();

      return () => {
        window.removeEventListener('resize', calcAndSetChildWidth);
      }
    }

  }, [participants, visible]);

  useEffect(() => {
    const videoContainer = document.getElementById('zoomVideoContainer');
    const setClass = (users) => {
      if (videoContainer) {
        const children = videoContainer.querySelectorAll('.video-outer:not(.activeSpeakerBig)');
        // Remove a specific class from all children
        Array.from(children).forEach(child => {
          child.classList.remove('active');
        });

        // Find a child with a specific node-id and add a class to it
        const targetChild = Array.from(children).find(
          child => child.getAttribute('user-id') === users[0]?.userId?.toString()
        );
        if (targetChild) {
          targetChild.classList.add('active');
        }
      }
    }

    zoomClient.on('active-speaker', setClass);

    return () => {
      zoomClient.off('active-speaker', setClass);
    }
  }, [])

  return null;
}

export default GalleryView;