import React, {Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {DocumentsButtonSearch, DocumentsIconConferenceBook, MeetingsLogo2} from "../../elements/helpers/GfxPaths";
import {connect} from "react-redux";
import moment from 'moment-timezone';
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import ProjectApi from "../../api/ProjectApi";
import {projectLoadSuccess} from "../../store/project/projectAction";
import MeetingApi from "../../api/MeetingApi";
import gfx_plus from '../../themes/equityforum/gfx/documents/plus.svg';
import gfx_minus from '../../themes/equityforum/gfx/documents/minus.svg';
import gfx_stream from '../../themes/equityforum/gfx/documents/stream.svg';

class Documents extends React.Component {
    state = {
        downloadTitle: true,
        companies: [],
        curPerson: null,
        renderIndex: 1
    }

    componentDidMount() {
        const { project, authorization } = this.props;
        if (authorization.bearerToken) {
            const curPerson = project?.projectPersons?.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId);
            this.setState({
                companies: project.projectCompanies?.map((company) => ({
                    ...company,
                    keyId: `${this.state.renderIndex}-${company.id}`
                })),
                curPerson: project?.projectPersons?.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId),
            });
        } else {
            ProjectApi.getProjectDocuments()
              .then(response => {
                  if (response.status === 200 && response.data.success) {
                      this.setState({companies: response.data.data?.map((company) => ({
                              ...company,
                              keyId: company.id
                          }))});
                  }
              })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authorization.selectedPersonType !== this.props.authorization.selectedPersonType || prevProps.project?.projectCompanies?.length !== this.props.project?.projectCompanies?.length) {
            this.setState({
                renderIndex: this.state.renderIndex + 1
            });
        }
        if (prevState.renderIndex !== this.state.renderIndex) {
            const { project, authorization } = this.props;
            const curPerson = project?.projectPersons?.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId);
            this.setState({
                companies: project.projectCompanies?.map((company) => ({
                    ...company,
                    keyId: `${this.state.renderIndex + 1}-${company.id}`
                })),
                curPerson: curPerson,
            });
        }
    }

    handleAddPresentationToTimetable = (meetingId) => {
        MeetingApi.addPresentationToTimetable(meetingId, this.props.authorization.selectedPersonId)
          .then((response) => {
              if (response.data.success) {
                  this.props.projectLoadSuccess(response.data.data.projectPersons, response.data.data.projectDays, response.data.data.projectSponsors, response.data.data.pushNotificationsEnabled, response.data.data.hasEverSetVCard, response.data.data.projectCompanies, response.data.data.projectInfo);
                  this.setState({
                      renderIndex: this.state.renderIndex + 1
                  });
              }
          })
    }

    handleRemovePresentationToTimetable = (meetingId) => {
        MeetingApi.removePresentationFromTimetable(meetingId, this.props.authorization.selectedPersonId)
          .then((response) => {
              if (response.data.success) {
                  this.props.projectLoadSuccess(response.data.data.projectPersons, response.data.data.projectDays, response.data.data.projectSponsors, response.data.data.pushNotificationsEnabled, response.data.data.hasEverSetVCard, response.data.data.projectCompanies, response.data.data.projectInfo);
                  this.setState({
                      renderIndex: this.state.renderIndex + 1
                  });
              }
          })
    }

    renderTimetableButton = (row) => {
        const { authorization } = this.props;
        if (authorization.bearerToken && authorization.selectedPersonType === 'investor') {
            if (!!this.state.curPerson?.meetings?.find(meeting => meeting.meetingId === row.meetingId)) {
                return (
                  <button onClick={() => this.handleRemovePresentationToTimetable(row.meetingId)}
                          className="link-with-hover-box timetableButton">
                      <img src={gfx_minus} alt=""/>
                      <span className="d-none">
                          Remove presentation from your schedule
                      </span>
                  </button>
                )
            } else {
                return (
                  <button onClick={() => this.handleAddPresentationToTimetable(row.meetingId)}
                          className="link-with-hover-box timetableButton">
                      <img src={gfx_plus} alt=""/>
                      <span className="d-none">
                          Add presentation to your schedule
                      </span>
                  </button>
                )
            }
        }
    }

    render() {
        const { project, authorization } = this.props;

        let columns = [
            {
                dataField: 'name',
                text: 'Company',
                sort: true
            },
            {
                dataField: 'presentationAt',
                text: '',
                sort: true,
                formatter: (cell, row) => {
                    if (cell)  {
                        return (
                          <div className="d-flex">
                              <div><span style={{whiteSpace: "nowrap"}}>{moment(cell).format('dddd, DD.MM.YYYY')}</span><span className="d-inline-block d-md-none ml-2"></span><br className="d-none d-md-inline-block"/>
                                {moment(cell).format('HH:mm')} - {moment(row.presentationUntil).format('HH:mm')}
                                  {row.room ? (
                                    <span className="d-none d-md-inline d-lg-none">
                                        {' '}/ {row.room}
                                    </span>
                                  ) : null}
                              </div>
                              <div className="ml-auto d-md-none">
                                  {row.room ?? ''}
                              </div>
                          </div>
                        )
                    } else {
                        return '1on1 only';
                    }
                },
                filterValue: (cell, row) => cell ? `${moment(cell).format('dddd, DD.MM.YYYY')} ${moment(cell).format('HH:mm')} - ${moment(row.presentationUntil).format('HH:mm')}` : '1on1 only',
                sortValue: (cell) => cell ? moment(cell).valueOf() : 0
            },
            {
                dataField: 'room',
                text: '',
                sort: true
            },
            {
                dataField: 'infoSheets',
                text: '',
                formatter: (cell, row) => (
                  <div className="d-flex justify-content-md-end">
                      {row.presentationAt && this.renderTimetableButton(row)}
                      {cell ? JSON.parse(cell).map((document, dI) => (
                        <a href={`${process.env.REACT_APP_API_URL}projectCompany/infosheet/${document.uuid}`}
                           className="link-with-hover-box download-button" key={dI}
                           rel="noopener noreferrer" target="_blank">
                            <span className="d-none">{document.fileName}</span>
                        </a>
                      )) : null}
                      {row.presentationManualLink ? (
                        <a href={row.presentationManualLink} target="_blank" rel="noopener noreferrer" className="presentationManualLink ml-auto ml-md-0">
                            <img src={gfx_stream} alt=""/>
                        </a>
                        ) : null}
                  </div>
                )
            }
        ];

        const rowClasses = (row, rowIndex) => {
            if (authorization.selectedPersonType === 'investor' && !!this.state.curPerson?.meetings?.find(meeting => meeting.meetingId === row.meetingId)) {
                return "isInTimetable"
            }
        };

        const {SearchBar} = Search;

        return (
            <div className="page page-documents">
                <ToolkitProvider
                    keyField="keyId"
                    data={this.state.companies}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <Fragment>
                                <div className="container container-top">
                                    <EigenkapitalTheme>
                                        <div className="row">
                                            <div className="col-logo col-6">
                                                <a href="https://www.deutsche-boerse-cash-market.com/" target="_blank"
                                                   rel="noopener noreferrer">
                                                    <img className="logo2" src={MeetingsLogo2} alt=""/>
                                                </a>
                                            </div>

                                            <div className="col-img col-6 col-md-4 offset-md-1 col-lg-3 offset-lg-0">
                                                <div className="box-with-shadow img-box">
                                                    <img className="icon" src={DocumentsIconConferenceBook} alt=""/>
                                                    <a href="https://www.edisongroup.com/event/deutsches-eigenkapitalforum-ekf-2021/"
                                                       target="_blank" rel="noopener noreferrer">
                                                        Edison Conference Book
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </EigenkapitalTheme>
                                </div>
                                <div className="container container-table">
                                    <div className="row">
                                        <div className="col-6 col-lg-6 offset-lg-0">
                                            <div className="col-search">
                                                <div className="box-with-shadow search-box">
                                                    <SearchBar {...props.searchProps} />
                                                    <img className="icon" src={DocumentsButtonSearch} alt=""/>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4
                                                defaultSorted={[{dataField: 'name', order: 'asc'}]}
                                                rowClasses={rowClasses}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                </ToolkitProvider>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    project: state.project,
    authorization: state.authorization
});
export default connect(mapStateToProps, {
    projectLoadSuccess
})(Documents);
