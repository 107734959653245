const AudioMuted = () => (
<svg id="Ebene_1" className="AudioMuted" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 260.9 260.9">
  <path id="Pfad_1036" data-name="Pfad 1036" class="cls-1" fill="currentColor" d="M130.4,0C58.4,0,0,58.4,0,130.4s58.4,130.4,130.4,130.4c72,0,130.4-58.4,130.4-130.4C260.8,58.4,202.4,0,130.4,0ZM130.4,20.9c28.1,0,55.2,10.8,75.5,30.3L30.6,175.3c-24.8-55.1-.3-119.9,54.8-144.7,14.1-6.4,29.5-9.7,45-9.7ZM130.4,240c-35.2,0-68.3-17-88.8-45.6l178.7-126.5c34.6,49.6,22.4,117.8-27.2,152.4-18.4,12.8-40.3,19.7-62.7,19.7Z"/>
  <g>
    <path className="cls-1" fill="currentColor" d="M172,127.9c0,22.9-18.6,41.5-41.5,41.5s-16.4-2.6-23-6.9l-10.6,7.5c7.7,6.2,17.1,10.3,27.4,11.4v22.5h-34c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2h80.3c3.4,0,6.2-2.8,6.2-6.2s-2.8-6.2-6.2-6.2h-34v-22.5c26.8-3.1,47.7-25.9,47.7-53.5v-12.7c0-2.1-1.1-4-2.8-5.1l-9.6,6.8v11.1Z"/>
    <path className="cls-1" fill="currentColor" d="M88.9,115.1c0-3.4-2.8-6.2-6.2-6.2s-6.2,2.8-6.2,6.2v12.7c0,0,0,.2,0,.3l12.4-8.8v-4.2Z"/>
    <path className="cls-1" fill="currentColor" d="M160.6,127.6v-2.8l-42.6,30.2c3.8,1.7,8,2.7,12.5,2.7,16.6,0,30.1-13.5,30.1-30.1Z"/>
    <path className="cls-1" fill="currentColor" d="M130.4,40.5c-16.6,0-30.1,13.5-30.1,30.1v40.6l60.2-42.6c-1-15.7-14.1-28.2-30.1-28.2Z"/>
  </g>
</svg>
)

export default AudioMuted;