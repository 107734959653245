import NameSettings from "./NameSettings";
import DeviceSettings from "./DeviceSettings";
import PhoneAsMicrophone from "./PhoneAsMicrophone";

const Settings = ({phoneAsSource}) => {
  return (
    <div className="Settings">
      <NameSettings/>
      <DeviceSettings phoneAsSource={phoneAsSource}/>
      <PhoneAsMicrophone/>
    </div>
  )
}

export default Settings;