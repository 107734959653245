const Camera = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.316" height="9.574" viewBox="0 0 15.316 9.574">
    <g id="Video_Active2" transform="translate(0 1.8)">
      <path id="Pfad_1014" data-name="Pfad 1014"
            d="M58.833,3.217A1.024,1.024,0,0,0,57.82,3.2l-2.662,1.45a.494.494,0,0,0-.258.437V8.7a.518.518,0,0,0,.258.437l2.642,1.45a1.077,1.077,0,0,0,.5.119.962.962,0,0,0,.516-.139.992.992,0,0,0,.5-.874V4.131A1.017,1.017,0,0,0,58.833,3.217Z"
            transform="translate(-43.995 -3.905)" fill="currentColor"/>
      <path id="Pfad_1015" data-name="Pfad 1015"
            d="M8.283-1.8H1.291A1.38,1.38,0,0,0,0-.35V6.324a1.38,1.38,0,0,0,1.291,1.45H8.3a1.38,1.38,0,0,0,1.291-1.45V-.35A1.409,1.409,0,0,0,8.283-1.8Z"
            transform="translate(0 0)" fill="currentColor"/>
    </g>
  </svg>
);

export default Camera;