import React from 'react';
import {LoginBackgroundLG, LoginBackgroundMD, LoginBackgroundXS, LoginLogo, LoginLogoBlue, LoginLogoMo ,LoginLogoFullBlue, LoginLogo2, LoginLogo2Blue, LoginButtonClose} from "../../elements/helpers/GfxPaths";
import {Link, NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";

class Left extends React.Component {
    render() {
        return (
            <div className={`left ${this.props.location.pathname.replace('/', '')}`}>
                <div className="bg bg-xs d-block d-md-none" style={{backgroundImage: `url(${LoginBackgroundXS})`}}/>
                <div className="bg bg-md d-none d-md-block d-lg-none" style={{backgroundImage: `url(${LoginBackgroundMD})`}}/>
                <div className="bg bg-lg d-none d-lg-block" style={{backgroundImage: `url(${LoginBackgroundLG})`}}/>
                <NavLink to="/" exact={true} className="btnClose">
                    <img alt="" className="img" src={LoginButtonClose}/>
                </NavLink>
                <div className="container content-container">
                    <div className="row">
                        <div className="col-3 offset-3 col-md-2 offset-md-0">
                            <Link className="logo-container d-block" to="/">
                                <img src={LoginLogoFullBlue} alt="" className="logo logo-default ml-auto ml-md-0"/>
                                <img src={LoginLogoBlue} alt="" className="logo logo-tablet-default ml-auto ml-md-0"/>
                                <img src={LoginLogoMo} alt="" className="logo logo-mo ml-auto ml-md-0"/>
                            </Link>
                        </div>
                        <EigenkapitalTheme>
                            <div className="col-3 offset-3 col-md-2 offset-md-2">
                                <a className="logo-container d-block"
                                   href="https://www.deutsche-boerse-cash-market.com/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={LoginLogo2Blue} alt="" className="logo2 logo2-default mr-auto mr-md-0"/>
                                </a>
                            </div>
                        </EigenkapitalTheme>
                    </div>
                    <div className="row row-bottom">
                        <div className="col">
                            <div className="privacy-menu d-flex justify-content-between justify-content-md-start">
                                <Link to="/privacy">
                                    Privacy
                                </Link>
                                <Link to="/imprint">
                                    Imprint
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Left);
