const IconSpeakerView = () => (
  <svg className="ViewIcon" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 36 24">
    <g id="Gruppe_3103" data-name="Gruppe 3103">
      <g id="Gruppe_3102" data-name="Gruppe 3102">
        <path id="Pfad_1295" data-name="Pfad 1295" className="cls-1" fill="currentColor"
              d="M32.6,0H3.4C1.5,0,0,1.5,0,3.4v17.2c0,1.9,1.5,3.4,3.4,3.4h29.2c1.9,0,3.4-1.5,3.4-3.4V3.4c0-1.9-1.5-3.4-3.4-3.4M16.6,17.6h4.1v4.1h-4.1v-4.1ZM23.1,17.6h4.1v4.1h-4.1v-4.1ZM33.7,20.6c0,.6-.5,1.1-1.1,1.1h-3v-4.1h4.1v3ZM29.5,15.2h-15.2v6.5H3.4c-.6,0-1.1-.5-1.1-1.1h0V3.4c0-.6.5-1.1,1.1-1.1h29.2c.6,0,1.1.5,1.1,1.1v11.8h-4.1Z"/>
      </g>
    </g>
  </svg>
)

export default IconSpeakerView;