import React from "react";
import {Link} from "react-router-dom";


class Footer extends React.Component {
    render() {
        return (
            <div className="Footer">
                <div className="container-fluid">
                    <div className="row mt-auto">
                        <div className="col col-lg-4 offset-lg-2">
                            <div className="privacy-menu d-flex justify-content-between justify-content-md-end">
                                <Link to="/privacy">
                                    Privacy
                                </Link>
                                <Link to="/imprint">
                                    Imprint
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
