import React, {Fragment, useEffect, useState} from 'react';
import ScrollToTop from "../../elements/helpers/ScrollToTop";
import ProjectApi from "../../api/ProjectApi";
import moment from "moment";
import {Dropdown} from "react-bootstrap";

const Program = () => {

  const [loaded, setLoaded] = useState(false);
  const [days, setDays] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [programEvents, setProgramEvents] = useState([]);
  const [oooOnly, setOooOnly] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null)
  const [roomsForDay, setRoomsForDay] = useState([]);
  const [slotsForDay, setSlotsForDay] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [cellWidth, setCellWidth] = useState(null);

  useEffect(() => {
    if (!loaded) {
      ProjectApi.getProjectProgram()
        .then(response => {
          if (response.status === 200) {
            setLoaded(true);
            let events = response.data.data;
            setOooOnly(events.filter(e => !e.startAt && (e.representatives && e.representatives.length > 0)));
            events = events.filter(e => e.startAt).sort((a, b) => moment(a.startAt) - moment(b.startAt))
            let days = [...new Set([...events.map(e => moment(e.startAt).utc().format('DD.MM.YYYY'))])];
            let rooms = [...new Set([...events.filter(e => e.room).map(e => e.room)])];
            setDays(days);
            setRooms(rooms);
            setProgramEvents(events);
            setSelectedDay(days.length > 0 ? days[0] : null);
          }
        })
    }
  }, [loaded]);

  useEffect(() => {
    if (loaded && selectedDay) {
      let events = programEvents.filter(e => moment(e.startAt).isSame(moment(selectedDay, 'DD.MM.YYYY'), 'day'));
      let eventRooms = rooms.filter(r => !!events.find(e => e.room === r))?.sort((a, b) => a.localeCompare(b));
      let slots = [...new Set([...events.map(e => e.startAt)])]?.map(slot => {
        if (events.find(e => e.startAt === slot && e.type === 'presentation')) {
          return {
            type: 'presentation',
            startAt: slot,
            events: events.filter(e => e.startAt === slot)
          }
        } else {
          return {
            type: 'event',
            event: events.find(e => e.startAt === slot && e.type === 'event')
          }
        }
      })
      setSelectedRoom(eventRooms.length > 0 ? eventRooms[0] : null);
      setRoomsForDay(eventRooms);
      setSlotsForDay(slots);
    }
  }, [loaded, selectedDay, programEvents, rooms]);

  const renderCompany = (event) => {
    return event ? (
      <Fragment>
        <div className="company">
          {event?.homepage ? (
            <a href={event.homepage} target="_blank" rel="noopener noreferrer">
              {event.title}
            </a>
          ) : (
            event.title
          )}
          {event.presentationInEnglish ? (
            <span>*</span>
          ) : null}
        </div>
        {event?.representatives?.split(':::')?.map((r, rI) => (
          <div key={rI} className="representative">{r}</div>
        ))}
        {event?.marketCap ? (
          <div className="marketCap">Market Cap: {event.marketCap}</div>
        ) : null}
      </Fragment>
    ) : null
  }

  return loaded ? (
    <Fragment>
      <ScrollToTop/>
      <div className="page page-program">
        <div className="container container-program-table">
          <div className="row">
            <div className="col-6">
              <div className="days">
                <a href="https://equityforum.de/events/fr-hjahrskonferenz-2023" target="_blank" rel="noopener noreferrer" className="d-inline-block d-md-none">
                  Registration
                </a>
                <div className="breaker d-md-none"/>
                {days.map((day, dI) => (
                  <button onClick={() => setSelectedDay(day)} key={dI} disabled={day === selectedDay}>
                    <span>{moment(day, 'DD.MM.YYYY').format('DD.MM.')}</span>
                  </button>
                ))}
                <a href="https://equityforum.de/events/fr-hjahrskonferenz-2023" target="_blank" rel="noopener noreferrer" className="d-none d-md-inline-block">
                  Registration
                </a>
              </div>
              <div className="program-content">
                <div className="program-content-inner">
                  <Dropdown className={`d-md-none${roomsForDay?.length < 2 ? ' no-selection' : ''}`}>
                    <Dropdown.Toggle variant="default">
                      <div className="dropdown-toggle-content">
                        {selectedRoom}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {roomsForDay?.filter(r => r !== selectedRoom).map((room, rI) => (
                        <Dropdown.Item
                          onClick={() => setSelectedRoom(room)}
                          key={rI}>
                          {room}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <table className="d-none d-xl-block">
                    <thead>
                    <tr>
                      <th className="th-date" style={{width: 200}}>Time</th>
                      {roomsForDay.map((room, rI) => (
                        <th key={rI} className="th-room" style={{width: `calc((100% - 200px) / ${roomsForDay.length})`}}>{room}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {slotsForDay.map((slot, sI) => (
                      slot.type === 'event' ? (
                        <tr key={sI} className="tr-event">
                          <td colSpan={roomsForDay.length + 1}>
                            {slot.event?.title}
                          </td>
                        </tr>
                      ) : (
                        <tr key={sI}>
                          <td className="td-time" style={{width: 200}}>
                            {moment(slot.startAt).format('HH:mm')}
                          </td>
                          {roomsForDay.map((room, rI) => (
                            <td key={rI} className="td-presentation" style={{width: `calc((100% - 200px) / ${roomsForDay.length})`}}>
                              {renderCompany(slot.events.find(e => e.room === room))}
                            </td>
                          ))}
                        </tr>
                      )
                    ))}
                    </tbody>
                  </table>
                  <table className="d-none d-md-block d-xl-none">
                    <thead>
                    <tr>
                      <th className="th-date" style={{width: 100}}>Time</th>
                      {roomsForDay.map((room, rI) => (
                        <th key={rI} className="th-room" style={{width: `calc((100% - 100px) / ${roomsForDay.length})`}}>{room}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {slotsForDay.map((slot, sI) => (
                      slot.type === 'event' ? (
                        <tr key={sI} className="tr-event">
                          <td colSpan={roomsForDay.length + 1}>
                            <span>{slot.event?.title}</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key={sI}>
                          <td className="td-time" style={{width: 100}}>
                            {moment(slot.startAt).format('HH:mm')}
                          </td>
                          {roomsForDay.map((room, rI) => (
                            <td key={rI} className="td-presentation" style={{width: `calc((100% - 100px) / ${roomsForDay.length})`}}>
                              {renderCompany(slot.events.find(e => e.room === room))}
                            </td>
                          ))}
                        </tr>
                      )
                    ))}
                    </tbody>
                  </table>
                  <table className="d-md-none">
                    <tbody>
                    {slotsForDay.filter(s => !!s.events?.find(e => e.room === selectedRoom) || s.type === 'event')?.map((slot, sI) => (
                      slot.type === 'event' ? (
                        <tr key={sI} className="tr-event">
                          <td colSpan={roomsForDay.length + 1}>
                            {slot.event?.title}
                          </td>
                        </tr>
                      ) : (
                        <tr key={sI}>
                          <td className="td-time">
                            {moment(slot.startAt).format('HH:mm')}
                          </td>
                          <td className="td-presentation">
                            {renderCompany(slot.events.find(e => e.room === selectedRoom))}
                          </td>
                        </tr>
                      )
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="ooo-only">
                <div className="ooo-only-inner">
                  <h4>1on1 only:</h4>
                  <div className="entries d-none d-md-flex">
                    {oooOnly.sort((a, b) => a.title?.localeCompare(b.title)).map((entry, eI) => (
                      <div key={eI} className="entry" style={{minWidth: `${100 / roomsForDay?.length}%`, maxWidth: `${100 / roomsForDay?.length}%`}}>
                        {renderCompany(entry)}
                      </div>
                    ))}
                  </div>
                  <div className="entries d-md-none">
                    {oooOnly.sort((a, b) => a.title?.localeCompare(b.title)).map((entry, eI) => (
                      <div key={eI} className="entry">
                        {renderCompany(entry)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="info">
                * Presentation in English
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : null
}

export default Program;