import axios from 'axios';

class ProjectApi {
    static getProject = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}project`)
    }

    static someActive = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}project/someActive`)
    }

    static getProjectProgram = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}project/program`)
    }

    static getProjectDocuments = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}project/documents`)
    }
}

export default ProjectApi;
