import {
  MEETING_SET_AUDIO_MUTED,
  MEETING_SET_CAMERA_ID,
  MEETING_SET_MICROPHONE_ID,
  MEETING_SET_SPEAKER_ID,
  MEETING_SET_VIDEO_MUTED, MEETING_SET_VIRTUAL_BACKGROUND
} from "../../config/actions";

const defaultState = {
  cameraId: null,
  microphoneId: null,
  speakerId: null,
  videoMuted: false,
  audioMuted: false,
  virtualBackground: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MEETING_SET_CAMERA_ID:
      return {
        ...state,
        cameraId: action.cameraId
      }
    case MEETING_SET_MICROPHONE_ID:
      return {
        ...state,
        microphoneId: action.microphoneId
      }
    case MEETING_SET_SPEAKER_ID:
      return {
        ...state,
        speakerId: action.speakerId
      }
    case MEETING_SET_VIDEO_MUTED:
      return {
        ...state,
        videoMuted: action.videoMuted
      }
    case MEETING_SET_AUDIO_MUTED:
      return {
        ...state,
        audioMuted: action.audioMuted
      }
    case MEETING_SET_VIRTUAL_BACKGROUND:
      return {
        ...state,
        virtualBackground: action.virtualBackground
      }
    default:
      return state
  }
}