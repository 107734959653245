import {PROJECT_LOAD_SUCCESS, PROJECT_HAS_SET_VCARD, PROJECT_UNLOAD} from "../../config/actions";

export const projectLoadSuccess = (projectPersons, projectDays, projectSponsors, pushNotificationsEnabled, hasEverSetVCard, projectCompanies, projectInfo) => {
    return {
        type: PROJECT_LOAD_SUCCESS,
        projectPersons,
        projectDays,
        projectSponsors,
        pushNotificationsEnabled,
        hasEverSetVCard,
        projectCompanies,
        projectInfo
    }
};

export const projectSetHasSetVCard = () => {
    return {
        type: PROJECT_HAS_SET_VCARD
    }
}

export const projectUnload = () => {
    return {
        type: PROJECT_UNLOAD
    }
};
