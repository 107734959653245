import MenuIcon from '../../../../themes/equityforum/gfx/meeting/Menu';
import {useContext, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import Close from "../../../../themes/equityforum/gfx/meeting/Close";
import IconChat from "../../../../themes/equityforum/gfx/meeting/menu/IconChat";
import IconPeople from "../../../../themes/equityforum/gfx/meeting/menu/IconPeople";
import IconDetails from "../../../../themes/equityforum/gfx/meeting/menu/IconDetails";
import IconOptions from "../../../../themes/equityforum/gfx/meeting/menu/IconOptions";
import IconShare from "../../../../themes/equityforum/gfx/meeting/menu/IconShare";
import IconStopSharing from "../../../../themes/equityforum/gfx/meeting/menu/IconStopSharing";
import IconGalleryView from "../../../../themes/equityforum/gfx/meeting/menu/IconGalleryView";
import IconSpeakerView from "../../../../themes/equityforum/gfx/meeting/menu/IconSpeakerView";
import Chat from "./Chat";
import People from "./People";
import {ZoomContext} from "../../context/ZoomContext";
import Settings from "./Settings";
import Details from "./Details";

const Menu = ({isGalleryView, setIsGalleryView, currentUserIsScreenSharing, setCurrentUserIsScreenSharing, phoneAsSource}) => {

  const zoomClient = useContext(ZoomContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuOptionOpen, setMenuOptionOpen] = useState(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [chatMessages, setChatMessages] = useState([]);
  const [readChatMessagesIds, setReadChatMessagesIds] = useState([]);
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] = useState(0);

  const menuOpenContainer = useRef();

  const renderMenuOption = (menuOption) => {
    switch (menuOption) {
      case 'chat':
        return <Chat/>;
      case 'people':
        return <People/>;
      case 'settings':
        return <Settings phoneAsSource={phoneAsSource}/>;
      case 'details':
        return <Details/>;
      default:
        return null;
    }
  }

  useEffect(() => {
    const updateMaxHeight = () => {
      const topOffset = menuOpenContainer.current?.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;
      const newMaxHeight = viewportHeight - topOffset - 2;
      setMaxHeight(newMaxHeight);
    };

    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);

    return () => {
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, []);

  const toggleMenuOptionOpen = (menuOption) => {
    setMenuOptionOpen(current => current === menuOption ? null : menuOption);
  }

  useEffect(() => {
    if (menuOptionOpen === 'chat') {
      setReadChatMessagesIds(chatMessages?.map(cm => cm.id));
    }
  }, [menuOptionOpen]);

  const receiveChatMessage = (chatMessage) => {
    const currentUserId = zoomClient.getCurrentUserInfo()?.userId;
    if (chatMessage.sender.userId === currentUserId) return;
    if (chatMessages?.find(m => m.id === chatMessage.id)) {
      setChatMessages(current => current.map(c => c.id === chatMessage.id ? chatMessage : c));
    } else {
      setChatMessages(current => [...current, chatMessage]);
    }
    if (menuOptionOpen === 'chat') {
      setReadChatMessagesIds(cur => [...cur, chatMessage.id]);
    }
  }

  useEffect(() => {
    zoomClient.on('chat-on-message', receiveChatMessage);
    return () => {
      zoomClient.off('chat-on-message', receiveChatMessage);
    };
  }, [zoomClient, menuOptionOpen]);

  useEffect(() => {
    setUnreadChatMessagesCount(chatMessages?.filter(cm => !readChatMessagesIds?.includes(cm.id))?.length);
  }, [chatMessages, readChatMessagesIds]);

  return (
    <div className="Menu">
      <button className={classNames('d-md-none', menuVisible ? 'd-none' : 'd-inline-block')} onClick={() => {
        setMenuVisible(true);
        setMenuOptionOpen(null);
      }}>
        <MenuIcon/>
      </button>
      <div className={classNames("buttons-container d-md-flex", menuVisible ? '' : 'd-none')}>
        <button className="button button-close d-md-none" onClick={() => setMenuVisible(false)}>
          <Close/>
        </button>
        <button className="button toggleview" onClick={() => setIsGalleryView(!isGalleryView)}>
          {isGalleryView ? (
            <IconSpeakerView/>
          ) : (
            <IconGalleryView/>
          )}
          <span>View</span>
        </button>
        <button className={classNames("button button-share d-none d-md-flex", {'sharing': currentUserIsScreenSharing})}
                onClick={() => setCurrentUserIsScreenSharing(!currentUserIsScreenSharing)}>
          {currentUserIsScreenSharing ? (
            <>
              <IconStopSharing/>
              <span>Stop</span>
            </>
          ) : (
            <>
              <IconShare/>
              <span>Share</span>
            </>
          )}
        </button>
        <button className={classNames("button", menuOptionOpen === 'chat' ? 'active' : '')}
                onClick={() => toggleMenuOptionOpen('chat')}>
          <IconChat/>
          <span>Chat</span>
          {unreadChatMessagesCount > 0 ? (
            <div className="indicator">
              {unreadChatMessagesCount}
            </div>
          ) : null}
        </button>
        <button className={classNames("button", menuOptionOpen === 'people' ? 'active' : '')}
                onClick={() => toggleMenuOptionOpen('people')}>
          <IconPeople/>
          <span>People</span>
        </button>
        <button className={classNames("button", menuOptionOpen === 'settings' ? 'active' : '')}
                onClick={() => toggleMenuOptionOpen('settings')}>
          <IconOptions/>
          <span>Options</span>
        </button>
        <button className={classNames("button", menuOptionOpen === 'details' ? 'active' : '')}
                onClick={() => toggleMenuOptionOpen('details')}>
          <IconDetails/>
          <span>Details</span>
        </button>
      </div>
      <div className={classNames('menu-close-backdrop d-md-none', menuOptionOpen && menuVisible ? 'd-block' : 'd-none')}
           onClick={() => setMenuOptionOpen(null)}/>
      <div
        className={classNames('menu-open-container', menuOptionOpen ? (menuVisible ? 'd-flex' : 'd-none d-md-block') : 'd-none')}
        ref={menuOpenContainer} style={{maxHeight: `${maxHeight}px`}} onClick={(e) => e.stopPropagation()}>
        {renderMenuOption(menuOptionOpen)}
      </div>
    </div>
  )
}

export default Menu;