import React from 'react';

class Checkbox extends React.Component {
    render() {
        const {label, checked, onChange, checkedHelpText, uncheckedHelpText, error, onText, offText} = this.props;

        return (
            <div className={`c-input checkbox-container${error ? ' error': ''}`}>
                <label className="w-100">
                    <input type="checkbox" checked={checked} onChange={onChange}/>
                    {label}
                </label>
                <div className="help-text">
                    {checked ? checkedHelpText : uncheckedHelpText}
                    <div className="dot d-none"/>
                </div>
            </div>
        )
    }
}

export default Checkbox;
