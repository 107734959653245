import React, {Fragment} from 'react';
import {MeetingsIconSponsorInfo, MeetingsIconSponsorClose, MeetingsIconSponsorCloseMo, MeetingsIconSponsorWorld} from "../../elements/helpers/GfxPaths";

class Sponsor extends React.Component {

    state = {
        isOpen: false
    };

    render() {
        let {sponsor} = this.props;
        return (
            <div className={`Sponsor ${this.state.isOpen ? 'isOpen' : ''} ${sponsor.externalLink && sponsor.externalLink.length > 0 ? "hasLink" : ''}`}>
                <button className="close-button-fullWidth" onClick={() => this.setState({isOpen: false})}>
                    <img src={MeetingsIconSponsorClose} alt="" className="icon icon-default"/>
                    <img src={MeetingsIconSponsorCloseMo} alt="" className="icon icon-mo"/>
                </button>
                {sponsor.externalLink && sponsor.externalLink.length > 0 ? (
                    <a href={sponsor.externalLink} target="_blank" rel="noopener noreferrer" className="d-lg-none worldLink">
                        <img src={MeetingsIconSponsorWorld} alt="" className="icon-world"/>
                    </a>
                ) : null}
                <div className="row">
                    <div className={`image-container ${this.state.isOpen ? 'col-6 isOpen' : 'col-6 col-lg-3 offset-lg-3'}`}>
                        <div className="image-inner"
                             style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}sponsor/logo/${sponsor.uuid}')`}}>
                            <button className="info-button" onClick={() => this.setState({isOpen: true})}>
                                <img className="d-none d-md-inline-block" src={MeetingsIconSponsorInfo} alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={`description-container ${this.state.isOpen ? 'col-6 isOpen' : 'col-6 col-xl-3 offset-xl-3'}`}>
                        <div className="description-inner">
                            <div className="text">
                                {sponsor.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sponsor;
