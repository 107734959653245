import {PROJECT_HAS_SET_VCARD, PROJECT_LOAD_SUCCESS, PROJECT_UNLOAD} from "../../config/actions";

let defaultState = {
    loaded: false,
    projectPersons: [],
    projectDays: [],
    projectSponsors: [],
    pushNotificationsEnabled: false,
    hasEverSetVCard: true,
    projectCompanies: [],
    projectInfo: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PROJECT_LOAD_SUCCESS:
            return {
                ...state,
                loaded: true,
                projectPersons: action.projectPersons,
                projectDays: action.projectDays,
                projectSponsors: action.projectSponsors,
                pushNotificationsEnabled: action.pushNotificationsEnabled,
                hasEverSetVCard: action.hasEverSetVCard,
                projectCompanies: action.projectCompanies,
                projectInfo: action.projectInfo
            };
        case PROJECT_HAS_SET_VCARD: {
            return {
                ...state,
                hasEverSetVCard: true
            }
        }
        case PROJECT_UNLOAD:
            return defaultState;
        default:
            return state;
    }
}
