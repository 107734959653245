const VideoMuted = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36.09" height="36.09" viewBox="0 0 36.09 36.09" className="VideoMuted">
    <g id="Gruppe_2130" data-name="Gruppe 2130" transform="translate(-620 -752)">
      <path id="Pfad_1036" data-name="Pfad 1036"
            d="M1356.55,2086.087a18.045,18.045,0,1,0,18.045,18.046A18.066,18.066,0,0,0,1356.55,2086.087Zm0,2.9a15.09,15.09,0,0,1,10.443,4.19l-24.257,17.168a15.14,15.14,0,0,1,13.814-21.359Zm0,30.3a15.139,15.139,0,0,1-12.289-6.3l24.717-17.494a15.139,15.139,0,0,1-12.428,23.8Z"
            transform="translate(-718.505 -1334.087)" fill="currentColor"/>
      <g id="Gruppe_2118" data-name="Gruppe 2118" transform="translate(626.833 763.408)">
        <path id="Pfad_1038" data-name="Pfad 1038" d="M1362.878,2105.608H1352.2a2.007,2.007,0,0,0-2.005,2.006v6.969Z"
              transform="translate(-1350.198 -2105.608)" fill="currentColor"/>
        <path id="Pfad_1039" data-name="Pfad 1039" d="M1358.6,2123.265h8.045a2.007,2.007,0,0,0,2.005-2v-5.108Z"
              transform="translate(-1353.69 -2109.99)" fill="currentColor"/>
      </g>
      <path id="Pfad_1040" data-name="Pfad 1040"
            d="M1383.885,2108.375l-.211.115-3.842,2.72v4.908l4.053,2.214a.821.821,0,0,0,1.214-.721v-8.516A.821.821,0,0,0,1383.885,2108.375Z"
            transform="translate(-735.681 -1343.307)" fill="currentColor"/>
    </g>
  </svg>
)

export default VideoMuted;