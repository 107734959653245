import React from 'react';

class GeneralError extends React.Component {
    render() {
        const {errorMessage} = this.props;
        return (
            <div className="c-input general-error-container">
                <div className="error-message">
                    {errorMessage}
                </div>
            </div>
        )
    }
}

export default GeneralError;
