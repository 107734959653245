import {useParams} from "react-router";
import React, {useState} from "react";
import Join from "./Join";
import MeetingApi from "../../api/MeetingApi";
import {useSelector} from "react-redux";
import Main from "./Main";
import ZoomVideo from "@zoom/videosdk";
import {ZoomContext} from "./context/ZoomContext";
import Phone from "./Phone";

const zoomClient = ZoomVideo.createClient();

const Meeting = () => {

  const { id } = useParams();
  const authorization = useSelector(state => state.authorization);

  const [view, setView] = useState('join');
  const [meetingJWT, setMeetingJWT] = useState(null);
  const [meetingName, setMeetingName] = useState(null);

  const handleStartMeeting = (view = 'main') => {
    MeetingApi.getToken(id, authorization.selectedPersonType, authorization.selectedPersonId).then((response) => {
      if (response.status === 200 && response.data.success === true) {
        setMeetingJWT(response.data.data.token);
        setMeetingName(response.data.data.meetingName);
        setView(view);
      } else {
        alert('Unfortunately, an error occurred when joining the meeting.');
      }
    }).catch(() => {
      alert('Unfortunately, an error occurred when joining the meeting.');
    });
  }

  const renderView = () => {
    switch (view) {
      case 'join':
        return <Join handleStartMeeting={handleStartMeeting}/>
      case 'main':
        return <Main meetingJWT={meetingJWT} meetingName={meetingName}/>
      case 'phone':
        return <Phone meetingJWT={meetingJWT} meetingName={meetingName}/>
      default:
        return null
    }
  }

  return (
    <div className="page page-meeting">
      <ZoomContext.Provider value={zoomClient}>
        {renderView()}
      </ZoomContext.Provider>
    </div>
  )
}

export default Meeting;