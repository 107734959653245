import axios from 'axios';

class ProfileApi {

    static get = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}profile/get`)
    };

    static getVCard = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}profile/getVCard`)
    };

    static update = (email, pushNotificationsEnabled, changePassword, oldPassword, password, sharesVCardInformation) => {
        return axios.put(`${process.env.REACT_APP_API_URL}profile/update`, {
            email,
            pushNotificationsEnabled,
            changePassword,
            oldPassword,
            password,
            sharesVCardInformation
        });
    };

    static updateVCard = (title, lastName, firstName, position, company, street, zipcode, city, country, email, phone, mobile, fax) => {
        return axios.put(`${process.env.REACT_APP_API_URL}profile/updateVCard`, {
            title,
            lastName,
            firstName,
            position,
            company,
            street,
            zipcode,
            city,
            country,
            email,
            phone,
            mobile,
            fax
        });
    };
}

export default ProfileApi;
