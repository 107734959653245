const Menu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="40" viewBox="0 0 55 40">
    <g id="Gruppe_3253" data-name="Gruppe 3253" transform="translate(-316 -293)">
      <rect id="Rechteck_1273" data-name="Rechteck 1273" width="55" height="40" rx="10" transform="translate(316 293)"
            fill="#fff"/>
      <path id="Pfad_1335" data-name="Pfad 1335"
            d="M171.293,8.057a4.607,4.607,0,1,0,4.607,4.607,4.607,4.607,0,0,0-4.607-4.607"
            transform="translate(159.207 300.34)" fill="#002856"/>
      <path id="Pfad_1336" data-name="Pfad 1336"
            d="M171.293,8.057a4.607,4.607,0,1,0,4.607,4.607,4.607,4.607,0,0,0-4.607-4.607"
            transform="translate(172.207 300.34)" fill="#002856"/>
      <path id="Pfad_1337" data-name="Pfad 1337"
            d="M171.293,8.057a4.607,4.607,0,1,0,4.607,4.607,4.607,4.607,0,0,0-4.607-4.607"
            transform="translate(185.207 300.34)" fill="#002856"/>
    </g>
  </svg>
)

export default Menu;