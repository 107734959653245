import React, {Fragment} from 'react';
import {Document, Page, Text, StyleSheet, Font, Image, View} from "@react-pdf/renderer";
import moment from "moment-timezone";
import font_maisonNeueBold from '../../themes/equityforum/fonts/MaisonNeue-Bold.otf';
import font_maisonNeueBook from '../../themes/equityforum/fonts/MaisonNeue-Book.otf';
import image_logo from '../../themes/equityforum/gfx/pdf/logo_equity-forum.png';
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";

Font.register({ family: 'MaisonNeue Book', src: font_maisonNeueBook, fontStyle: 'normal'});
Font.register({ family: 'MaisonNeue Bold', src: font_maisonNeueBold, fontStyle: 'normal', fontWeight: 700});

const DayTimetable = ({projectDay, meetings, person, projectInfo}) => {

  const getMeetingType = (meeting) => {
    if (meeting.specialMeetingType && meeting.specialMeetingType.type === 'PRESENTATION') {
      return 'Presentation';
    } else {
      if (meeting.type === 1) {
        return '1on1';
      } else {
        if (meeting.investors?.length <= 3) {
          return `${meeting.investors?.length}on1`;
        } else {
          return 'Group meeting';
        }
      }
    }
  }

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'MaisonNeue Bold',
      padding: 40
    },
    headerView: {
      flexDirection: 'row',
    },
    logoContainer: {
      alignItems: 'flex-end',
      flex: '1'
    },
    logo: {
      width: 100
    },
    headerTextBlock: {
      marginTop: 10
    },
    projectInfo: {
      marginTop: 5,
      flexDirection: 'row',
      fontSize: 11,
      color: '#002856',
      flexWrap: 'wrap'
    },
    fontBook: {
      fontFamily: 'MaisonNeue Book'
    },
    tableContainer: {
      marginTop: 45,
      borderBottom: '2pt solid #000000'
    },
    tableCell: {
      padding: 4
    },
    displayBlock: {
      width: '100%'
    }
  })

  const getBluejeansInformation = (meeting) => {
    if (meeting.blueJeansMeeting && !meeting.blueJeansMeeting.isInvalid) {
      return <Text style={styles.fontBook}>
        {`bluejeans.com/${meeting.blueJeansMeeting.numericMeetingId}/${meeting.blueJeansMeeting.attendeePasscode}/`}
        {meeting.blueJeansMeeting.numbers ? `${"\n"}Tel.: ${meeting.blueJeansMeeting.numbers.numbers[0].number}${"\n"}ID: ${meeting.blueJeansMeeting.numericMeetingId}${"\n"}Pin: ${meeting.blueJeansMeeting.attendeePasscode}` : ''}
      </Text>
    } else {
      return null;
    }
  }

  return projectDay ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerView}>
          <View style={styles.headerTextBlock}>
            {person.companyName && <Text>{person.companyName}</Text>}
            {person.personName && <Text>{person.personName}</Text>}
            <View style={styles.projectInfo}>
              {projectInfo?.name && <Text style={styles.displayBlock}>{projectInfo?.name}:</Text>}
              <Text>Meetings on {moment(moment(projectDay.date).tz('Europe/Berlin')).format('dddd, MMMM Do, YYYY')}</Text>
            </View>
          </View>
          <View style={styles.logoContainer}>
            <Image src={image_logo} style={styles.logo}/>
          </View>
        </View>
        <View style={styles.tableContainer}>
          {/*TODO: Make this working again*/}
          {/*<Table data={[...meetings]}>
            <TableHeader/>
            <TableBody>
              <DataTableCell style={styles.tableCell} weighting={0.4} getContent={(r) => `${getMeetingType(r)}${(r.room || r.blueJeansMeeting) ? (` (${!r.blueJeansMeeting && r.room ? 'on site' : 'online'})`) : ''}`}/>
              <DataTableCell style={styles.tableCell} weighting={0.2} getContent={(r) => r.room}/>
              <DataTableCell style={styles.tableCell} weighting={0.3} getContent={(r) => `${moment(r.realStartAt).format('HH:mm')} - ${moment(r.realEndAt).format('HH:mm')}`}/>
              <DataTableCell style={styles.tableCell} getContent={(r) =>
                <View>
                  {
                    person.personType === 'company' ? (
                      r.specialMeetingType && r.specialMeetingType.type === 'PRESENTATION' ? (
                        <Text>{r.companyName}</Text>
                      ) : (
                        r.investors.map((investor, iI) => (
                          <Text key={iI}>
                            {investor.fullName}
                            {investor.companyName ? <Text style={styles.fontBook}> ({investor.companyName})</Text> : ''}
                          </Text>
                        ))
                      )
                    ) : (
                      r.specialMeetingType && r.specialMeetingType.type === 'PRESENTATION' ? (
                        <Text>{r.companyName}</Text>
                      ) : (
                        <Fragment>
                          <Text>{r.companyName}</Text>
                          {r.companyRepresentatives?.map((cr, crI) => (
                            <Text key={crI} style={styles.fontBook}>
                              {cr.detailName}
                              {cr.position ? <Text> ({cr.position})</Text> : ''}
                            </Text>
                          ))}
                        </Fragment>
                      )
                    )
                  }
                  {getBluejeansInformation(r)}
                </View>}/>
            </TableBody>
          </Table>*/}
        </View>
      </Page>
    </Document>
  ) : null
}

export default DayTimetable;