const NoVideo = () => (
  <svg id="Gruppe_3252" data-name="Gruppe 3252" xmlns="http://www.w3.org/2000/svg" width="113.454" height="113.455"
       viewBox="0 0 113.454 113.455">
    <path id="Pfad_1036" data-name="Pfad 1036"
          d="M1395.233,2086.087a56.727,56.727,0,1,0,56.726,56.73A56.794,56.794,0,0,0,1395.233,2086.087Zm0,9.1a47.437,47.437,0,0,1,32.829,13.173l-76.256,53.971a47.6,47.6,0,0,1,43.427-67.144Zm0,95.253a47.593,47.593,0,0,1-38.633-19.82l77.7-54.994a47.59,47.59,0,0,1-39.07,74.814Z"
          transform="translate(-1338.505 -2086.087)" fill="#fff"/>
    <g id="Gruppe_2118" data-name="Gruppe 2118" transform="translate(21.481 35.862)">
      <path id="Pfad_1038" data-name="Pfad 1038" d="M1390.06,2105.608H1356.5a6.311,6.311,0,0,0-6.3,6.306v21.907Z"
            transform="translate(-1350.198 -2105.608)" fill="#ff7f27"/>
      <path id="Pfad_1039" data-name="Pfad 1039" d="M1358.6,2138.512h25.291a6.31,6.31,0,0,0,6.3-6.3v-16.059Z"
            transform="translate(-1343.164 -2096.781)" fill="#ff7f27"/>
    </g>
    <path id="Pfad_1040" data-name="Pfad 1040"
          d="M1392.574,2108.593l-.663.36-12.078,8.551v15.43l12.742,6.961a2.58,2.58,0,0,0,3.818-2.265v-26.772A2.58,2.58,0,0,0,1392.574,2108.593Z"
          transform="translate(-1303.91 -2067.516)" fill="#ff7f27"/>
  </svg>
)

export default NoVideo;