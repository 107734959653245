const IconShare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.06" height="29.06"
       viewBox="0 0 29.06 29.06">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rechteck_1278" data-name="Rechteck 1278" width="29.06" height="29.06" fill="currentColor"/>
      </clipPath>
    </defs>
    <g id="Gruppe_3094" data-name="Gruppe 3094" clipPath="url(#clip-path)">
      <path id="Pfad_1290" data-name="Pfad 1290"
            d="M14.53,0A14.53,14.53,0,1,0,29.059,14.53,14.546,14.546,0,0,0,14.53,0m0,26.729a12.2,12.2,0,1,1,12.2-12.2,12.213,12.213,0,0,1-12.2,12.2"
            transform="translate(0 0)" fill="currentColor"/>
      <path id="Pfad_1291" data-name="Pfad 1291"
            d="M21.426,9.539H8.86a1.732,1.732,0,0,0-1.726,1.728V12.66H9.076V11.48H21.211v7.742H15.728v1.942h5.7a1.735,1.735,0,0,0,1.727-1.727V11.264a1.734,1.734,0,0,0-1.73-1.725"
            transform="translate(-0.615 -0.822)" fill="currentColor"/>
      <path id="Pfad_1292" data-name="Pfad 1292"
            d="M7.135,14.113v1.193a6.261,6.261,0,0,1,6.252,6.252H14.58a7.454,7.454,0,0,0-7.445-7.445"
            transform="translate(-0.615 -1.216)" fill="currentColor"/>
      <path id="Pfad_1293" data-name="Pfad 1293"
            d="M7.136,16.737V17.93a3.858,3.858,0,0,1,3.855,3.855h1.193a5.053,5.053,0,0,0-5.048-5.047"
            transform="translate(-0.615 -1.442)" fill="currentColor"/>
      <path id="Pfad_1294" data-name="Pfad 1294"
            d="M7.134,19.357h0l0,1.194A1.46,1.46,0,0,1,8.594,22.01H9.787a2.653,2.653,0,0,0-2.653-2.653"
            transform="translate(-0.615 -1.668)" fill="currentColor"/>
    </g>
  </svg>
)

export default IconShare;