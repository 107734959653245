const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.059" height="19.586" viewBox="0 0 21.059 19.586">
    <g id="x" transform="translate(0.956 0.5)">
      <g id="Gruppe_2300" data-name="Gruppe 2300" style={{isolation: 'isolate'}}>
        <path id="Pfad_1099" data-name="Pfad 1099"
              d="M1467.594,2089.905l-6.383-9.188h5.892l3.507,5.611h.07l3.473-5.611h5.926l-6.417,9.188,6.556,9.4h-5.926l-3.612-5.821h-.07l-3.647,5.821h-5.89Z"
              transform="translate(-1461.072 -2080.717)" fill="currentColor" stroke="currentColor" strokeWidth="1"/>
      </g>
    </g>
  </svg>
)

export default Close;