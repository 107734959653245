import React, {Fragment} from 'react';
import SubmitButton from "../../elements/form/SubmitButton";
import TextInput from "../../elements/form/TextInput";
import ProfileApi from "../../api/ProfileApi";
import Checkbox from "../../elements/form/Checkbox";
// TODO Create new Icons
import {MeetingsLogo2, ProfileButtonDontChangePassword, ProfileButtonDontChangePasswordMo, ProfileButtonDontChangePasswordMobile, ProfileButtonSave, ProfileButtonSaveMo, ProfileButtonTestMeetingLG, PrivacyLogoGrey, ProfileButtonTestMeetingLGMo, ProfileButtonTestMeetingMD, ProfileButtonTestMeetingMDMo, ProfileButtonTestMeetingXS, ProfileButtonTestMeetingXSMo} from "../../elements/helpers/GfxPaths";
import moment from "moment-timezone";
import {connect} from "react-redux";
import {authorizationOpenSelectTimezone} from "../../store/authorization/authorizationAction";
import ScrollToTop from "../../elements/helpers/ScrollToTop";
import VCardPopup from "./VCardPopup";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import EquityTheme from "../../elements/helpers/EquityTheme";

class Profile extends React.Component {

    state = {
        fullName: '',
        email: '',
        pushNotificationsEnabled: false,
        sharesVCardInformation: false,
        changePassword: false,
        oldPassword: '',
        password: '',
        confirmPassword: '',
        errors: {},
        showMessage: false,
        message: null,
        messageType: 'success',
        originalValues: {},
        showVCardPopup: false
    };

    messageTimeout = null;

    componentDidMount() {
        ProfileApi.get().then((response) => {
            if (response.status === 200) {
                this.setState({
                    fullName: response.data.data.fullName,
                    email: response.data.data.email,
                    pushNotificationsEnabled: response.data.data.pushNotificationsEnabled,
                    sharesVCardInformation: response.data.data.sharesVCardInformation,
                    originalValues: {
                        email: response.data.data.email,
                        pushNotificationsEnabled: response.data.data.pushNotificationsEnabled,
                        sharesVCardInformation: response.data.data.sharesVCardInformation,
                        changePassword: false
                    }
                });
            }
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({errors: {confirmPassword: 'wrong'}});
            return;
        }
        ProfileApi.update(this.state.email, this.state.pushNotificationsEnabled, this.state.changePassword, this.state.oldPassword, this.state.password, this.state.sharesVCardInformation)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.success === true) {
                        if (this.messageTimeout) clearTimeout(this.messageTimeout);
                        this.setState({
                            fullName: response.data.data.fullName,
                            email: response.data.data.email,
                            pushNotificationsEnabled: response.data.data.pushNotificationsEnabled,
                            sharesVCardInformation: response.data.data.sharesVCardInformation,
                            changePassword: false,
                            oldPassword: '',
                            password: '',
                            confirmPassword: '',
                            errors: {},
                            originalValues: {
                                email: response.data.data.email,
                                pushNotificationsEnabled: response.data.data.pushNotificationsEnabled,
                                sharesVCardInformation: response.data.data.sharesVCardInformation,
                                changePassword: false
                            },
                            showMessage: true,
                            message: 'Thank you! Your changes have been saved.',
                            messageType: 'success'
                        });
                        this.messageTimeout = window.setTimeout(() => {
                            this.setState({showMessage: false})
                        }, 30000);
                    } else {
                        this.setState({errors: response.data.errors})
                    }
                }
            });
    };

    handleChangeValue = (name, value) => {
        this.setState({
            [name]: value
        });
        window.setTimeout(this.checkForChanges, 100);
        if (name === 'pushNotificationsEnabled' && value === true) {
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification.");
            } else if (Notification.permission !== 'granted') {
                Notification.requestPermission().then();
            }
        } else if (name === 'sharesVCardInformation' && value === true) {
            let btn = document.getElementsByClassName('openEditVCard-btn');
            if (btn && btn.length > 0) {
                btn[0].click();
            }
        }
    };

    checkForChanges = () => {
        if (this.messageTimeout) clearTimeout(this.messageTimeout);
        if (this.state.originalValues.email !== this.state.email || this.state.originalValues.pushNotificationsEnabled !== this.state.pushNotificationsEnabled || this.state.originalValues.changePassword !== this.state.changePassword || this.state.originalValues.sharesVCardInformation !== this.state.sharesVCardInformation) {
            this.setState({
                showMessage: true,
                message: 'You have unsaved changes.',
                messageType: 'warning'
            });
        } else {
            this.setState({
                showMessage: false
            });
        }
    };

    handleDontChangePassword = () => {
        this.setState({
            changePassword: false,
            oldPassword: '',
            password: '',
            confirmPassword: '',
            errors: {}
        });
        window.setTimeout(this.checkForChanges, 100);
    };

    render() {
        const {authorization, project} = this.props;
        return (
            <Fragment>
                <ScrollToTop/>
                <div className="page page-profile">
                    <EigenkapitalTheme>
                        <div className="container container-profile-logo2"><div className="row"><div className="col-6">
                            <a href="https://www.deutsche-boerse-cash-market.com/" target="_blank" rel="noopener noreferrer">
                                <img className="logo2 d-none d-lg-block" src={MeetingsLogo2} alt=""/>
                            </a>
                        </div> </div> </div>
                    </EigenkapitalTheme>
                    <form className="profile-form" onSubmit={this.submitForm}>
                        <div className="container">
                            <div className="row">
                                <div className="col-6 col-md-4 offset-md-1 col-lg-3 pl-lg-0 setTimezone">
                                    {authorization.currentTimezone ? (
                                        <div className="setTimezone-container">
                                            <span>
                                                Timezone is set to ({authorization.currentTimezone.replace('_', ' ')}) {moment(this.state.currentTime).format('HH:mm')}
                                            </span>
                                            <a onClick={this.props.authorizationOpenSelectTimezone}>
                                                Change<span className="d-none d-md-inline"> Timezone</span>
                                            </a>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="d-flex flex-wrap col-6 col-md-4 offset-md-1 col-lg-2 col-lg-2 pl-lg-0 col-lg-left">
                                    <div
                                        className={`message-container${!this.state.showMessage ? ' hidden' : ''} messageType-${this.state.messageType}`}>
                                        {this.state.message}
                                    </div>
                                    <TextInput label="NAME" value={this.state.fullName}
                                               disabled/>
                                    <TextInput label="E-MAIL (CONTACT & LOGIN)" value={this.state.email}
                                               type="email"
                                               error={this.state.errors.email}
                                               onChange={(e) => this.handleChangeValue('email', e.target.value)}/>
                                   <EigenkapitalTheme>
                                        <Checkbox label="Push Notifications:" checked={this.state.pushNotificationsEnabled}
                                                  className="d-none d-lg-inline-block order-1 order-lg-3"
                                                  onChange={(e) => this.handleChangeValue('pushNotificationsEnabled', e.target.checked)}
                                                  uncheckedHelpText="You do not receive any notifications from 1on1 Dashboard at the moment. Turn on to get reminded, 5 Minutes before a meeting starts."
                                                  checkedHelpText="You will receive notifications, 5 minutes before a meeting starts. Turn off to disable all push notifications."/>
                                    </EigenkapitalTheme>
                                </div>
                                <div
                                    className="col-6 col-md-4 offset-md-1 col-lg-2 offset-lg-0 pr-lg-0 d-flex flex-wrap flex-column">
                                    
                                    <div className={`d-none d-lg-inline-block order-4 persionalVcard${!project.hasEverSetVCard ? ' redText' : ''}`}>
                                        <Checkbox label="Personal vCard:" onText="ENABLED" offText="DISABLED"
                                                  checked={this.state.sharesVCardInformation}
                                                  onChange={(e) => this.handleChangeValue('sharesVCardInformation', e.target.checked)}
                                                  uncheckedHelpText="Your vCard is not visible at the moment."
                                                  checkedHelpText="Your vCard is visible at the moment."/>
                                        <VCardPopup/>
                                    </div>

                                    <Checkbox label="Push Notifications:" checked={this.state.pushNotificationsEnabled}
                                              className="d-md-none"
                                              onChange={(e) => this.handleChangeValue('pushNotificationsEnabled', e.target.checked)}
                                              uncheckedHelpText="You do not receive any notifications from 1on1 Dashboard at the moment. Turn on to get reminded, 5 Minutes before a meeting starts."
                                              checkedHelpText="You will receive notifications, 5 minutes before a meeting starts. Turn off to disable all push notifications."/>
                                    <div className="d-lg-none persionalVcard order-3">
                                        <Checkbox label="Personal vCard:" onText="ENABLED" offText="DISABLED"
                                                  checked={this.state.sharesVCardInformation}
                                                  onChange={(e) => this.handleChangeValue('sharesVCardInformation', e.target.checked)}
                                                  uncheckedHelpText="Your vCard is not visible at the moment."
                                                  checkedHelpText="Your vCard is visible at the moment."/>
                                        <VCardPopup/>
                                    </div>
                                    <div className={`messageContainer-mobile message-container${!this.state.showMessage ? ' hidden' : ''} messageType-${this.state.messageType}`}>
                                        {this.state.message}
                                    </div>
                                    <button onClick={() => this.handleDontChangePassword()}
                                            className={`mobileSubmit ${this.state.changePassword ? 'active' : ''}`}
                                            type="button">
                                        <img alt="" className="img img-mobile" src={ProfileButtonDontChangePasswordMobile}/>
                                    </button>
                                    <SubmitButton alignment="right">
                                        <img alt="" className="img img-default d-lg-none" src={ProfileButtonSave}/>
                                        <img alt="" className="img img-default d-none d-lg-inline" src={ProfileButtonSave}/>
                                        <img alt="" className="img img-mo" src={ProfileButtonSaveMo}/>
                                    </SubmitButton>
                                </div>
                                <div className="col-1 col-dcp pl-0">
                                    <button onClick={() => this.handleDontChangePassword()}
                                            className={`${this.state.changePassword ? 'active' : ''}`} type="button">
                                        <img alt="" className="img img-default" src={ProfileButtonDontChangePassword}/>
                                        <img alt="" className="img img-mo" src={ProfileButtonDontChangePasswordMo}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization,
    project: state.project
});

export default connect(mapStateToProps, {
    authorizationOpenSelectTimezone
})(Profile);
