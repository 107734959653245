import React, {Fragment} from 'react';
import moment from "moment-timezone";
import {
    MeetingsIconClock,
    MeetingsIconAddToCalendarMD,
    MeetingsIconJoinActive,
    MeetingsIconJoinActiveMo,
    MeetingsIconJoinInactive,
    MeetingsIconJoinInactiveMobile,
    MeetingsIconJoinDetailsActive,
    MeetingsIconJoinDetailsInactive,
    MeetingsHeader1on1SM,
    MeetingsHeader1on1MD,
    MeetingsHeader1on1LG,
    MeetingsHeader1on1XL,
    MeetingsHeaderGroupSM,
    MeetingsHeaderGroupMD, MeetingsHeaderGroupLG, MeetingsHeaderGroupXL
} from "../../elements/helpers/GfxPaths";
import {MeetingsIconCompanyBlack, MeetingsIconCompanyGray, MeetingsIconCompanyWhite, MeetingsIconPersonBlue, MeetingsIconPersonGray, MeetingsIconPersonWhite, MeetingsStoerer, MeetingsIconCloseDetails, MeetingsIconCloseDetailsMo, MeetingsIconCloseDetailsMoBl} from "../../elements/helpers/GfxPaths";
import {MeetingsIconVcard, MeetingsIconCanvas, MeetingsIconCanvasGray, MeetingsIconCanvasWhite, MeetingsIconHandout} from "../../elements/helpers/GfxPaths";
import fileDownload from 'js-file-download';
import MeetingApi from "../../api/MeetingApi";
import {connect} from "react-redux";
import ical from 'ical-generator';
import EquityTheme from "../../elements/helpers/EquityTheme";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import {Link} from "react-router-dom";

class Meeting extends React.Component {

    state = {
        showDetails: false,
    };

    downloadMeetingIcs = () => {
        const {authorization, meeting} = this.props;
        MeetingApi.getIcsForMeeting(meeting.meetingId, authorization.selectedPersonType, authorization.selectedPersonId).then((response) => {
            if (response.status === 200) {
                const cal = ical({timezone: 'Europe/Berlin'});
                cal.createEvent(
                    response.data
                );
                fileDownload(cal.toString(), "meeting_" + meeting.meetingId + ".ics", 'text/calendar');
            }
        })
    };

    downloadVCard = (uuid) => {
        MeetingApi.getVCard(uuid).then((response) => {
            if(response.status === 200) {
                fileDownload(response.data, `vcard_${uuid}.vcf`, 'text/vcard')
            }
        })
    };

    render() {
        const {meeting, nextMeeting, person, isLast, project, authorization} = this.props;
        const currDate = moment();
        const status = moment(meeting.endAt).isBefore(currDate) ? 'past' : (moment(meeting.startAt).isAfter(moment(currDate).add(5, 'minute')) ? 'future' : 'current');
        const joinActive = status === 'current';
        const isPresentation = meeting.specialMeetingType && meeting.specialMeetingType.type === 'PRESENTATION';

        return (
            <div
                className={`meeting meeting-type-${meeting.type} upcoming${(nextMeeting && moment.duration(nextMeeting.realStartAt.diff(meeting.realEndAt)).asHours() >= 1) ? ' long-break-after' : ''} meeting-status-${status} ${isPresentation ? 'ispresentation' : ''}`}>
                <div className="timeline-entry">
                    <div className="time">{moment(meeting.realStartAt).format('HH:mm')}</div>
                    {!isLast ? (
                        <div className="line"/>
                    ) : null}
                </div>
                <div className="meeting-box">
                    {status === 'current' ? (
                        <img src={MeetingsStoerer} className="stoerer-now" alt=""/>
                    ) : null}
                    {this.state.showDetails && !isPresentation ? (
                        <div className="meeting-details-container">
                            <div className={`meeting-details-meeting show`}>
                                <div className="details">
                                    <div className="d-inline-block mr-3 mr-md-4 mr-lg-5">
                                        <span className="label">
                                            Time:
                                        </span>
                                            <span className="value">
                                            {moment(meeting.realStartAt).format('HH:mm')} - {moment(meeting.realEndAt).format('HH:mm')}
                                        </span>
                                    </div>
                                    <div className="d-inline-block">
                                        <span className="label">
                                            Type:
                                        </span>
                                            <span className="value">
                                            {isPresentation ? 'Presentation' : (meeting.type === 1 ? '1on1 meeting' : 'Group meeting')}
                                        </span>
                                    </div>
                                </div>
                                {person.personType === 'company' ? (
                                    <Fragment>
                                        <div className="details details-italic">
                                            <span className="label mr-2">
                                                Representatives:
                                            </span>
                                            <span className="value d-inline-block ml-0">
                                                {meeting.companyRepresentatives.map((companyRepresentative, cRI) => (
                                                    <Fragment key={cRI}>
                                                        {cRI !== 0 ? (
                                                            <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                        ) : null}
                                                        <span>{companyRepresentative.detailName}</span>
                                                        {companyRepresentative.vCard ? (
                                                            <button onClick={() => this.downloadVCard(companyRepresentative.vCard)} className="btn-vcard">
                                                                <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                            </button>
                                                        ) : null}
                                                    </Fragment>
                                                ))}
                                            </span>
                                        </div>
                                        <div className="details details-italic">
                                            <span className="label mr-2">
                                                Other participants:
                                            </span>
                                            <span className="value d-inline-block ml-0">
                                                {meeting.investors.map((investor, iI) => (
                                                    <Fragment key={iI}>
                                                        {iI !== 0 ? (
                                                            <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                        ) : null}
                                                        <span>{investor.detailName}</span>
                                                        {investor.vCard ? (
                                                            <button onClick={() => this.downloadVCard(investor.vCard)} className="btn-vcard">
                                                                <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                            </button>
                                                        ) : null}
                                                    </Fragment>
                                                ))}
                                            </span>
                                        </div>
                                        {meeting.room && (
                                          <div className="details details-italic">
                                            <span className="label">
                                                Room:
                                            </span>
                                              <span className="value">
                                                {meeting.room}
                                            </span>
                                          </div>
                                        )}
                                    </Fragment>
                                ) : person.personType === 'investor' ? (
                                    <Fragment>
                                        <div className="details">
                                            <span className="label">
                                                Company:
                                            </span>
                                            <span className="value">
                                                {meeting.companyName}
                                            </span>
                                        </div>
                                        <div className="details details-italic">
                                            <span className="label">
                                                Represented by:
                                            </span>
                                            <span className="value">
                                                {meeting.companyRepresentatives.map((companyRepresentative, cRI) => (
                                                    <Fragment key={cRI}>
                                                        {cRI !== 0 ? (
                                                            <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                        ) : null}
                                                        <span>{companyRepresentative.detailName}</span>
                                                        {companyRepresentative.position ? (
                                                            <Fragment>
                                                                &nbsp;
                                                                <span
                                                                    className="position">({companyRepresentative.position})</span>
                                                            </Fragment>
                                                        ) : null}
                                                        {companyRepresentative.vCard ? (
                                                            <button onClick={() => this.downloadVCard(companyRepresentative.vCard)} className="btn-vcard">
                                                                <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                            </button>
                                                        ) : null}
                                                    </Fragment>
                                                ))}
                                            </span>
                                        </div>
                                        {meeting.investors.filter((el) => el.fullName !== person.personName).length > 0 ? (
                                            <div className="details details-italic">
                                            <span className="label">
                                                Other participants:
                                            </span>
                                                <span className="value">
                                                {meeting.investors.filter((el) => el.fullName !== person.personName).map((investor, iI) => (
                                                    <Fragment key={iI}>
                                                        {iI !== 0 ? (
                                                            <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                        ) : null}
                                                        <span>{investor.detailName}</span>
                                                        {investor.vCard ? (
                                                            <button onClick={() => this.downloadVCard(investor.vCard)} className="btn-vcard">
                                                                <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                            </button>
                                                        ) : null}
                                                    </Fragment>
                                                ))}
                                            </span>
                                            </div>
                                        ) : null}
                                        {meeting.room && (
                                          <div className="details details-italic">
                                            <span className="label">
                                                Room:
                                            </span>
                                              <span className="value">
                                                {meeting.room}
                                            </span>
                                          </div>
                                        )}
                                    </Fragment>
                                ) : null}
                                <div className="handout d-flex flex-wrap">
                                    {meeting.infoSheets && meeting.infoSheets.length > 0 ? (
                                        <Fragment>
                                            <span className="w-100 mb-2">Handout:</span>
                                            {meeting.infoSheets.map((infoSheet, iSI) => (
                                                <a href={`${process.env.REACT_APP_API_URL}projectCompany/infosheet/${infoSheet.uuid}`} target="_blank" rel="noopener noreferrer" className="link-with-hover-box mr-3" key={iSI}>
                                                    <img src={MeetingsIconHandout} alt={infoSheet.fileName}/>
                                                    <span className="d-none">{infoSheet.fileName}</span>
                                                </a>
                                            ))}
                                        </Fragment>
                                    ) : null}
                                </div>
                            </div>
                            <div className="meeting-details-join">
                                {meeting.isOnline ? (
                                  status === 'past' || (!meeting.isOnline && !(meeting.specialMeetingType && meeting.specialMeetingType.manualLink)) ? (
                                    <span className={`join-event`}>
                                        <img src={MeetingsIconJoinDetailsInactive} alt="" className="icon icon-inactive"/>
                                        <img src={MeetingsIconJoinDetailsActive} alt="" className="icon icon-active"/>
                                        <img src={MeetingsIconJoinActiveMo} alt="" className="icon icon-active-mo"/>
                                        <img src={MeetingsIconJoinInactiveMobile} alt="" className="icon icon-inactive-mobile"/>
                                        <img src={MeetingsIconJoinInactiveMobile} alt="" className="icon icon-active-mobile"/>
                                    </span>
                                  ) : (
                                    meeting.specialMeetingType && meeting.specialMeetingType.manualLink ? (
                                      <a href={meeting.specialMeetingType.manualLink} className={`join-event${joinActive ? ' active' : ''}`} target="_blank" rel="noopener noreferrer">
                                          <img src={MeetingsIconJoinDetailsInactive} alt="" className="icon icon-inactive"/>
                                          <img src={MeetingsIconJoinDetailsActive} alt="" className="icon icon-active"/>
                                          <img src={MeetingsIconJoinActiveMo} alt="" className="icon icon-active-mo"/>
                                          <img src={MeetingsIconJoinInactiveMobile} alt="" className="icon icon-inactive-mobile"/>
                                          <img src={MeetingsIconJoinInactiveMobile} alt="" className="icon icon-active-mobile"/>
                                      </a>
                                      ) : (
                                      <Link
                                        to={`/meeting/${meeting.meetingId}`}
                                        className={`join-event${joinActive ? ' active' : ''}`}>
                                          <img src={MeetingsIconJoinDetailsInactive} alt=""
                                               className="icon icon-inactive"/>
                                          <img src={MeetingsIconJoinDetailsActive} alt="" className="icon icon-active"/>
                                          <img src={MeetingsIconJoinActiveMo} alt="" className="icon icon-active-mo"/>
                                          <img src={MeetingsIconJoinInactiveMobile} alt=""
                                               className="icon icon-inactive-mobile"/>
                                          <img src={MeetingsIconJoinInactiveMobile} alt=""
                                               className="icon icon-active-mobile"/>
                                      </Link>
                                    )
                                  )
                                ) : null}

                            </div>
                            <div className={`meeting-details-close ${this.state.toggleMaterials ? "orange" : "blue"}`}>
                                <button onClick={() => this.setState({showDetails: false})}>
                                    <img src={MeetingsIconCloseDetails} alt="Close" className="icon icon-default"/>
                                    <img src={MeetingsIconCloseDetailsMo} alt="Close" className="icon icon-hover"/>
                                    <img src={MeetingsIconCloseDetailsMoBl} alt="Close"
                                         className="icon icon-hover-orange"/>
                                </button>
                            </div>
                        </div>
                    ) : (
                      <Fragment>
                          <div className="meeting-title-bar"
                               onClick={() => !isPresentation ? this.setState({showDetails: true}) : null}>
                              <EquityTheme>
                                  <div className="background d-md-none"
                                       style={{backgroundImage: `url(${meeting.type === 1 ? MeetingsHeader1on1SM : MeetingsHeaderGroupSM})`}}/>
                                    <div className="background d-none d-md-block d-lg-none"
                                         style={{backgroundImage: `url(${meeting.type === 1 ? MeetingsHeader1on1MD : MeetingsHeaderGroupMD})`}}/>
                                    <div className="background d-none d-lg-block d-xl-none"
                                         style={{backgroundImage: `url(${meeting.type === 1 ? MeetingsHeader1on1LG : MeetingsHeaderGroupLG})`}}/>
                                    <div className="background d-none d-xl-block"
                                         style={{backgroundImage: `url(${meeting.type === 1 ? MeetingsHeader1on1XL : MeetingsHeaderGroupXL})`}}/>
                                </EquityTheme>
                                <div className="row title-content">
                                    <div className="col-3 col-md-2 col-time pl-0">
                                        <img src={MeetingsIconClock} alt="" className={`${status === 'current' ? "hide" : ""}`}/>
                                        <span className="d-none d-md-inline-block d-lg-none">
                                            {moment(meeting.startAt).format('ddd')},&nbsp;
                                        </span>
                                        <span className="d-none d-lg-inline-block">
                                            {moment(meeting.startAt).format('dddd')},&nbsp;
                                        </span>
                                        {moment(meeting.realStartAt).format('HH:mm')} - {moment(meeting.realEndAt).format('HH:mm')}
                                        <div className="text-small d-inline-block ml-1">UTC {parseInt(moment(meeting.realStartAt).utcOffset())/60 > 0 ? '+' : ''}{parseInt(moment(meeting.realStartAt).utcOffset())/60}</div>
                                    </div>
                                    <div className={`col-3 px-0 col-md-2 text-center col-details-link ${isPresentation ? 'd-none' : ''}`}>
                                        DETAILS
                                    </div>
                                    <div className={`col-2 pl-0 text-right col-meetingType pr-0 ${isPresentation ? 'offset-3 offset-md-2' : ''}`}>
                                        {isPresentation ? 'Presentation' : (meeting.type === 1 ? '1on1' : 'Group Meeting')} {(meeting.room || meeting.isOnline) && (
                                          <span>({!meeting.isOnline && meeting.room ? 'on site' : 'online'})</span>
                                    )}
                                    </div>
                                </div>
                            </div>
                            <div className="meeting-content">
                                {person.personType === 'company' ? (
                                    <div className="meeting-content-company">
                                        <div className="investors">
                                            <EigenkapitalTheme>
                                                <div className="icon-leinwand">
                                                    <img src={MeetingsIconCanvas} alt="" className={status !== "past" && status !== "current" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                    <img src={MeetingsIconCanvasWhite} alt="" className={status === "current" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                    <img src={MeetingsIconCanvasGray} alt="" className={status === "past" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                </div>
                                            </EigenkapitalTheme>
                                            <div className={`investors-content ${isPresentation ? 'm-0' : ''}`}>
                                                <img src={status === 'past' ? MeetingsIconPersonGray : MeetingsIconPersonBlue} alt=""/>
                                                <div className="investor-persons">
                                                    {isPresentation ? (
                                                        <div className="investor">
                                                            <span className="name">Presentation</span>
                                                            &nbsp;<span
                                                                className="company">{meeting.companyName}</span>
                                                            <p className="presentationInfo">{meeting.room ? `Room ${meeting.room}` : ''}</p>
                                                        </div>
                                                    ) : (
                                                      <Fragment>
                                                          {meeting.investors.map((investor, iI) => (
                                                            <div className="investor" key={iI}>
                                                                <span className="name">{investor.fullName}</span>
                                                                {investor.companyName ? (
                                                                    <Fragment>
                                                                        &nbsp;
                                                                        <span
                                                                            className="company">({investor.companyName})</span>
                                                                    </Fragment>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                        <p className="room">{meeting.room ? `Room ${meeting.room}` : ''}</p>
                                                      </Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {!isPresentation && meeting.isOnline ? (
                                          status === 'past' || (!meeting.isOnline && !(meeting.specialMeetingType && meeting.specialMeetingType.manualLink)) ? (
                                            <span className={`join-event`}>
                                                <img src={MeetingsIconJoinInactive} alt=""
                                                     className="icon icon-inactive"/>
                                                <img src={MeetingsIconJoinActive} alt="" className="icon icon-active"/>
                                                <img src={MeetingsIconJoinActiveMo} alt=""
                                                     className="icon icon-active-mo"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-inactive-mobile"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-active-mobile"/>
                                            </span>
                                          ) : (
                                            meeting.specialMeetingType && meeting.specialMeetingType.manualLink ? (
                                              <a href={meeting.specialMeetingType.manualLink}
                                                 className={`join-event${joinActive ? ' active' : ''}`} target="_blank"
                                                 rel="noopener noreferrer">
                                                  <img src={MeetingsIconJoinInactive} alt=""
                                                       className="icon icon-inactive"/>
                                                  <img src={MeetingsIconJoinActive} alt=""
                                                       className="icon icon-active"/>
                                                  <img src={MeetingsIconJoinActiveMo} alt=""
                                                       className="icon icon-active-mo"/>
                                                  <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                       className="icon icon-inactive-mobile"/>
                                                  <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                       className="icon icon-active-mobile"/>
                                              </a>
                                            ) : (
                                              <Link
                                                to={`/meeting/${meeting.meetingId}`}
                                                className={`join-event${joinActive ? ' active' : ''}`}>
                                                  <img src={MeetingsIconJoinInactive} alt=""
                                                       className="icon icon-inactive"/>
                                                  <img src={MeetingsIconJoinActive} alt=""
                                                       className="icon icon-active"/>
                                                  <img src={MeetingsIconJoinActiveMo} alt=""
                                                       className="icon icon-active-mo"/>
                                                  <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                       className="icon icon-inactive-mobile"/>
                                                  <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                       className="icon icon-active-mobile"/>
                                              </Link>
                                            )

                                          )
                                        ) : null}
                                        <button onClick={this.downloadMeetingIcs}
                                                className={`ics-download ${status === 'current' ? "borderTop" : ""}`}>
                                            <div className="h-100 d-flex">
                                                <img src={MeetingsIconAddToCalendarMD} alt="" className="mt-auto"/>
                                            </div>
                                        </button>
                                    </div>
                                ) : person.personType === 'investor' ? (
                                  <div className="meeting-content-investor">
                                      <div className="companyInfo">
                                          <div className={`companyInfo-content ${isPresentation ? 'd-none' : ''}`}>
                                                <img src={status === 'past' ? MeetingsIconCompanyGray : (status === 'current' ? MeetingsIconCompanyWhite : MeetingsIconCompanyBlack)} alt=""/>
                                                <div className="company-details">
                                                    <div className="company">
                                                        <span className="name">{meeting.companyName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`companyInfo-content ${isPresentation ? 'm-0' : ''}`}>
                                                <EigenkapitalTheme>
                                                    <div className="icon-leinwand h-100">
                                                        <img src={MeetingsIconCanvas} alt="" className={status !== "past" && status !== "current" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                        <img src={MeetingsIconCanvasWhite} alt="" className={status === "current" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                        <img src={MeetingsIconCanvasGray} alt="" className={status === "past" && isPresentation ? 'd-inline-block' : 'd-none'}/>
                                                    </div>
                                                </EigenkapitalTheme>
                                                <img src={status === 'past' ? MeetingsIconPersonGray : (status === 'current' ? MeetingsIconPersonWhite : MeetingsIconPersonBlue)} alt=""/>
                                                <div className="companyRepresentatives-persons">
                                                    {isPresentation ? (
                                                            <div className="investor">
                                                                <span className="name">Presentation</span>
                                                                &nbsp;<span
                                                                className="company">{meeting.companyName}</span>
                                                                <p className="presentationInfo">{meeting.room ? `Room ${meeting.room}` : ''}</p>
                                                            </div>
                                                        ) : (
                                                          <Fragment>
                                                              {meeting.companyRepresentatives.map((companyRepresentative, cRI) => (
                                                                <div className="companyRepresentative" key={cRI}>
                                                                    <span className="name">{companyRepresentative.detailName}</span>
                                                                    {companyRepresentative.position ? (
                                                                      <Fragment>
                                                                          &nbsp;
                                                                          <span
                                                                            className="position">({companyRepresentative.position})</span>
                                                                      </Fragment>
                                                                    ) : null}
                                                                </div>
                                                              ))}
                                                              <p className="room">{meeting.room ? `Room ${meeting.room}` : ''}</p>
                                                          </Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                      {!isPresentation && meeting.isOnline ? (
                                        status === 'past' || (!meeting.isOnline && !(meeting.specialMeetingType && meeting.specialMeetingType.manualLink)) ? (
                                          <span className={`join-event`}>
                                                <img src={MeetingsIconJoinInactive} alt=""
                                                     className="icon icon-inactive"/>
                                                <img src={MeetingsIconJoinActive} alt="" className="icon icon-active"/>
                                                <img src={MeetingsIconJoinActiveMo} alt=""
                                                     className="icon icon-active-mo"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-inactive-mobile"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-active-mobile"/>
                                            </span>
                                        ) : (
                                          meeting.specialMeetingType && meeting.specialMeetingType.manualLink ? (
                                            <a href={meeting.specialMeetingType.manualLink}
                                               className={`join-event${joinActive ? ' active' : ''}`} target="_blank"
                                               rel="noopener noreferrer">
                                                <img src={MeetingsIconJoinInactive} alt=""
                                                     className="icon icon-inactive"/>
                                                <img src={MeetingsIconJoinActive} alt="" className="icon icon-active"/>
                                                <img src={MeetingsIconJoinActiveMo} alt=""
                                                     className="icon icon-active-mo"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-inactive-mobile"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-active-mobile"/>
                                            </a>
                                          ) : (
                                            <Link
                                              to={`/meeting/${meeting.meetingId}`}
                                              className={`join-event${joinActive ? ' active' : ''}`}>
                                                <img src={MeetingsIconJoinInactive} alt=""
                                                     className="icon icon-inactive"/>
                                                <img src={MeetingsIconJoinActive} alt=""
                                                     className="icon icon-active"/>
                                                <img src={MeetingsIconJoinActiveMo} alt=""
                                                     className="icon icon-active-mo"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-inactive-mobile"/>
                                                <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                     className="icon icon-active-mobile"/>
                                            </Link>
                                          )
                                        )
                                      ) : null}
                                      {isPresentation && meeting.specialMeetingType.manualLink ? (
                                        <a href={meeting.specialMeetingType.manualLink}
                                           className={`join-event${joinActive ? ' active' : ''}`} target="_blank"
                                           rel="noopener noreferrer">
                                            <img src={MeetingsIconJoinInactive} alt="" className="icon icon-inactive"/>
                                            <img src={MeetingsIconJoinActive} alt="" className="icon icon-active"/>
                                            <img src={MeetingsIconJoinActiveMo} alt="" className="icon icon-active-mo"/>
                                            <img src={MeetingsIconJoinInactiveMobile} alt=""
                                                 className="icon icon-inactive-mobile"/>
                                            <img src={MeetingsIconJoinInactiveMobile} alt="" className="icon icon-active-mobile"/>
                                          </a>
                                        ) : null}
                                        <button onClick={this.downloadMeetingIcs} className="ics-download">
                                            <div className="h-100 d-flex">
                                                <img src={MeetingsIconAddToCalendarMD} alt="" className="mt-auto"/>
                                            </div>
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </Fragment>
                    )}
                    <div className={`${ meeting.specialMeetingType && meeting.specialMeetingType.type === 'SPONSOR_MEETING' && meeting.specialMeetingType.logo ? 'd-flex' : 'd-none'} hostedby-container position-absolute`}>
                        <div className="text text-uppercase">
                            hosted by
                        </div>
                        <div className="logo flex-grow-1">
                            {meeting.specialMeetingType && meeting.specialMeetingType.type === 'SPONSOR_MEETING' && meeting.specialMeetingType.logo ? (
                                <img className="m-auto" alt="" src={`${process.env.REACT_APP_API_URL}meeting/sponsor/logo/${meeting.specialMeetingType.logo}`}/>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className={`containerMe-mobile text-right col-meetingType pr-0`}>
                    {isPresentation ? 'Presentation' : (meeting.type === 1 ? '1on1' : 'Group Meeting')}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization,
    project: state.project
});

export default connect(mapStateToProps, {})(Meeting);
