import Split from 'split.js'

let splitInstance;

class ZoomHelper {
  static extractUserInformation(str) {
    const regex = /^(.*?)\s*\[(.*?)]$/;
    const match = str?.match(regex);

    return match ? {
      displayName: match[1],
      companyName: match[2]
    } : null;
  }

  static setOtherScreenShare(zoomClient, userId, isActive) {
    const mediaStream = zoomClient.getMediaStream();
    const split1Container = document.getElementById('split1');
    let otherScreenShareContainer = split1Container.querySelector('div.otherScreenShare');
    if (isActive) {
      if (!otherScreenShareContainer) {
        otherScreenShareContainer = document.createElement('div');
        otherScreenShareContainer.classList.add('video-outer');
        otherScreenShareContainer.classList.add('otherScreenShare');
        split1Container.append(otherScreenShareContainer);
      }
      let shareCanvas = otherScreenShareContainer.querySelector('canvas');
      if (!shareCanvas) {
        shareCanvas = document.createElement('canvas');
        otherScreenShareContainer.append(shareCanvas);
      }
      let nameContainer = otherScreenShareContainer.querySelector('.name-container');
      if (!nameContainer) {
        nameContainer = document.createElement('div');
        nameContainer.classList.add('name-container');
        otherScreenShareContainer.append(nameContainer);
      }
      let displayName = zoomClient.getAllUser()?.find(p => p.userId === userId)?.displayName;
      if (displayName) {
        nameContainer.innerHTML = this.extractUserInformation(displayName)?.displayName;
      }
      mediaStream.startShareView(shareCanvas, userId).then().catch(e => {
        console.error(`could not start share view of user ${userId}`, e);
      });
      splitInstance = Split(['#split1', '#split2'], {
        onDragStart(sizes) {
          document.getElementById('split2').style.height = '0';
        },
        onDragEnd(sizes) {
          document.getElementById('split2').style.height = '';
        }
        
    });
    } else {
      if (splitInstance) {
        splitInstance.destroy();
      }
      if (otherScreenShareContainer) {
        mediaStream.stopShareView().then().catch(e => {
          console.error(`could not stop share view of user ${userId}`, e);
        });
        otherScreenShareContainer.remove();
      }
    }
  }

  static setOwnScreenShare(zoomClient, isActive, stopScreenShare) {
    const mediaStream = zoomClient.getMediaStream();
    const shareContainer = document.getElementById('zoomOwnShareContainer');
    if (isActive) {
      if (!shareContainer.querySelector('.OwnShare')) {
        if (mediaStream.isStartShareScreenWithVideoElement()) {
          const videoElement = document.createElement("video");
          videoElement.classList.add('OwnShare');
          videoElement.width = 1920;
          videoElement.height = 1080;
          shareContainer.append(videoElement);
          try {
            mediaStream
              .startShareScreen(videoElement)
              .then(() => {
                // screen share successfully started and rendered
              })
              .catch((error) => {
                console.error(`could not start own screen share in video element`, error)
                stopScreenShare();
              })
          } catch (e) {
            console.error(`could not start own screen share in video element`, e)
          }

        } else {
          const canvasElement = document.createElement("canvas");
          canvasElement.classList.add('OwnShare');
          canvasElement.width = 1920;
          canvasElement.height = 1080;
          shareContainer.append(canvasElement);
          try {
            mediaStream
              .startShareScreen(canvasElement)
              .then(() => {
                // screen share successfully started and rendered
              })
              .catch((error) => {
                console.error(`could not start own screen share in video element`, error)
                stopScreenShare();
              })
          } catch (e) {
            console.error(`could not start own screen share in video element`, e)
          }
        }
      }
    } else {
      mediaStream.stopShareScreen().then(() => {
        const shareElement = shareContainer.querySelector('.OwnShare');
        if (shareElement) {
          shareElement.remove();
        }
      }).catch(e => {
        console.error(`could not stop own screen share`, e);
      });
    }
  }

  static renderActiveSpeaker(zoomClient, userId) {
    let userIdToShow = userId;
    const mediaStream = zoomClient.getMediaStream();
    if (!userId) {
      userIdToShow = zoomClient.getCurrentUserInfo().userId;
    }
    const split2Container = document.getElementById('split2');
    let activeSpeakerContainer = split2Container.querySelector('div.activeSpeakerBig');
    if (!activeSpeakerContainer) {
      activeSpeakerContainer = document.createElement("div");
      activeSpeakerContainer.classList.add("video-outer");
      activeSpeakerContainer.classList.add("activeSpeakerBig");
      activeSpeakerContainer.setAttribute('user-id', userIdToShow);
      split2Container.append(activeSpeakerContainer);
    }
    const participants = zoomClient.getAllUser();
    const participant = participants?.find(p => p.userId === userIdToShow);
    if (activeSpeakerContainer.getAttribute('user-id') === userIdToShow?.toString()) {
      if (activeSpeakerContainer.classList.contains('no-video') && participant.bVideoOn) {
        mediaStream.attachVideo(userIdToShow, 3).then((userVideo) => {
          activeSpeakerContainer.appendChild(userVideo);
          activeSpeakerContainer.classList.remove('no-video');
        })
      } else if (!activeSpeakerContainer.classList.contains('no-video') && !participant.bVideoOn) {
        const videoPlayer = activeSpeakerContainer.querySelector('video-player');
        if (videoPlayer) {
          //mediaStream.detachVideo(userIdToShow, videoContainer).then(() => {}).catch((e) => {}) ;
          videoPlayer.remove();
        }
        activeSpeakerContainer.classList.add('no-video');
      }
    } else {
      activeSpeakerContainer.setAttribute('user-id', userIdToShow);
      const videoPlayer = activeSpeakerContainer.querySelector('video-player');
      if (videoPlayer) {
        videoPlayer.remove();
      }
      if (participant.bVideoOn) {
        mediaStream.attachVideo(userIdToShow, 3).then((userVideo) => {
          activeSpeakerContainer.appendChild(userVideo);
          activeSpeakerContainer.classList.remove('no-video');
        })
      } else {
        activeSpeakerContainer.classList.add('no-video');
      }
      let nameContainer = activeSpeakerContainer.querySelector('.name-container');
      if (!nameContainer) {
        nameContainer = document.createElement('div');
        nameContainer.classList.add('name-container');
        activeSpeakerContainer.append(nameContainer);
      }
      let displayName = participant?.displayName;
      if (displayName) {
        nameContainer.innerHTML = this.extractUserInformation(displayName)?.displayName;
      }
    }
  }

  static setUserVideo(zoomClient, userId, quality, videoOn, audioOn) {
    const mediaStream = zoomClient.getMediaStream();
    const videoContainer = document.getElementById('zoomVideoContainer');
    let userElement = videoContainer.querySelector(`div[user-id="${userId}"]:not(.activeSpeakerBig):not(.otherScreenShare)`);
    if (!userElement) {
      userElement = document.createElement("div");
      userElement.classList.add("video-outer");
      userElement.setAttribute('user-id', userId);
      if (zoomClient.getCurrentUserInfo().userId === userId) {
        userElement.classList.add("self");
        videoContainer.append(userElement);
      } else {
        videoContainer.insertBefore(userElement, videoContainer.firstChild);
      }
    }
    if (videoOn) {
      if (!userElement.querySelector('video-player')) {
        mediaStream.attachVideo(userId, quality).then((userVideo) => {
          userElement.appendChild(userVideo);
          userElement.classList.remove('no-video');
        }).catch(e => {
          console.error(`could not attach video of own user`, e);
        });
      }
    } else {
      const videoPlayer = userElement.querySelector('video-player');
      if (videoPlayer) {
        //mediaStream.detachVideo(userId, videoContainer).then(() => {}).catch((e) => {}) ;
        videoPlayer.remove();
      }
      userElement.classList.add('no-video');
    }
    let nameContainer = userElement.querySelector('.name-container');
    if (!nameContainer) {
      nameContainer = document.createElement('div');
      nameContainer.classList.add('name-container');
      userElement.append(nameContainer);
    }
    let displayName = zoomClient.getAllUser()?.find(p => p.userId === userId)?.displayName;
    if (displayName) {
      nameContainer.innerHTML = this.extractUserInformation(displayName)?.displayName;
    }
    if (audioOn) {
      userElement.classList.remove('no-audio');
    } else {
      userElement.classList.add('no-audio');
    }
  }

  static removeUserVideo(userId) {
    const videoContainer = document.getElementById('zoomVideoContainer');
    let userElement = videoContainer.querySelector(`div[user-id="${userId}"]`);
    if (userElement) {
      userElement.remove();
    }
  }

  static setCurrentUserCapturing(zoomClient, virtualBackground, cameraId, microphoneId, speakerId, videoMuted, audioMuted) {
    const mediaStream = zoomClient.getMediaStream();
    if (videoMuted) {
      this.setUserVideo(zoomClient, zoomClient.getCurrentUserInfo().userId, 2, false, !audioMuted);
      if (mediaStream.isCapturingVideo()) {
        mediaStream.stopVideo().then(() => {}).catch(e => {
          console.error('could not stop video', e);
        })
      }
    } else {
      if (!mediaStream.isCapturingVideo()) {
        mediaStream.startVideo({cameraId: cameraId, virtualBackground: virtualBackground ? {imageUrl: 'blur'} : undefined}).then(() => {
            ZoomHelper.setUserVideo(zoomClient, zoomClient.getCurrentUserInfo().userId, 2, true, !audioMuted);
        }).catch(e => {
          console.error('could not start video', e);
        })
      } else {
        mediaStream.updateVirtualBackgroundImage(virtualBackground ? 'blur' : undefined).then(() => {}).catch(e => {
          console.error('could not update virtual background image', e);
        });
      }
    }
    mediaStream.startAudio({microphoneId: microphoneId, speakerId: speakerId}).then(() => {
      if (audioMuted) {
        mediaStream.muteAudio().then(() => {}).catch(e => {
          console.error('could not mute audio', e);
        });
      } else {
        mediaStream.unmuteAudio().then(() => {}).catch(e => {
          console.error('could not unmute audio', e);
        })
      }
    }).catch(e => {
      console.error('could not start audio', e);
    });
  }
}

export default ZoomHelper;