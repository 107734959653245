import {useContext} from "react";
import {ZoomContext} from "../context/ZoomContext";
import {useHistory} from "react-router-dom";
import Close from "../../../themes/equityforum/gfx/meeting/Close";

const LeaveMeeting = ({setIsBlocking}) => {
  const zoomClient = useContext(ZoomContext);
  let history = useHistory();

  const handleLeave = () => {
    setIsBlocking(false);
    setTimeout(() => {
      try {
        zoomClient?.leave();
      } catch(e) {
        console.error('could not leave meeting', e);
      }
      history.push('/');
    }, 500);
  }

  return (
    <div className="LeaveMeeting">
      <button className="leave-button" onClick={handleLeave}>
      <Close/>
        Leave<br/>Meeting
      </button>
    </div>
  )
}

export default LeaveMeeting;