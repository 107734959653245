import React from 'react';
import {Fragment} from "react";
import {connect} from "react-redux";
import {
  authorizationSetHasSeenWelcomeScreen
} from "../../store/authorization/authorizationAction";
import {
  LoginLogoFullBlue,
  WelcomeBackground,
  WelcomeVid,
  WelcomeVidLg,
  WelcomeLogo, WelcomeLogo2
} from "../helpers/GfxPaths";
import EigenkapitalTheme from "../helpers/EigenkapitalTheme";
import EquityTheme from "../helpers/EquityTheme";

class WelcomeScreen extends React.Component {

  closeTimeout = null;

  componentDidMount() {
    if (!this.closeTimeout) this.closeTimeout = setTimeout(this.props.authorizationSetHasSeenWelcomeScreen, 4000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.authorization.hasSeenWelcomeScreen !== this.props.authorization.hasSeenWelcomeScreen) {
      if (!this.closeTimeout) this.closeTimeout = setTimeout(this.props.authorizationSetHasSeenWelcomeScreen, 4000);
    }
  }

  componentWillUnmount() {
    if (this.closeTimeout) clearTimeout(this.closeTimeout);
  }

  render() {
    const {authorization} = this.props;
    return !authorization.hasSeenWelcomeScreen ? (
      <Fragment>
        <EigenkapitalTheme>
          <div className="WelcomeScreen">
            <div className="bg" style={{backgroundImage: `url(${WelcomeBackground})`}}/>
            <div className="container text">
              <div className="row logo-row d-none d-lg-flex">
                <div className="col-3 logo-col">
                  <img src={LoginLogoFullBlue} alt=""/>
                </div>
                <div className="col-3 logo-col text-right">
                  <img src={WelcomeLogo2} alt=""/>
                </div>
              </div>
              <div className="row text-row">
                <div className="col-6 text-center">
                  <p>Welcome, {authorization.username}!</p>
                </div>
              </div>
            </div>
          </div>
        </EigenkapitalTheme>
        <EquityTheme>
          <div className="WelcomeScreen d-flex align-items-center">
            <video className="bgVideo d-lg-none" autoPlay loop muted>
              <source src={WelcomeVid} type="video/mp4"/>
            </video>
            <video className="bgVideo d-none d-lg-block" autoPlay loop muted>
              <source src={WelcomeVidLg} type="video/mp4"/>
            </video>
            <div className="text w-100">
              <p>Welcome to the</p>
              <div className="border-logo">
                <div className="image-container w-100">
                  <img alt="" src={WelcomeLogo} className="logo"/>
                </div>
              </div>
              <p>{authorization.username}</p>
            </div>
          </div>
        </EquityTheme>
      </Fragment>
    ) : null
  }
}

const mapStateToProps = state => ({
  authorization: state.authorization
});

export default connect(mapStateToProps, {
  authorizationSetHasSeenWelcomeScreen
})(WelcomeScreen);
