import React, {useContext} from "react";
import {MeetingParticipantsContext} from "../../../context/MeetingParticipantsContext";
import Speaker from "../../../../../themes/equityforum/gfx/meeting/Speaker";
import classNames from "classnames";
import Camera from "../../../../../themes/equityforum/gfx/meeting/Camera";
import ZoomHelper from "../../../../../config/zoomHelper";

const People = () => {
  const participants = useContext(MeetingParticipantsContext);

  return (
    <div className="People">
      {participants?.map((participant, pI) => (
        <div className="participant" key={pI}>
          <div className="icons">
            <div className={classNames('icon speaker', !participant.muted ? 'active' : '')}>
              <Speaker/>
            </div>
            <div className={classNames('icon camera', participant.bVideoOn ? 'active' : '')}>
              <Camera/>
            </div>
          </div>
          <div className="description">
            <div className="name">
              {ZoomHelper.extractUserInformation(participant.displayName)?.displayName}
              {participant.isPhoneUser ? (
                <span className="isPhoneUser ml-1">
                  (by phone)
                </span>
              ) : null}
            </div>
            <div className="company">
              {ZoomHelper.extractUserInformation(participant.displayName)?.companyName}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default People;