import React from 'react';

class GeneralInfo extends React.Component {
    render() {
        const {message} = this.props;
        return (
            <div className="c-input general-info-container">
                <div className="message">
                    {message}
                </div>
            </div>
        )
    }
}

export default GeneralInfo;
