import authorizationReducer from "./authorization/authorizationReducer";
import {combineReducers} from "redux";
import projectReducer from "./project/projectReducer";
import meetingReducer from "./meeting/meetingReducer";

const efDashboard = combineReducers({
    authorization: authorizationReducer,
    project: projectReducer,
    meeting: meetingReducer,
});

export default function (state, action) {
    return efDashboard(state, action);
}
