import React from 'react';
import TextInput from "../../elements/form/TextInput";
import SubmitButton from "../../elements/form/SubmitButton";
import history from "../../history";
import AuthorizationApi from "../../api/AuthorizationApi";
import {connect} from "react-redux";
import {authorizationLoginSuccess} from "../../store/authorization/authorizationAction";
import {LoginButtonResetPassword, LoginButtonResetPasswordMo} from '../../elements/helpers/GfxPaths'

class ForgotPassword extends React.Component {

    state = {
        username: '',
        error: ''
    };

    submitForm = (e) => {
      e.preventDefault();
      AuthorizationApi.passwordToken(this.state.username)
        .then((response) => {
          history.push("/sentToken");
        }).catch(error => {
          this.setState({error: true})
        });
    };

    render() {
        const error = this.state.error;
        return (
            <form className="forgotPassword-form" onSubmit={this.submitForm}>
                <h5>Reset your password via mail:</h5>
                <TextInput label="E-MAIL / USERNAME" value={this.state.username}
                           onChange={(e) => this.setState({username: e.target.value})}/>
                <SubmitButton>
                    <img alt="" className="img img-default" src={LoginButtonResetPassword}/>
                    <img alt="" className="img img-mo" src={LoginButtonResetPasswordMo}/>
                </SubmitButton>
                <div>
                    {error ? (
                        <p className='error-message text-danger'>This account is currently disabled.</p>
                    ) : null}
                </div>
            </form>
        )
    }
}

export default connect(null, {
    authorizationLoginSuccess
})(ForgotPassword);
