import {
  MEETING_SET_AUDIO_MUTED,
  MEETING_SET_CAMERA_ID,
  MEETING_SET_MICROPHONE_ID,
  MEETING_SET_SPEAKER_ID,
  MEETING_SET_VIDEO_MUTED, MEETING_SET_VIRTUAL_BACKGROUND
} from "../../config/actions";

export const meetingSetCameraId = (cameraId) => {
  return {
    type: MEETING_SET_CAMERA_ID,
    cameraId,
  }
}

export const meetingSetMicrophoneId = (microphoneId) => {
  return {
    type: MEETING_SET_MICROPHONE_ID,
    microphoneId,
  }
}

export const meetingSetSpeakerId = (speakerId) => {
  return {
    type: MEETING_SET_SPEAKER_ID,
    speakerId,
  }
}

export const meetingSetVideoMuted = (videoMuted) => {
  return {
    type: MEETING_SET_VIDEO_MUTED,
    videoMuted
  }
}

export const meetingSetAudioMuted = (audioMuted) => {
  return {
    type: MEETING_SET_AUDIO_MUTED,
    audioMuted
  }
}

export const meetingSetVirtualBackground = (virtualBackground) => {
  return {
    type: MEETING_SET_VIRTUAL_BACKGROUND,
    virtualBackground
  }
}