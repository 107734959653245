const Play = () => (
  <svg id="Gruppe_123" data-name="Gruppe 123" xmlns="http://www.w3.org/2000/svg" width="37.963" height="37.964"
       viewBox="0 0 37.963 37.964">
    <g id="Gruppe_85" data-name="Gruppe 85" transform="translate(0)">
      <path id="Pfad_29" data-name="Pfad 29"
            d="M1171.132,404.508a18.982,18.982,0,1,1,18.98-18.982A19,19,0,0,1,1171.132,404.508Zm0-34.918a15.936,15.936,0,1,0,15.935,15.936A15.954,15.954,0,0,0,1171.132,369.59Z"
            transform="translate(-1152.149 -366.544)" fill="currentColor"/>
    </g>
    <path id="Pfad_30" data-name="Pfad 30" d="M1175.8,383.513l-11.4-6.584V390.1Z"
          transform="translate(-1149.775 -364.531)" fill="currentColor"/>
  </svg>
)

export default Play;