import {useContext, useEffect, useState} from "react";
import {ZoomContext} from "../../../context/ZoomContext";
import ZoomHelper from "../../../../../config/zoomHelper";
import Play from "../../../../../themes/equityforum/gfx/meeting/Play";

const NameSettings = () => {
  const zoomClient = useContext(ZoomContext);

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');

  const handleChangeUserName = () => {
    const ownUserId = zoomClient.getCurrentUserInfo()?.userId;
    zoomClient.changeName(`${name} [${company}]`, ownUserId).then(() => {}).catch(e => {
      console.error('could not change username', e);
    });
  }

  useEffect(() => {
    const currentUser = zoomClient.getCurrentUserInfo();
    const names = ZoomHelper.extractUserInformation(currentUser.displayName);
    setName(names?.displayName);
    setCompany(names?.companyName);
  }, []);

  useEffect(() => {
    const ownUserId = zoomClient.getCurrentUserInfo()?.userId;

    const updateUserDisplayName = (payload) => {
      if (payload?.userId === ownUserId && payload?.displayName) {
        const names = ZoomHelper.extractUserInformation(payload.displayName);
        setName(names?.displayName);
        setCompany(names?.companyName);
      }
    }

    zoomClient.on('user-updated', updateUserDisplayName);

    return () => {
      zoomClient.off('user-updated', updateUserDisplayName);
    }
  });

  return (
    <div className="NameSettings">
      <div>
        <label>Name</label>
        <input value={name} onChange={e => setName(e.target.value)}/>
      </div>
      <div>
        <label>Company</label>
        <input value={company} onChange={e => setCompany(e.target.value)}/>
      </div>
      <button onClick={handleChangeUserName}>
      <Play/>Save
      </button>
    </div>
  )
}

export default NameSettings;