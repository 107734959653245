import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {ZoomContext} from "../../../context/ZoomContext";
import DeviceSelect from "../../../DeviceSelect";
import classNames from "classnames";
import Speaker from "../../../../../themes/equityforum/gfx/meeting/Speaker";
import Camera from "../../../../../themes/equityforum/gfx/meeting/Camera";
import {
  meetingSetAudioMuted,
  meetingSetCameraId, meetingSetMicrophoneId, meetingSetSpeakerId,
  meetingSetVideoMuted, meetingSetVirtualBackground
} from "../../../../../store/meeting/meetingAction";
import VideoMuted from "../../../../../themes/equityforum/gfx/meeting/VideoMuted";
import VideoUnmuted from "../../../../../themes/equityforum/gfx/meeting/VideoUnmuted";
import AudioMuted from "../../../../../themes/equityforum/gfx/meeting/AudioMuted";
import AudioUnmuted from "../../../../../themes/equityforum/gfx/meeting/AudioUnmuted";
import Checkbox from "../../../../../elements/form/Checkbox";

const DeviceSettings = ({phoneAsSource}) => {
  const zoomClient = useContext(ZoomContext);
  const meetingStore = useSelector(state => state.meeting);
  const dispatch = useDispatch();

  const [videoDevices, setVideoDevices] = useState([]);
  const [microphoneDevices, setMicrophoneDevices] = useState([]);
  const [speakerDevices, setSpeakerDevices] = useState([]);

  useEffect(() => {
    const mediaStream = zoomClient.getMediaStream();
    const updateDevicesLists = () => {
      setVideoDevices(mediaStream.getCameraList());
      setMicrophoneDevices(mediaStream.getMicList());
      setSpeakerDevices(mediaStream.getSpeakerList());
    }

    updateDevicesLists();
    zoomClient.on('device-change', updateDevicesLists);

    return () => {
      zoomClient.off('device-change', updateDevicesLists);
    }
  }, []);

  const videoDeviceSelectOptions = videoDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));
  const microphoneDeviceSelectOptions = microphoneDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));
  const speakerDeviceSelectOptions = speakerDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));

  const handleChangeCamera = (cameraId) => {
    const mediaStream = zoomClient.getMediaStream();
    dispatch(meetingSetCameraId(cameraId));
    mediaStream.switchCamera(cameraId).then(() => {}).catch(e => {
      console.error('could not switch camera', e);
    })
  }

  const handleChangeMicrophone = (microphoneId) => {
    const mediaStream = zoomClient.getMediaStream();
    dispatch(meetingSetMicrophoneId(microphoneId));
    mediaStream.switchMicrophone(microphoneId).then(() => {}).catch(e => {
      console.error('could not switch microphone', e);
    })
  }

  const handleChangeSpeaker = (speakerId) => {
    const mediaStream = zoomClient.getMediaStream();
    dispatch(meetingSetSpeakerId(speakerId));
    mediaStream.switchSpeaker(speakerId).then(() => {}).catch(e => {
      console.error('could not switch speaker', e);
    })
  }

  const handleSetVirtualBackground = (virtualBackground) => {
    dispatch(meetingSetVirtualBackground(virtualBackground));
  }

  return (
    <div className="DeviceSettings">
      <div className="select-container">
        <DeviceSelect label="Cam" options={videoDeviceSelectOptions}
                      value={videoDeviceSelectOptions?.find(el => el.value === meetingStore?.cameraId)}
                      onChange={(option) => handleChangeCamera(option.value)}/>
        <DeviceSelect label="Mic" options={microphoneDeviceSelectOptions}
                      value={microphoneDeviceSelectOptions?.find(el => el.value === meetingStore?.microphoneId)}
                      onChange={(option) => handleChangeMicrophone(option.value)}/>
        <DeviceSelect label="Speaker" options={speakerDeviceSelectOptions}
                      value={speakerDeviceSelectOptions?.find(el => el.value === meetingStore?.speakerId)}
                      onChange={(option) => handleChangeSpeaker(option.value)}/>
        {typeof SharedArrayBuffer === 'function' ? (
          <Checkbox label="Background blur" checked={meetingStore.virtualBackground}
                    className=""
                    onChange={(e) => handleSetVirtualBackground(e.target.checked)}/>
        ) : null}
        <div className="muting-container">
          <div className="indicators">
            <div
              className={classNames("indicator indicator-audio", !meetingStore?.audioMuted ? 'is-active' : '')}>
              <Speaker/>
            </div>
            <div
              className={classNames("indicator indicator-video", !meetingStore?.videoMuted ? 'is-active' : '')}>
              <Camera/>
            </div>
          </div>
          <div className={classNames("muting muting-video", meetingStore?.videoMuted ? 'is-muted' : '')}>
            <button onClick={() => dispatch(meetingSetVideoMuted(!meetingStore?.videoMuted))}>
              <div className="icon-container">
                <VideoMuted/>
                <VideoUnmuted/>
              </div>
              <div className="text-container">
                <div className="text-muted">
                  Unmute<br/>
                  Video
                </div>
                <div className="text-unmuted">
                  Mute<br/>
                  Video
                </div>
              </div>
            </button>
          </div>
          <div className={classNames("muting muting-audio", meetingStore?.audioMuted ? 'is-muted' : '')}>
            <button onClick={() => dispatch(meetingSetAudioMuted(!meetingStore?.audioMuted))} disabled={phoneAsSource}>
              <div className="icon-container">
                <AudioMuted/>
                <AudioUnmuted/>
              </div>
              <div className="text-container">
                <div className="text-muted">
                  Unmute<br/>
                  Audio
                </div>
                <div className="text-unmuted">
                  Mute<br/>
                  Audio
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceSettings;