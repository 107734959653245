import React from 'react';
import TextInput from "../../elements/form/TextInput";
import SubmitButton from "../../elements/form/SubmitButton";
import {LoginButtonSubmitLG} from "../../elements/helpers/GfxPaths";
import AuthorizationApi from "../../api/AuthorizationApi";
import GeneralError from "../../elements/form/GeneralError";
import {connect} from "react-redux";
import {authorizationLoginSuccess} from "../../store/authorization/authorizationAction";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import GeneralInfo from "../../elements/form/GeneralInfo";
import {withRouter} from "react-router";

function removeAfterLastSlash(url) {
  const lastSlashIndex = url.lastIndexOf('/');
  return url.substring(0, lastSlashIndex);
}

class Login extends React.Component {

  state = {
    username: '',
    password: '',
    sendTokenSuccess: false,
    sendTokenError: false,
    loginError: false,
    loginErrorMessage: '',
    hasToken: false
  };

  componentDidMount() {
    if (this.props.match.params.token) {
      this.setState({hasToken: true});
      AuthorizationApi.login(this.props.match.params.token, this.props.match.params.token)
        .then((response) => {
          if (response.status === 200) {
            let {access_token, username, roles} = response.data;
            this.props.authorizationLoginSuccess(access_token, username, roles);
          } else {
            this.setState({loginError: true});
          }
        })
        .catch((err) => {
          this.setState({loginError: true});
          const location = this.props.history.location;
          this.props.history.replace({
            ...location,
            pathname: removeAfterLastSlash(location.pathname),
          })
          if (err.response.status === 403) {
            this.setState({loginErrorMessage: 'This account is currently disabled.'})
          } else {
            this.setState({loginErrorMessage: 'The link you used seems to be not valid anymore. Please request a new one.'})
          }
        });
    }
  }

  submitForm = (e) => {
    this.setState({sendTokenSuccess: false, sendTokenError: false, loginError: false, loginErrorMessage: ''});
    e?.preventDefault();
    AuthorizationApi.sendLoginToken(this.state.username)
      .then((response) => {
        if (response.status === 200) {
          this.setState({sendTokenSuccess: true, username: ''});
        } else {
          this.setState({sendTokenError: true});
        }
      })
      .catch((err) => {
        this.setState({sendTokenError: true});
      });
  };

  render() {
    const loginErrorMessage = this.state.loginErrorMessage
    return this.props.match.params.token && !this.state.loginError ? (
      <GeneralInfo message={'Trying to log you in...'}/>
    ) : (
      <form className="login-form m-lg-auto" onSubmit={this.submitForm}>
        <EigenkapitalTheme>
          <p>Welcome. Please enter your login data* to get access to your 1on1-meetings and press meetings.</p>
        </EigenkapitalTheme>
        {this.state.sendTokenSuccess ? (
          <GeneralInfo
            message={'If your user exists and is active, the login data has been sent to your email address. Please check your inbox.'}/>
        ) : null}
        <TextInput label="E-MAIL / USERNAME" value={this.state.username}
                   onChange={(e) => this.setState({username: e.target.value})}/>
        {this.state.sendTokenError ? (
          <GeneralError
            errorMessage={'The login data could not be sent. Please try again.'}/>
        ) : null}
        {this.state.loginError ? (
          <GeneralError
            errorMessage={loginErrorMessage}/>
        ) : null}
        <EigenkapitalTheme>
          <p>*Please use the login data which has been sent to you by 1on1@equityforum.de. Contact: 0049 69 75 00 66
            25</p>
        </EigenkapitalTheme>
        <SubmitButton>
          <img alt="" className="img img-default d-inline" src={LoginButtonSubmitLG}/>
          <img alt="" className="img img-mo" src={LoginButtonSubmitLG}/>
        </SubmitButton>
      </form>
    )
  }
}

export default connect(null, {
  authorizationLoginSuccess
})(withRouter(Login));
