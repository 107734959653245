import {useContext, useEffect, useRef, useState} from "react";
import {useParams, useHistory } from "react-router-dom";
import {ZoomContext} from "../context/ZoomContext";
import {useSelector} from "react-redux";
import Close from "../../../themes/equityforum/gfx/meeting/Close";
import IconStop from "../../../themes/equityforum/gfx/meeting/IconStop";
import TeleDial from "../../../themes/equityforum/gfx/meeting/TeleDial";
import ZoomVideo from "@zoom/videosdk";

const Phone = ({meetingJWT, meetingName}) => {
  const history = useHistory();
  const {id} = useParams();
  const zoomClient = useContext(ZoomContext);
  const projectStore = useSelector(state => state.project);
  const authorizationStore = useSelector(state => state.authorization);

  const [countryCode, setCountryCode] = useState('+49');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCalling, setIsCalling] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const intervalRef = useRef(null);

  const startCallOut = async () => {
    await zoomClient.init('en-US', 'Global', {
      enforceMultipleVideos: true
    });
    const mediaStream = zoomClient.getMediaStream();
    const projectPerson = projectStore?.projectPersons?.find((pp) => pp.personType === authorizationStore?.selectedPersonType && pp.personId === authorizationStore?.selectedPersonId);
    const displayName = `${projectPerson.personName} [${projectPerson.companyName}]`;
    await zoomClient.join(meetingName, meetingJWT, displayName);
    if (zoomClient.getAllUser()?.length > 1) {
      setIsCalling(true);
      setIsWaiting(false);
      let phoneNumberToCall = phoneNumber?.replace(/^0+/, '');
      mediaStream.inviteByPhone(countryCode, phoneNumberToCall, displayName).then((result) => {
        console.log('result', result);
      }).catch(error => {
        console.log('error', error);
        zoomClient.leave();
      });
    } else {
      zoomClient.leave();
      setIsWaiting(true);
      intervalRef.current = setTimeout(startCallOut, 60000);
      return;
    }

    zoomClient.on('dialout-state-change', (payload) => {
      if (payload?.code === 3) {
        setShowSuccess(true);
        zoomClient.leave();
      }
    })
  }

  const handleCancelWaiting = () => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    setIsWaiting(false);
  }

  const handleCancel = () => {
    const mediaStream = zoomClient.getMediaStream();
    let phoneNumberToCall = phoneNumber?.replace(/^0+/, '');
    mediaStream.cancelInviteByPhone(countryCode, phoneNumberToCall).then((result) => {}).catch(error => {});
    setIsCalling(false);
    zoomClient.leave();
  }

  const handleClose = () => {
    history.goBack();
  }

  useEffect(() => {
    return () => {
      try {
        if (intervalRef.current) {
          clearTimeout(intervalRef.current);
        }
        zoomClient.leave();
        ZoomVideo.destroyClient();
      } catch (e) {}
    }
  }, []);

  return (
    <div className="phone">

      {showSuccess ? (
        <div className="text-white">
          You entered the meeting by phone
        </div>
      ) : (
        <div>
          <button className="phone__closer" onClick={handleClose}>
            <Close/>
          </button>
          <div>
            <label>Country code</label>
            <input className="form-control" value={countryCode} onChange={e => setCountryCode(e.target.value)} disabled={!!isCalling} placeholder="Country code"/>
          </div>
          <div>
            <label>Phone Number</label>
            <input className="form-control" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} disabled={!!isCalling} placeholder="Your Phone Number"/>
          </div>
          <button className="btn btn-primary" onClick={startCallOut} disabled={!!isCalling}>
          <TeleDial/>
            <div>
            Call me
            </div>
          </button>
          {isWaiting ? (
            <div className="mt-5 text-white">
              Waiting for other participants to join the meeting. We will start the dial-out as soon as possible.<br/>
              <button onClick={handleCancelWaiting}>
                Cancel waiting
              </button>
            </div>
          ) : null}
          {isCalling ? (
            <div className="mt-5 text-white">
              Stand by, please. The dial-out will start within a minute.<br/>
              <button onClick={handleCancel}>
              <IconStop/>Cancel call
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default Phone;