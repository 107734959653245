const MicrophonePreview = ({amount}) => {
  return (
    <div className="MicrophonePreview">
      <div className="icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="11.088" height="16.995" viewBox="0 0 11.088 16.995">
          <path id="Pfad_1397" data-name="Pfad 1397"
                d="M1252.057,2178.21l-4.652,4.644h-3.318a1.512,1.512,0,0,0-1.512,1.512v4.233a1.512,1.512,0,0,0,1.512,1.512h3.318l4.652,4.644a.982.982,0,0,0,1.605-.759v-15.027A.982.982,0,0,0,1252.057,2178.21Z"
                transform="translate(-1242.575 -2177.985)" fill="#d8f7fc"/>
        </svg>
      </div>
      <div className="dots-container">
        {Array.from({ length: 12 }, (_, i) => i).map((i) => (
          <div key={i} className="dot" style={{color: amount > (i + 1) * 10 ? '#F37F29' : '#FFFFFF'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="17" viewBox="0 0 4 17">
              <rect id="Rechteck_1551" data-name="Rechteck 1551" width="4" height="17" rx="2" fill="currentColor"/>
            </svg>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MicrophonePreview;