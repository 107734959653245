import React from 'react';
import ScrollToTop from "../../elements/helpers/ScrollToTop";
import {PrivacyLogoGrey, PrivacyButtonPrevious} from "../../elements/helpers/GfxPaths";
import EquityTheme from "../../elements/helpers/EquityTheme";


class Privacy extends React.Component {
    render() {
        return (
            <div className="page page-privacy">
                <ScrollToTop/>
                <EquityTheme>
                    <button className="button-prevPage" onClick={() => window.history.back()} >
                        <img className="icon" src={PrivacyButtonPrevious} alt=""/>
                        <span>
                            previous Page
                        </span>
                    </button>
                </EquityTheme>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="logo-privacy d-none d-md-inline-block d-lg-none">
                                <img src={PrivacyLogoGrey} alt=""/>
                            </div>
                            <div className="header-privacy d-md-inline-block">
                                Data Protection
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-4">
                            <div className="text-privacy">
                                <div className="MaisonNeueBookItalic">
                                    <p>
                                        We at Equity Forum UG fully respect your concerns about data privacy and we are
                                        constantly looking to improve the measures used to protect the stored data. We only
                                        collect and store data and information provided to us voluntarily. We protect, use and
                                        process your data in compliance with the current data protection regulations of the
                                        Federal Republic of Germany, of May 25, 2018 in compliance with the new data
                                        protection act (DSGVO resp. GDRP).
                                        By using and/or accessing our Services you acknowledge that you have read and
                                        understood this Privacy Policy.
                                    </p>
                                </div>
                                <div className="MaisonNeue">
                                    <p>
                                        <strong>Collection and Processing of Personal Data</strong>
                                        When you visit our website, our web server logs - for statistical purposes - only the IP
                                        address or URL of your internet provider, the original website from where you are
                                        connecting to us and the content on which you click in connection with date, time and
                                        dwell time. Personal data will only be collected if you make it available to us actively and
                                        voluntarily (e.g. by filling out online forms). Usage data relates to the data necessary to
                                        use our website, such as information concerning the start, end and scope of the use of
                                        our website and login data.
                                    </p>
                                    <p>
                                        <strong>Registration or Login Using Login Screens, Online events</strong>
                                        On our website we provide various input masks for online event registration. The type
                                        and scope of data to be collected is clearly defined on the logon screens. Required data is
                                        marked as such. All data given to us voluntarily and data input can be terminated at any
                                        time without storing the data entered up until such time.
                                    </p>
                                    <p>
                                        <strong>Use of the collected data</strong>
                                        For reasons of technical security, in particular to provide a safe it infrastructure for our
                                        online services and to enable you to join our services, online events we store data. We
                                        collect your data as follows: username, email address, first name, name, title, salutation,
                                        country, telephone, company name, notes, language, event details (time, status, other
                                        attendees), related emails, logs on our servers (time, action, IP address, system software,
                                        browser). We collect your information only when you voluntarily provide that information
                                        to us in order to use our services (for example: 1on1-meeting organization)
                                    </p>
                                    <p>
                                        In your user account which is only reachable with personal login data first name, name
                                        and company name of the attending people will be shown to all participants of the
                                        specific online-meeting.
                                    </p>
                                    <p>
                                        <strong>Disclosure of Personal Information</strong>
                                        The data you transmit to us is automatically classified as confidential and stored in
                                        specially secured systems. As a matter of principle, your data will only be disclosed with
                                        your permission. Any sale of your data will be excluded. Your data will not be
                                        automatically disclosed to the authorities or any other government agencies, but only in
                                        individual cases and based on relevant law.
                                    </p>
                                    <p>
                                        <strong>Cooperation with third parties</strong>
                                        To provide you with video conferencing services in connection with our online
                                        events we cooperate with the technical service provider Zoom, San Jose, CA, USA.
                                        Please find the privacy policy of Zoom here:
                                        <a href="https://explore.zoom.us/en/privacy/" target="_blank"
                                           rel="noopener noreferrer" className="d-inline-block ml-1">Zoom Privacy
                                            Policy</a>
                                    </p>
                                    <p>
                                        When you actively confirm („Join Event“) your attendance to an online conference
                                        organized by Equity Forum via Zoom, your first name and name will be transferred
                                        to Zoom.
                                    </p>
                                    <p>
                                        <strong>Cookies</strong>
                                        Cookies are small files stored on your computer with the aid of your internet browser. If
                                        you prefer to block the use of cookies, you can use your browser settings to prevent them from being stored on your computer. Equity Forum UG websites are generally
                                        programmed so as not to install any cookies on your systems.
                                    </p>
                                    <p>
                                        <strong>Further Processing of User Data</strong>
                                        The advertisements posted by Equity Forum UG on the websites of Equity Forum UG (e.g.
                                        equityforum.de) are generally programmed so as not to install any cookies on your
                                        systems. Equity Forum is not responsible for this cookies may installed on your computer.
                                        You can disable your browser’s cookie functionality at any time via your browser’s
                                        “settings”. To do so, please follow your browser’s instructions.
                                    </p>
                                    <p>
                                        <strong>Links | advertisements</strong>
                                        When you follow links to foreign websites or advertisements posted by other companies
                                        or service providers you use those sites on your own risk and responsibility. Equity Forum
                                        is not liable for any content provided from other websites.
                                    </p>
                                    <p>
                                        <strong>Use of Website Tracking or Analytics Services (e.g., Google Analytics)</strong>
                                        The websites of Equity Forum UG do not use tracking or Internet analytics services.
                                    </p>
                                    <p>
                                        <strong>Security of Personal Information</strong>
                                        Equity Forum UG employs appropriate technical and organizational measures for secure
                                        storage of personal data with which you entrust to us. These security measures are
                                        reviewed on a regular basis and adapted to comply with the latest security standards.
                                    </p>
                                    <p>
                                        <strong>Information about Stored Data | Right of users</strong>
                                        Users are entitled to be provided with information at any time, confirming whether or not
                                        their personal data is being stored, and they may contact the controller in order to find
                                        out about the content and source of the stored data, to review its accuracy, to demand
                                        that it be supplemented, erased, updated, corrected or converted into an anonymised
                                        format or to have a block imposed on unlawfully stored data, and to object to processing
                                        on legitimate grounds.
                                    </p>
                                    <p>
                                        You are also entitled at any time, to exercise your right to object without stating any
                                        reasons, and to modify or wholly withdraw your declaration of consent, taking effect for
                                        the future. You can communicate your withdrawal of consent to the contractual partner
                                        by regular mail, by email or by fax. In doing so, you will incur no costs apart from the
                                        postage or the cost of sending the email etc. according to the existing base tariff.
                                        However, please note that you will no longer be able to use the software in this case.
                                    </p>
                                    <p className="furtherInfo">
                                        If you have any concerns in this respect, please send an email to the<br/>
                                        <a href="mailto:dataprotection@equityforum.de">dataprotection@equityforum.de </a>
                                        or send a letter by regular mail using the address
                                        provided below.
                                    </p>
                                    <p className="visitorsAddress">
                                        Visitors address:<br/>
                                        EF Equity Forum UG (limited liable company)<br/>
                                        Berger Straße 145<br/>
                                        60385 Frankfurt<br/>
                                        Germany<br/>
                                        <a href="https://www.equityforum.de">www.equityforum.de</a><br/>
                                        <a href="tel:+496975006610">T +49 (0) 69 75 00 66 - 10</a>
                                        <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex justify-content-center col-2 logo-privacy">
                            <img src={PrivacyLogoGrey} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Privacy;
