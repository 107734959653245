import {useParams} from "react-router";
import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import moment from "moment-timezone";
import {MeetingsIconVcard} from "../../../../../elements/helpers/GfxPaths";

const Details = () => {
  const {id} = useParams();
  const projectStore = useSelector(state => state.project);
  const authorizationStore = useSelector(state => state.authorization);

  const [meeting, setMeeting] = useState(null);
  const [person, setPerson] = useState(null);
  const [isPresentation, setIsPresentation] = useState(false);

  useEffect(() => {
    if (id) {
      const projectPerson = projectStore?.projectPersons?.find((pp) => pp.personType === authorizationStore?.selectedPersonType && pp.personId === authorizationStore?.selectedPersonId);
      setPerson(projectPerson);
      const curMeeting = projectPerson?.meetings?.find((m) => m.meetingId?.toString() === id);
      setIsPresentation(curMeeting?.specialMeetingType && curMeeting?.specialMeetingType?.type === 'PRESENTATION')
      const projectDay = projectStore?.projectDays?.find((el) => el.id === authorizationStore?.selectedDay);
      if (curMeeting && projectDay) {
        setMeeting({
          ...curMeeting,
          startAt: moment(curMeeting.startAt),
          realStartAt: moment(moment(projectDay.date).tz('Europe/Berlin').format('DD.MM.YYYY') + ' ' + moment(curMeeting.startAt).format('HH:mm'), 'DD.MM.YYYY HH:mm'),
          realEndAt: moment(moment(projectDay.date).tz('Europe/Berlin').format('DD.MM.YYYY') + ' ' + moment(curMeeting.endAt).format('HH:mm'), 'DD.MM.YYYY HH:mm')
        })
      }
    }
  }, [projectStore?.projectDays, projectStore?.projectPersons, projectStore?.meetings, authorizationStore?.selectedPersonType, authorizationStore?.selectedPersonId, authorizationStore?.selectedDay]);

  return meeting && person ? (
    <div className="Details">
      <div className="details">
        <div className="d-inline-block mr-3 mr-md-4 mr-lg-5">
                                        <span className="label">
                                            Time:
                                        </span>
          <span className="value">
                                            {moment(meeting.realStartAt).format('HH:mm')} - {moment(meeting.realEndAt).format('HH:mm')}
                                        </span>
        </div>
        <div className="d-inline-block">
                                        <span className="label">
                                            Type:
                                        </span>
          <span className="value">
                                            {isPresentation ? 'Presentation' : (meeting.type === 1 ? '1on1 meeting' : 'Group meeting')}
                                        </span>
        </div>
      </div>
      {authorizationStore?.selectedPersonType === 'company' ? (
        <>
          <div className="details details-italic">
                                            <span className="label mr-2">
                                                Representatives:
                                            </span>
            <span className="value d-inline-block ml-0">
                                                {meeting.companyRepresentatives.map((companyRepresentative, cRI) => (
                                                  <Fragment key={cRI}>
                                                    {cRI !== 0 ? (
                                                      <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                    ) : null}

                                                    <span>{companyRepresentative.detailName}</span>
                                                    {companyRepresentative.vCard ? (
                                                      <button
                                                        onClick={() => this.downloadVCard(companyRepresentative.vCard)}
                                                        className="btn-vcard">
                                                        <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                      </button>
                                                    ) : null}
                                                  </Fragment>
                                                ))}
                                            </span>
          </div>
          <div className="details details-italic">
                                            <span className="label mr-2">
                                                Other participants:
                                            </span>
            <span className="value d-inline-block ml-0">
                                                {meeting.investors.map((investor, iI) => (
                                                  <Fragment key={iI}>
                                                    {iI !== 0 ? (
                                                      <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                    ) : null}
                                                    <span>{investor.detailName}</span>
                                                    {investor.vCard ? (
                                                      <button onClick={() => this.downloadVCard(investor.vCard)}
                                                              className="btn-vcard">
                                                        <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                      </button>
                                                    ) : null}
                                                  </Fragment>
                                                ))}
                                            </span>
          </div>
          {meeting.room && (
            <div className="details details-italic">
                                            <span className="label">
                                                Room:
                                            </span>
              <span className="value">
                                                {meeting.room}
                                            </span>
            </div>
          )}
        </>
      ) : null}
      {authorizationStore?.selectedPersonType === 'investor' ? (
        <>
          <div className="details">
                                            <span className="label">
                                                Company:
                                            </span>
            <span className="value">
                                                {meeting.companyName}
                                            </span>
          </div>
          <div className="details details-italic">
                                            <span className="label">
                                                Represented by:
                                            </span>
            <span className="value">
                                                {meeting.companyRepresentatives.map((companyRepresentative, cRI) => (
                                                  <Fragment key={cRI}>
                                                    {cRI !== 0 ? (
                                                      <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                    ) : null}
                                                    <span>{companyRepresentative.detailName}</span>
                                                    {companyRepresentative.position ? (
                                                      <Fragment>
                                                        &nbsp;
                                                        <span
                                                          className="position">({companyRepresentative.position})</span>
                                                      </Fragment>
                                                    ) : null}
                                                    {companyRepresentative.vCard ? (
                                                      <button
                                                        onClick={() => this.downloadVCard(companyRepresentative.vCard)}
                                                        className="btn-vcard">
                                                        <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                      </button>
                                                    ) : null}
                                                  </Fragment>
                                                ))}
                                            </span>
          </div>
          {meeting.investors.filter((el) => el.fullName !== person.personName).length > 0 ? (
            <div className="details details-italic">
                                            <span className="label">
                                                Other participants:
                                            </span>
              <span className="value">
                                                {meeting.investors.filter((el) => el.fullName !== person.personName).map((investor, iI) => (
                                                  <Fragment key={iI}>
                                                    {iI !== 0 ? (
                                                      <span className="bull">&nbsp;&bull;&nbsp;</span>
                                                    ) : null}
                                                    <span>{investor.detailName}</span>
                                                    {investor.vCard ? (
                                                      <button onClick={() => this.downloadVCard(investor.vCard)}
                                                              className="btn-vcard">
                                                        <img src={MeetingsIconVcard} alt="Download vCard"/>
                                                      </button>
                                                    ) : null}
                                                  </Fragment>
                                                ))}
                                            </span>
            </div>
          ) : null}
          {meeting.room && (
            <div className="details details-italic">
                                            <span className="label">
                                                Room:
                                            </span>
              <span className="value">
                                                {meeting.room}
                                            </span>
            </div>
          )}
        </>
      ) : null}
    </div>
  ) : null
}

export default Details;