import ScrollToTop from "../../../elements/helpers/ScrollToTop";
import React, {useContext, useEffect, useState} from "react";
import {ZoomContext} from "../context/ZoomContext";
import {Prompt, useParams} from "react-router";
import ZoomVideo from "@zoom/videosdk";
import {useSelector} from "react-redux";
import Menu from "./Menu";
import SelfVideo from "./SelfVideo";
import {MeetingParticipantsContext} from "../context/MeetingParticipantsContext";
import LeaveMeeting from "./LeaveMeeting";
import GalleryView from "./GalleryView";
import {toast} from "react-toastify";
import ZoomHelper from "../../../config/zoomHelper";
import IconPeople from "../../../themes/equityforum/gfx/meeting/menu/IconPeople";
import IconStopSharing from "../../../themes/equityforum/gfx/meeting/menu/IconStopSharing";
import classNames from "classnames";
import { useDebouncedCallback } from 'use-debounce';
import MeetingApi from "../../../api/MeetingApi";

const Main = ({meetingJWT, meetingName}) => {

  const {id} = useParams();
  const zoomClient = useContext(ZoomContext);
  const projectStore = useSelector(state => state.project);
  const authorizationStore = useSelector(state => state.authorization);
  const meetingStore = useSelector(state => state.meeting);

  const [mediaStream, setMediaStream] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [isGalleryView, setIsGalleryView] = useState(false);
  const [currentUserIsScreenSharing, setCurrentUserIsScreenSharing] = useState(false);
  const [otherUserIsScreenSharing, setOtherUserIsScreenSharing] = useState(false);

  const [sessionStarted, setSessionStarted] = useState(false);
  const [hasActiveSpeaker, setHasActiveSpeaker] = useState(false);
  const [phoneAsSource, setPhoneAsSource] = useState(false);

  const [isBlocking, setIsBlocking] = useState(false);


  useEffect(() => {
    if (sessionStarted && zoomClient.getMediaStream()) {
      ZoomHelper.setCurrentUserCapturing(zoomClient, meetingStore.virtualBackground, meetingStore.cameraId, meetingStore.microphoneId, meetingStore.speakerId, meetingStore.videoMuted, meetingStore.audioMuted);
    }
  }, [sessionStarted, meetingStore.virtualBackground, meetingStore.cameraId, meetingStore.microphoneId, meetingStore.speakerId, meetingStore.videoMuted, meetingStore.audioMuted, phoneAsSource]);

  useEffect(() => {
    if (sessionStarted) {
      ZoomHelper.setOwnScreenShare(zoomClient, currentUserIsScreenSharing, () => setCurrentUserIsScreenSharing(false))
      if (currentUserIsScreenSharing) {
        ZoomHelper.setOtherScreenShare(zoomClient, null, false);
      }
    }
  }, [sessionStarted, currentUserIsScreenSharing]);

  const handleActiveSpeaker = useDebouncedCallback((users) => {
    setHasActiveSpeaker(true);
    const newActiveSpeakerUserId = users?.length > 0 ? users[0].userId : null
    ZoomHelper.renderActiveSpeaker(zoomClient, newActiveSpeakerUserId);
  }, 500);

  const checkNeededActiveSpeakerSwitch = (participant) => {
    const currentActiveSpeaker = document.querySelector('.activeSpeakerBig').getAttribute('user-id');
    if (currentActiveSpeaker === participant.userId?.toString()) {
      ZoomHelper.renderActiveSpeaker(zoomClient, zoomClient.getCurrentUserInfo().userId);
    }
  }

  useEffect(() => {

    let adminUserIds = [];

    const handleUserAdded = (payload) => {
      let admins = payload.filter(p => p.displayName === 'ADMIN');
      if (admins?.length > 0) {
        adminUserIds = [...adminUserIds, ...admins?.map((a) => a.userId)];
      }
      payload = payload.filter(p => p.displayName !== 'ADMIN');
      setParticipants(prevParticipants => ([
        ...prevParticipants,
        ...payload?.filter(pl => !prevParticipants.map(p => p.userId)?.includes(pl.userId))
      ]));
      payload?.forEach(newParticipant => {
        ZoomHelper.setUserVideo(zoomClient, newParticipant.userId, 2, newParticipant.bVideoOn, newParticipant.muted === undefined ? true : !newParticipant.muted);
        const userDisplayName = ZoomHelper.extractUserInformation(newParticipant.displayName)?.displayName;
        toast((
          <>
            <div className="positive">
              <IconPeople/>
              {`${userDisplayName} has joined the meeting`}
            </div>
          </>
        ))
      })
    }

    const handleUserUpdated = (payload) => {
      payload = payload.filter(p => !adminUserIds?.includes(p.userId));
      payload?.forEach(participant => {
        if (participant.audio !== undefined && participant.userId === zoomClient.getCurrentUserInfo().userId) {
          if (participant.audio === 'phone') {
            setPhoneAsSource(true);
          } else {
            setPhoneAsSource(false);
          }
        }
        if (!hasActiveSpeaker) {
          ZoomHelper.renderActiveSpeaker(zoomClient, participant.userId);
        }
        let user = zoomClient.getAllUser()?.find(p => p.userId === participant.userId);
        if (participant.bVideoOn !== undefined) {
          ZoomHelper.setUserVideo(zoomClient, participant.userId, 2, participant.bVideoOn, !user?.muted);
        } else if (participant.muted !== undefined) {
          ZoomHelper.setUserVideo(zoomClient, participant.userId, 2, user.bVideoOn, !participant.muted);
        }
      })
      setParticipants(prevParticipants => prevParticipants.map(p => {
        const newP = payload?.find(pl => pl.userId === p.userId);
        return newP ? {
          ...p,
          ...newP,
        } : p;
      }))
    }

    const handleUserRemoved = (payload) => {
      payload = payload.filter(p => p.displayName !== 'ADMIN');
      setParticipants(prevParticipants => prevParticipants?.filter(p => !payload.map(pl => pl.userId)?.includes(p.userId)));
      payload?.forEach(participant => {
        checkNeededActiveSpeakerSwitch(participant);
        ZoomHelper.removeUserVideo(participant.userId);
        const userDisplayName = ZoomHelper.extractUserInformation(participant.displayName)?.displayName;
        toast((
          <>
            <div className="negative">
              <IconPeople/> {/* <Play/> icon before the text */}
              {`${userDisplayName} has left the meeting`}
            </div>
          </>
        ))
      })
    }

    const handlePassivelyStopShare = () => {
      setCurrentUserIsScreenSharing(false);
    }

    const handleActiveShareChange = (payload) => {
      if (payload.state === 'Active') {
        setCurrentUserIsScreenSharing(false);
        ZoomHelper.setOtherScreenShare(zoomClient, payload.userId, true);
        setOtherUserIsScreenSharing(true);
      } else if (payload.state === 'Inactive') {
        ZoomHelper.setOtherScreenShare(zoomClient, payload.userId, false);
        setOtherUserIsScreenSharing(false);
      }
    }

    const init = async () => {
      await zoomClient.init('en-US', 'Global', {
        enforceMultipleVideos: true
      });
      try {
        const projectPerson = projectStore?.projectPersons?.find((pp) => pp.personType === authorizationStore?.selectedPersonType && pp.personId === authorizationStore?.selectedPersonId);
        const displayName = `${projectPerson.personName} [${projectPerson.companyName}]`;
        await zoomClient.join(meetingName, meetingJWT, displayName);
        const mediaStream = zoomClient.getMediaStream();
        setMediaStream(mediaStream);
        setSessionStarted(true);
        MeetingApi.setZoomSessionId(id, zoomClient.getSessionInfo()?.sessionId).then(() => {});

        let allUsers = zoomClient.getAllUser();

        let admins = allUsers.filter(p => p.displayName === 'ADMIN');
        if (admins?.length > 0) {
          adminUserIds = [...adminUserIds, ...admins?.map((a) => a.userId)];
        }

        let startParticipants = allUsers?.filter(p => p.displayName !== 'ADMIN');
        setParticipants(startParticipants?.map((participant) => {
          if (participant.userId === zoomClient.getCurrentUserInfo()?.userId) {
            return {
              ...participant,
              muted: meetingStore.audioMuted
            }
          } else {
            return participant;
          }
        }));
        startParticipants.forEach((participant) => {
          if (participant.userId !== zoomClient.getCurrentUserInfo().userId) {
            ZoomHelper.setUserVideo(zoomClient, participant.userId, 2, participant.bVideoOn, participant.muted === undefined ? true : !participant.muted);
          }
        })
        let currentSharer = startParticipants?.find(p => p.sharerOn);
        if (currentSharer) {
          ZoomHelper.setOtherScreenShare(zoomClient, currentSharer.userId, true);
          setOtherUserIsScreenSharing(true);
        }
        zoomClient.on('user-added', handleUserAdded);
        zoomClient.on('user-updated', handleUserUpdated);
        zoomClient.on('user-removed', handleUserRemoved);
        zoomClient.on('passively-stop-share', handlePassivelyStopShare);
        zoomClient.on('active-share-change', handleActiveShareChange);

        zoomClient.on('active-speaker', handleActiveSpeaker);

        setIsBlocking(true);

      } catch (e) {
        alert('Unfortunately, an error occurred when joining the meeting.');
        console.error(e);
      }
    }

    init();

    return () => {
      try {
        zoomClient.off('user-added', handleUserAdded);
        zoomClient.off('user-updated', handleUserUpdated);
        zoomClient.off('user-removed', handleUserRemoved);
        zoomClient.off('passively-stop-share', handlePassivelyStopShare);
        zoomClient.off('active-share-change', handleActiveShareChange);
        zoomClient.off('active-speaker', handleActiveSpeaker);
        zoomClient.leave();
        ZoomVideo.destroyClient()
        setIsBlocking(false);
      } catch (e) {
        console.error('error while leaving meeting', e);
      }
    }
  }, [meetingJWT, id, zoomClient]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';  // Standard for most browsers
      return 'Are you sure you want to leave the meeting?';
    };

    if (isBlocking) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);

  return (
    <div className={classNames("Main", {
      'gallery-view': isGalleryView,
      'own-screen-share': currentUserIsScreenSharing,
      'screen-share': otherUserIsScreenSharing
    })}>
      <Prompt
        when={isBlocking}
        message="Are you sure you want to leave the meeting?"
      />
      <MeetingParticipantsContext.Provider value={participants}>
        <ScrollToTop/>
        {mediaStream ? (
          <>
            <SelfVideo phoneAsSource={phoneAsSource}/>
            <GalleryView visible={!currentUserIsScreenSharing && !otherUserIsScreenSharing && isGalleryView}/>
            <video-player-container class="split-container">
              <div id="split1"/>
              <div id="split2"/>
            </video-player-container>
            
            <video-player-container id="zoomVideoContainer">
            </video-player-container>
            {currentUserIsScreenSharing ? (
              <div className="isSharingInfo">
                <div>
                  You are sharing your screen<br/>
                  <button onClick={() => setCurrentUserIsScreenSharing(false)}>
                    <IconStopSharing/>Stop sharing
                  </button>
                </div>
              </div>
            ) : null}
            <div id="zoomOwnShareContainer"/>
          </>
        ) : null}
        <LeaveMeeting setIsBlocking={setIsBlocking}/>
        <Menu isGalleryView={isGalleryView} setIsGalleryView={setIsGalleryView}
              currentUserIsScreenSharing={currentUserIsScreenSharing}
              setCurrentUserIsScreenSharing={setCurrentUserIsScreenSharing} phoneAsSource={phoneAsSource}/>
      </MeetingParticipantsContext.Provider>
    </div>
  )
}

export default Main;