const IconChat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.061" height="29.062" viewBox="0 0 29.061 29.062">
    <g id="Gruppe_3087" data-name="Gruppe 3087" transform="translate(0 0)">
      <path id="Pfad_1279" data-name="Pfad 1279"
            d="M67.256.446a14.531,14.531,0,1,0,14.53,14.531A14.547,14.547,0,0,0,67.256.446m0,26.731a12.2,12.2,0,1,1,12.2-12.2,12.214,12.214,0,0,1-12.2,12.2"
            transform="translate(-52.725 -0.446)" fill="currentColor"/>
      <path id="Pfad_1280" data-name="Pfad 1280"
            d="M73.417,9.541h-10.9a1.543,1.543,0,0,0-1.544,1.544V23.622l3.562-4.817h8.883a1.543,1.543,0,0,0,1.544-1.544V11.085a1.543,1.543,0,0,0-1.544-1.544"
            transform="translate(-53.435 -1.229)" fill="currentColor"/>
    </g>
  </svg>
);

export default IconChat;