import React, {useEffect, useRef, useState} from "react";
import ZoomVideo from "@zoom/videosdk";
import {useDispatch, useSelector} from "react-redux";
import {
  meetingSetAudioMuted,
  meetingSetCameraId,
  meetingSetMicrophoneId,
  meetingSetSpeakerId,
  meetingSetVideoMuted
} from "../../../store/meeting/meetingAction";
import DeviceSelect from "../DeviceSelect";
import Speaker from "../../../themes/equityforum/gfx/meeting/Speaker";
import Camera from "../../../themes/equityforum/gfx/meeting/Camera";
import classNames from "classnames";
import VideoMuted from "../../../themes/equityforum/gfx/meeting/VideoMuted";
import VideoUnmuted from "../../../themes/equityforum/gfx/meeting/VideoUnmuted";
import AudioMuted from "../../../themes/equityforum/gfx/meeting/AudioMuted";
import AudioUnmuted from "../../../themes/equityforum/gfx/meeting/AudioUnmuted";
import TeleDial from "../../../themes/equityforum/gfx/meeting/TeleDial";
import Play from "../../../themes/equityforum/gfx/meeting/Play";
import NoVideo from "../../../themes/equityforum/gfx/meeting/NoVideo";
import gfx_speakerPreview from "../../../themes/equityforum/gfx/meeting/speakerPreview.png";
import ScrollToTop from "../../../elements/helpers/ScrollToTop";
import Details from "./Details";
import MicrophonePreview from "./MicrophonePreview";

const Join = ({handleStartMeeting}) => {

  const meetingStore = useSelector(state => state.meeting);
  const dispatch = useDispatch();

  const [videoDevices, setVideoDevices] = useState(null);
  const [microphoneDevices, setMicrophoneDevices] = useState(null);
  const [speakerDevices, setSpeakerDevices] = useState(null);

  const localVideoTrackRef = useRef(null);
  const localAudioTrackRef = useRef(null);
  const microphoneTesterRef = useRef(null);
  const speakerTesterRef = useRef(null);

  const [videoStarted, setVideoStarted] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoTrackAvailable, setVideoTrackAvailable] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [audioTrackAvailable, setAudioTrackAvailable] = useState(false);
  const [microphoneLevel, setMicrophoneLevel] = useState(0);
  const [speakerTestIsPlaying, setSpeakerTestIsPlaying] = useState(false);


  useEffect(() => {
    const getDevicesForZoom = () => {
      ZoomVideo.getDevices().then((devices) => {
        setVideoDevices(devices.filter((device) => device.kind === 'videoinput'));
        setMicrophoneDevices(devices.filter((device) => device.kind === 'audioinput'));
        setSpeakerDevices(devices.filter((device) => device.kind === 'audiooutput'));
      })
    }

    getDevicesForZoom();
    navigator.mediaDevices.ondevicechange = () => {
      getDevicesForZoom();
    }

    return () => {
      if (localVideoTrackRef.current && localVideoTrackRef.current.isVideoStarted) {
        localVideoTrackRef.current.stop();
      }
      if (localAudioTrackRef.current && localAudioTrackRef.current.isAudioStarted) {
        localAudioTrackRef.current.stop();
      }
    }
  }, []);

  useEffect(() => {
    if (!meetingStore?.cameraId && videoDevices?.length > 0) {
      dispatch(meetingSetCameraId(videoDevices[0].deviceId));
    }
  }, [meetingStore?.cameraId, videoDevices]);

  useEffect(() => {
    if (!meetingStore?.microphoneId && microphoneDevices?.length > 0) {
      dispatch(meetingSetMicrophoneId(microphoneDevices[0].deviceId));
    }
  }, [meetingStore?.microphoneId, microphoneDevices]);

  useEffect(() => {
    if (!meetingStore?.speakerId && speakerDevices?.length > 0) {
      dispatch(meetingSetSpeakerId(speakerDevices[0].deviceId));
    }
  }, [meetingStore?.speakerId, speakerDevices]);

  useEffect(() => {
    if (meetingStore?.cameraId) {
      localVideoTrackRef.current = ZoomVideo.createLocalVideoTrack(meetingStore.cameraId)
      setVideoTrackAvailable(true);
    }
  }, [meetingStore?.cameraId]);

  useEffect(() => {
    if (localVideoTrackRef.current && videoTrackAvailable) {
      if (!videoStarted && !meetingStore?.videoMuted) {
        setVideoStarted(true);
        localVideoTrackRef.current.start(document.getElementById('video-preview')).then(() => {
          setVideoIsPlaying(true);
        })
      }
    }
  }, [videoTrackAvailable, videoStarted, localVideoTrackRef.current, meetingStore?.videoMuted]);

  useEffect(() => {
    if (videoStarted && videoIsPlaying && meetingStore?.videoMuted && videoTrackAvailable) {
      setVideoStarted(false);
      localVideoTrackRef.current.stop().then(() => {
        setVideoIsPlaying(false);
      });
    }
  }, [videoTrackAvailable, videoStarted, videoIsPlaying, localVideoTrackRef.current, meetingStore?.videoMuted]);

  useEffect(() => {
    if (meetingStore?.microphoneId) {
      localAudioTrackRef.current = ZoomVideo.createLocalAudioTrack(meetingStore.microphoneId);
      setAudioTrackAvailable(true)
    }
  }, [meetingStore?.microphoneId]);

  useEffect(() => {
    if (localAudioTrackRef.current && audioTrackAvailable) {
      if (!audioStarted && !audioIsPlaying && !meetingStore?.audioMuted) {
        setAudioStarted(true);
        localAudioTrackRef.current.start().then(() => {
          setAudioIsPlaying(true);
          microphoneTesterRef.current = localAudioTrackRef.current.testMicrophone({
            microphoneId: meetingStore.microphoneId,
            onAnalyseFrequency: (v) => {
              setMicrophoneLevel(v);
            },
          });
        })
      }
    }
  }, [audioTrackAvailable, audioStarted, audioIsPlaying, localAudioTrackRef.current, meetingStore?.audioMuted]);

  useEffect(() => {
    if (audioTrackAvailable && audioIsPlaying && audioStarted && meetingStore?.audioMuted) {
      setAudioStarted(false);
      if (microphoneTesterRef.current) {
        microphoneTesterRef.current.stop();
      }
      localAudioTrackRef.current.stop().then(() => {
        setAudioIsPlaying(false);
      });
    }
  }, [audioTrackAvailable, audioIsPlaying, audioStarted, localAudioTrackRef.current, meetingStore?.audioMuted]);

  const handleChangeCamera = (cameraId) => {
    if (cameraId !== meetingStore.cameraId) {
      if (videoStarted && videoIsPlaying && videoTrackAvailable) {
        setVideoStarted(false);
        setVideoTrackAvailable(false);
        localVideoTrackRef.current.stop().then(() => {
          setVideoIsPlaying(false);
          dispatch(meetingSetCameraId(cameraId));
        })
      } else {
        dispatch(meetingSetCameraId(cameraId));
      }
    }
  }

  const handleChangeMicrophone = (microphoneId) => {
    if (microphoneId !== meetingStore.microphoneId) {
      if (audioStarted && audioIsPlaying && audioTrackAvailable) {
        setAudioStarted(false);
        setAudioTrackAvailable(false);
        if (microphoneTesterRef.current) {
          microphoneTesterRef.current.stop();
        }
        localAudioTrackRef.current.stop().then(() => {
          setAudioIsPlaying(false);
          dispatch(meetingSetMicrophoneId(microphoneId));
        })
      } else {
        dispatch(meetingSetMicrophoneId(microphoneId));
      }
    }
  }

  const handleChangeSpeaker = (speakerId) => {
    dispatch(meetingSetSpeakerId(speakerId));
  }

  const handleTestSpeaker = () => {
    if (!speakerTesterRef.current) {
      setSpeakerTestIsPlaying(true);
      if (microphoneTesterRef.current) {
        microphoneTesterRef.current.stop();
      }
      speakerTesterRef.current = localAudioTrackRef.current.testSpeaker({
        speakerId: meetingStore?.speakerId,
        onAnalyseFrequency: (v) => {
          // do we want to show the speaker output?
        },
      });
      setTimeout(() => {
        speakerTesterRef.current.destroy();
        speakerTesterRef.current = null;
        microphoneTesterRef.current = localAudioTrackRef.current.testMicrophone({
          microphoneId: meetingStore.microphoneId,
          onAnalyseFrequency: (v) => {
            setMicrophoneLevel(v);
          },
        });
        setSpeakerTestIsPlaying(false);
      }, 3500);
    }
  }

  const videoDeviceSelectOptions = videoDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));
  const microphoneDeviceSelectOptions = microphoneDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));
  const speakerDeviceSelectOptions = speakerDevices?.map(vd => ({value: vd.deviceId, label: vd.label}));

  return (
    <div className="Join">
      <ScrollToTop/>
      <div className="preview-outer">
        <div className="preview-inner-top">
          <div className="noVideo-outer">
            <div
              className={classNames("noVideo-container", !meetingStore?.videoMuted && meetingStore?.cameraId ? 'd-none' : '')}>
              <NoVideo/>
            </div>
          </div>
          <video className="video" id="video-preview" playsInline/>
          <div className="select-button-container">
            <div className="select-container">
              <DeviceSelect label="Cam" options={videoDeviceSelectOptions}
                            value={videoDeviceSelectOptions?.find(el => el.value === meetingStore?.cameraId)}
                            onChange={(option) => handleChangeCamera(option.value)}/>
              <DeviceSelect label="Mic" options={microphoneDeviceSelectOptions}
                            value={microphoneDeviceSelectOptions?.find(el => el.value === meetingStore?.microphoneId)}
                            onChange={(option) => handleChangeMicrophone(option.value)}/>
              <DeviceSelect label="Speaker" options={speakerDeviceSelectOptions}
                            value={speakerDeviceSelectOptions?.find(el => el.value === meetingStore?.speakerId)}
                            onChange={(option) => handleChangeSpeaker(option.value)}/>
              <div className="muting-container">
                <div className="indicators">
                  <div
                    className={classNames("indicator indicator-audio", audioIsPlaying ? 'is-active' : '')}>
                    <Speaker/>
                  </div>
                  <div
                    className={classNames("indicator indicator-video", videoIsPlaying ? 'is-active' : '')}>
                    <Camera/>
                  </div>
                </div>
                <div className={classNames("muting muting-video", meetingStore?.videoMuted ? 'is-muted' : '')}>
                  <button onClick={() => dispatch(meetingSetVideoMuted(!meetingStore?.videoMuted))}>
                    <div className="icon-container">
                      <VideoMuted/>
                      <VideoUnmuted/>
                    </div>
                    <div className="text-container">
                      <div className="text-muted">
                        Unmute<br/>
                        Video
                      </div>
                      <div className="text-unmuted">
                        Mute<br/>
                        Video
                      </div>
                    </div>
                  </button>
                </div>
                <div className={classNames("muting muting-audio", meetingStore?.audioMuted ? 'is-muted' : '')}>
                  <button onClick={() => dispatch(meetingSetAudioMuted(!meetingStore?.audioMuted))}>
                    <div className="icon-container">
                      <AudioMuted/>
                      <AudioUnmuted/>
                    </div>
                    <div className="text-container">
                      <div className="text-muted">
                        Unmute<br/>
                        Audio
                      </div>
                      <div className="text-unmuted">
                        Mute<br/>
                        Audio
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="tests">
                <div className="speaker-preview">
                  <button onClick={handleTestSpeaker}>
                    <img src={gfx_speakerPreview} alt=""/>
                    Testsound
                  </button>
                </div>
                <MicrophonePreview amount={speakerTestIsPlaying || meetingStore?.audioMuted ? 0 : microphoneLevel}/>
              </div>
            </div>
            {window.safari !== undefined ? (
              <div className="my-3">
                <h4 className="text-white">
                  There are known bugs when using the Safari browser. Please use a different browser.
                </h4>
              </div>
            ) : null}
            <div className="cta-button-container">
              <button onClick={() => handleStartMeeting('main')}>
                <Play/>
                <div>
                  Start!
                </div>
              </button>
              <button className="dialin" onClick={() => handleStartMeeting('phone')}>
                <TeleDial/>
                <div>
                  Phone dial-in
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Details/>
    </div>
  )
}

export default Join;