import React from 'react';
import {LoginButtonBackToLogin, LoginButtonBackToLoginMo} from "../../elements/helpers/GfxPaths";
import {Link} from "react-router-dom";

class SentToken extends React.Component {
    render() {
        return (
            <div>
                <h2 className="token-success">
                    Thank you!<br/>
                    You will shortly receive an email.
                </h2>
                <Link to="/" className="submit-button">
                    <img alt="" className="img img-default" src={LoginButtonBackToLogin}/>
                    <img alt="" className="img img-mo" src={LoginButtonBackToLoginMo}/>
                </Link>
            </div>
        )
    }
}

export default SentToken;
