export const AUTHORIZATION_LOGIN_SUCCESS = 'AUTHORIZATION_LOGIN_SUCCESS';
export const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT';
export const AUTHORIZATION_SET_SELECTED_PERSON = 'AUTHORIZATION_SET_SELECTED_PERSON';
export const AUTHORIZATION_SET_SELECTED_DAY = 'AUTHORIZATION_SET_SELECTED_DAY';
export const AUTHORIZATION_SET_SELECTED_TIMEZONE = 'AUTHORIZATION_SET_SELECTED_TIMEZONE';
export const AUTHORIZATION_OPEN_SELECT_TIMEZONE = 'AUTHORIZATION_OPEN_SELECT_TIMEZONE';
export const AUTHORIZATION_CLOSE_SELECT_TIMEZONE = 'AUTHORIZATION_CLOSE_SELECT_TIMEZONE';
export const AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN = 'AUTHORIZATION_SET_HAS_SEEN_WELCOME_SCREEN';

export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_HAS_SET_VCARD = 'PROJECT_HAS_SET_VCARD';
export const PROJECT_UNLOAD = 'PROJECT_UNLOAD';

export const MEETING_SET_CAMERA_ID = 'MEETING_SET_CAMERA_ID';
export const MEETING_SET_MICROPHONE_ID = 'MEETING_SET_MICROPHONE_ID';
export const MEETING_SET_SPEAKER_ID = 'MEETING_SET_SPEAKER_ID';
export const MEETING_SET_VIDEO_MUTED = 'MEETING_SET_VIDEO_MUTED';
export const MEETING_SET_AUDIO_MUTED = 'MEETING_SET_AUDIO_MUTED';
export const MEETING_SET_VIRTUAL_BACKGROUND = 'MEETING_SET_VIRTUAL_BACKGROUND';
