import Select, {components} from "react-select";

const DropdownIndicator = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="15.191" height="9.318" viewBox="0 0 15.191 9.318">
        <defs>
          <clipPath id="clip-path">
            <rect width="15.191" height="9.318" fill="none"/>
          </clipPath>
        </defs>
        <g clipPath="url(#clip-path)">
          <g transform="translate(-235.704 -17.951)">
            <path id="Pfad_1028" d="M9.318,7.6,0,0V15.191Z" transform="translate(250.896 17.951) rotate(90)" fill="#ff7f27"/>
          </g>
        </g>
      </svg>
    </components.DropdownIndicator>
  );
};

const DeviceSelect = ({label, options, value, onChange}) => {
  return (
    <div className="DeviceSelect">
      <label>{label}</label>
      <Select onChange={onChange} value={value} options={options} unstyled={true} isSearchable={false}
              className="react-select-unstyled-container"
              classNamePrefix="react-select-deviceSelect"
              components={{
                DropdownIndicator
              }}
    />
</div>
  )
}

export default DeviceSelect;