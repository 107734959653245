import React from 'react';
import TextInput from "../../elements/form/TextInput";
import SubmitButton from "../../elements/form/SubmitButton";
import history from "../../history";
import {LoginButtonSaveXS, LoginButtonSave, LoginButtonSaveMo} from "../../elements/helpers/GfxPaths";
import AuthorizationApi from "../../api/AuthorizationApi";
import GeneralError from "../../elements/form/GeneralError";
import {connect} from "react-redux";
import {authorizationLoginSuccess} from "../../store/authorization/authorizationAction";
import SimpleCheckbox from "../../elements/form/SimpleCheckbox";

class ForgotPassword extends React.Component {

    state = {
        token: '',
        password: '',
        confirmPassword: '',
        acceptedPrivacy: false,
        passwordError: false,
        passwordEmpty: false,
        tokenInvalid: false,
        mustAcceptPrivacy: false,
        privacyNotAccepted: false
    };

    componentDidMount() {
        this.setState({token: this.props.match.params.token});
        AuthorizationApi.checkResetPasswordToken(this.props.match.params.token)
            .then(response => {
                if (response.data.success) {
                    if (response.data.mustAcceptPrivacy) {
                        this.setState({mustAcceptPrivacy: true});
                    }
                } else {
                    this.setState({tokenInvalid: true});
                }
            });
    }

    submitForm = (e) => {
        e.preventDefault();
        this.setState({
            passwordError: false,
            passwordEmpty: false,
            privacyNotAccepted: false
        });
        if (this.state.password && this.state.confirmPassword) {
            if (this.state.password === this.state.confirmPassword) {
                if (this.state.mustAcceptPrivacy && !this.state.acceptedPrivacy) {
                    this.setState({privacyNotAccepted: true});
                } else {
                    AuthorizationApi.resetPassword(this.state.token, this.state.password, this.state.acceptedPrivacy)
                        .then((response) => {
                            history.push("/");
                        });
                }

            } else {
                this.setState({passwordError: true})
            }
        } else {
            this.setState({passwordEmpty: true})
        }
    };

    render() {
        return (
            <form className="resetPassword-form" onSubmit={this.submitForm}>
                <TextInput label="NEW PASSWORD" type="password" value={this.state.password}
                           disabled={this.state.tokenInvalid}
                           onChange={(e) => this.setState({password: e.target.value})}/>
                <TextInput label="CONFIRM NEW PASSWORD" type="password" value={this.state.confirmPassword}
                           disabled={this.state.tokenInvalid}
                           onChange={(e) => this.setState({confirmPassword: e.target.value})}/>
                {this.state.mustAcceptPrivacy ? (
                    <SimpleCheckbox
                        label="I accept the <a href='/privacy' target='_blank' rel='noopener noreferrer'>data protection regulations</a> of the 1on1 Dashboard."
                        checked={this.state.acceptedPrivacy}
                        onChange={(e) => this.setState({acceptedPrivacy: e.target.checked})}/>
                ) : null}
                {this.state.passwordError ? (
                    <GeneralError
                        errorMessage="The provided passwords do not match."/>
                ) : null}
                {this.state.passwordEmpty ? (
                    <GeneralError
                        errorMessage="The password fields can't be empty and need to match."/>
                ) : null}
                {this.state.privacyNotAccepted ? (
                    <GeneralError
                        errorMessage="You must accept the data protection regulations."/>
                ) : null}
                {this.state.tokenInvalid ? (
                    <GeneralError
                        errorMessage="Your entered link is invalid. Please copy it from your received email again."/>
                ) : null}
                <SubmitButton>
                    <img alt="" className="img img-default d-lg-none" src={LoginButtonSaveXS}/>
                    <img alt="" className="img img-default d-none d-lg-inline" src={LoginButtonSave}/>
                    <img alt="" className="img img-mo" src={LoginButtonSaveMo}/>
                </SubmitButton>
            </form>
        )
    }
}

export default connect(null, {
    authorizationLoginSuccess
})(ForgotPassword);
