const IconDetails = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.06" height="29.062" viewBox="0 0 29.06 29.062">
    <g id="Gruppe_3091" data-name="Gruppe 3091" transform="translate(0 0)">
      <path id="Pfad_1273" data-name="Pfad 1273"
            d="M273.38.446a14.531,14.531,0,1,0,14.529,14.531A14.547,14.547,0,0,0,273.38.446m0,26.731a12.2,12.2,0,1,1,12.2-12.2,12.215,12.215,0,0,1-12.2,12.2"
            transform="translate(-258.849 -0.446)" fill="currentColor"/>
      <path id="Pfad_1274" data-name="Pfad 1274"
            d="M273.648,11.447h1.895a.477.477,0,0,0,.477-.477V9.27a.477.477,0,0,0-.477-.477h-1.895a.477.477,0,0,0-.477.477v1.7a.477.477,0,0,0,.477.477"
            transform="translate(-260.082 -1.165)" fill="currentColor"/>
      <path id="Pfad_1275" data-name="Pfad 1275"
            d="M275.609,14.044h-2a.477.477,0,0,0-.477.477v7.746a.478.478,0,0,0,.477.478h2a.478.478,0,0,0,.478-.478V14.521a.478.478,0,0,0-.478-.477"
            transform="translate(-260.079 -1.617)" fill="currentColor"/>
    </g>
  </svg>
)

export default IconDetails;