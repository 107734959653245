import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store} from './store';
import history from './history';
import {Provider} from "react-redux";
import {LocalizeProvider} from "react-localize-redux";

try {
    ReactDOM.render(
        <Provider store={store}>
            <LocalizeProvider>
                <App history={history}/>
            </LocalizeProvider>
        </Provider>,
        document.getElementById('root')
    );
} catch(e) {
    console.log(e);
    localStorage.clear();
    window.location.reload();
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
