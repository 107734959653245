class OnTouchNoHoverHelper {
  static init = () => {
    function hasTouch() {
      return 'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0;
    }

    if (hasTouch()) { // remove all the :hover stylesheets
      setTimeout(() => {
        try { // prevent exception on browsers not supporting DOM styleSheets properly
          for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
              if (!styleSheet.rules[ri].selectorText) continue;

              if (styleSheet.rules[ri].selectorText.match(/:hover/)) {
                styleSheet.deleteRule(ri);
              } else if (styleSheet.rules[ri].selectorText.match(/:active/)) {
                styleSheet.deleteRule(ri);
              }
            }
          }
        } catch (ex) {
        }
      }, 500);

    }
  }
}

export default OnTouchNoHoverHelper;