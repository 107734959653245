const IconStopSharing = () => (
    <svg id="Ebene_1" data-name="Ebene 1" width="29.06" height="29.06" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 31.8 31.8">
  <g id="Gruppe_3140" data-name="Gruppe 3140">
    <path id="Pfad_1327" data-name="Pfad 1327" class="cls-1" fill="currentColor" d="M15.9,0C7.1,0,0,7.1,0,15.9s7.1,15.9,15.9,15.9,15.9-7.1,15.9-15.9S24.7,0,15.9,0h0M15.9,2.6c2.7,0,5.3.8,7.6,2.3l-4.6,4.6-2.1,2.1-4.3,4.3-.9.9-1.3,1.3-.6.6-4.6,4.6C.7,17.4,2.3,9.1,8.3,4.9c2.2-1.5,4.9-2.3,7.6-2.3M15.9,29.3c-3.9,0-7.5-1.7-10.1-4.6l5.4-5.4.3-.3,1.1-1.1.7-.7,5.4-5.4,1.1-1.1,4.8-4.8c5.6,4.8,6.2,13.2,1.4,18.8-2.5,2.9-6.2,4.6-10.1,4.6"/>
    <path id="Pfad_1328" data-name="Pfad 1328" class="cls-1" fill="currentColor" d="M23,9.6l-2.1,2.1h1.6v8.5h-6v2.1h6.2c1,0,1.9-.8,1.9-1.9v-8.9c0-.9-.7-1.7-1.6-1.9"/>
    <path id="Pfad_1329" data-name="Pfad 1329" class="cls-1" fill="currentColor" d="M9.3,11.7h5.3l2.1-2.1h-7.7c-1,0-1.9.8-1.9,1.9v1.5h2.1v-1.3Z"/>
    <path id="Pfad_1330" data-name="Pfad 1330" class="cls-1" fill="currentColor" d="M7.1,14.1h0Z"/>
    <path id="Pfad_1331" data-name="Pfad 1331" class="cls-1" fill="currentColor" d="M13.3,19.3c.4.9.7,1.9.7,3h1.3c0-1.4-.3-2.7-1-3.9l-1,1Z"/>
    <path id="Pfad_1332" data-name="Pfad 1332" class="cls-1" fill="currentColor" d="M10.1,16.1l1-1c-1.2-.7-2.6-1-3.9-1v1.3c1,0,2.1.2,3,.7"/>
    <path id="Pfad_1333" data-name="Pfad 1333" class="cls-1" fill="currentColor" d="M7.1,18c.3,0,.6,0,.9.1l1-1c-.6-.2-1.3-.4-2-.4v1.3Z"/>
    <path id="Pfad_1334" data-name="Pfad 1334" class="cls-1" fill="currentColor" d="M11.2,21.3c0,.3.1.6.1.9h1.3c0-.7-.1-1.3-.4-2l-1,1Z"/>
  </g>
</svg>
  )
  
  export default IconStopSharing;