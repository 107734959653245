import React from 'react';
import {connect} from "react-redux";
import Sponsor from "./Sponsor";

class Sponsors extends React.Component {
    render() {
        const {project} = this.props;
        return project.loaded && project.projectSponsors && project.projectSponsors.length > 0 ? (
            <div className="Sponsors sponsors-header">
                <div className="row sponsorsHeader-row">
                    <div className="col-3 col-md-6 col-lg-3 offset-lg-3 flex-grow-1">
                        <h4>
                            PARTNERS:
                        </h4>
                    </div>
                </div>
                <div className="desktop w-100">
                    {project.projectSponsors.map((sponsor, sI) => (
                        <Sponsor key={sI} sponsor={sponsor}/>
                    ))}
                </div>
                <div className="tablet">
                    <div className="sponsorsCol">
                        {project.projectSponsors.map((sponsor, sI) => (
                            sI % 3 === 0 ? (
                                <Sponsor key={sI} sponsor={sponsor}/>
                            ) : null
                        ))}
                    </div>
                    <div className="sponsorsCol">
                        {project.projectSponsors.map((sponsor, sI) => (
                            sI % 3 === 1 ? (
                                <Sponsor key={sI} sponsor={sponsor}/>
                            ) : null
                        ))}
                    </div>
                    <div className="sponsorsCol">
                        {project.projectSponsors.map((sponsor, sI) => (
                            sI % 3 === 2 ? (
                                <Sponsor key={sI} sponsor={sponsor}/>
                            ) : null
                        ))}
                    </div>
                </div>
                <div className="mobile d-flex justify-content-between w-100 d-md-none">
                    <div className="sponsorsCol w-50">
                        {project.projectSponsors.map((sponsor, sI) => (
                            sI % 2 === 0 ? (
                                <Sponsor key={sI} sponsor={sponsor}/>
                            ) : null
                        ))}
                    </div>
                    <div className="sponsorsCol w-50">
                        <div className="openInfo w-100">Click on partner-logo for more information.</div>
                        {project.projectSponsors.map((sponsor, sI) => (
                            sI % 2 === 1 ? (
                                <Sponsor key={sI} sponsor={sponsor}/>
                            ) : null
                        ))}
                    </div>
                </div>
            </div>
        ) : null

    }
}

const mapStateToProps = state => ({
    project: state.project
});

export default connect(mapStateToProps, {})(Sponsors);
