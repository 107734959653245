import React, {Fragment} from 'react';
import {Link, NavLink} from "react-router-dom";
import {
    TopBarIconDocuments,
    TopBarIconDocumentsMo,
    TopBarIconLogout,
    TopBarIconLogoutMo,
    TopBarIconMeetings,
    TopBarIconMeetingsMo,
    TopBarIconProfile,
    TopBarIconProfileMo,
    TopBarLogo, TopBarLogoMo,
    TopBarLogoMobile,
    TopBarLogoMobileMo
} from '../helpers/GfxPaths'
import {connect} from "react-redux";
import {
    authorizationLogout,
    authorizationSetSelectedDay,
    authorizationSetSelectedPerson
} from "../../store/authorization/authorizationAction";
import ProjectApi from "../../api/ProjectApi";
import {projectLoadSuccess} from "../../store/project/projectAction";
import {Dropdown} from "react-bootstrap";
import moment from 'moment-timezone';
import {NotificationManager} from "react-notifications";
import EigenkapitalTheme from "../helpers/EigenkapitalTheme";
import history from "../../history";


class TopBar extends React.Component {

    state = {
        lastNotificationCheck: moment()
    };

    notificationInterval = null;
    autoLogoutInterval = null

    componentDidMount() {
        const {authorization} = this.props;
        ProjectApi.getProject()
            .then((response) => {
                if (!authorization.selectedPersonType || !authorization.selectedPersonId || !response.data.data.projectPersons.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId)) {
                    if (response.data.data.projectPersons.length > 0) {
                        let curPerson = response.data.data.projectPersons[0];
                        this.props.authorizationSetSelectedPerson(curPerson.personType, curPerson.personId);
                    }
                }
                if (response.data.data.projectDays.length > 0 && (!authorization.selectedDay || !response.data.data.projectDays.find(el => el.id === authorization.selectedDay))) {
                    let that = this;
                    setTimeout(() => {
                        that.props.authorizationSetSelectedDay(that.findNextDayWithMeetings(response.data.data.projectDays).id);
                    }, 1000);
                }
                this.props.projectLoadSuccess(response.data.data.projectPersons, response.data.data.projectDays, response.data.data.projectSponsors, response.data.data.pushNotificationsEnabled, response.data.data.hasEverSetVCard, response.data.data.projectCompanies, response.data.data.projectInfo);
                this.notificationInterval = setInterval(() => this.checkNotify(), 60000);
                this.autoLogoutInterval = setInterval(() => this.checkAutoLogout(), 1000);
            });
    }

    componentWillUnmount() {
        if (this.notificationInterval) clearInterval(this.notificationInterval);
        if (this.autoLogoutInterval) clearInterval(this.autoLogoutInterval);
    }

    checkNotify() {
        const {authorization, project} = this.props;
        if (project.pushNotificationsEnabled) {
            let curPerson;
            if (project.loaded) {
                curPerson = project.projectPersons.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId);
                let meetings = curPerson.meetings.map((meeting) => ({
                    ...meeting,
                    realStartAt: moment(moment(project.projectDays.find((el) => el.id === meeting.projectDayId).date).format('DD.MM.YYYY') + ' ' + moment(meeting.startAt).format('HH:mm'), 'DD.MM.YYYY HH:mm')
                }));
                if (meetings.find(meeting => {
                    let notificationTime = meeting.realStartAt.subtract(5, 'minute');
                    return notificationTime.isAfter(this.state.lastNotificationCheck) && notificationTime.isBefore(moment().add(1, 'second'))
                })) {
                    if (Notification.permission === 'granted') {
                        let n = new Notification('1on1-Dashboard', {body: 'Your next meeting will begin shortly.'});
                        n.onclick = (e) => {
                            n.close();
                            window.focus();
                        }
                    } else {
                        NotificationManager.info('Your next meeting will begin shortly.', '1on1-Dashboard', 10 * 60000);
                    }

                }
                this.setState({
                    lastNotificationCheck: moment()
                })
            }
        }
    }

    checkAutoLogout = () => {
        if(moment().format('HH:mm') === '00:00' && (!this.props.authorization.lastLoginAt || moment().isAfter(moment(this.props.authorization.lastLoginAt).add(1, 'hour')))) {
            this.props.authorizationLogout();
        }
    };

    findNextDayWithMeetings = (days) => {
        try {
            const {authorization, project} = this.props;
            let curPerson = project.projectPersons.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId);
            let day = days.find(day => {
                if (moment(day.date).tz('Europe/Berlin').isSameOrAfter(moment().tz('Europe/Berlin'), 'day')) {
                    let meetings = curPerson.meetings.filter((meeting) => meeting.projectDayId === day.id);
                    if (meetings && meetings.length > 0) return true;
                }
            })
            if (!day && days.length > 0) day = days[0];
            return day;
        } catch(e) {
            console.log(e);
            return days[0];
        }
    }

    render() {
        const {project, authorization} = this.props;
        let curPerson;
        if (project.loaded) {
            curPerson = project.projectPersons.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId)
        }
        return (
            <div className="top-bar">
                {authorization.bearerToken ? (
                  <Fragment>
                      <NavLink to="/" exact={true} className="navLink navHome">
                          <div className="icon-container">
                              <img alt="Meetings" src={TopBarIconMeetings} className="icon icon-default"/>
                              <img alt="Meetings" src={TopBarIconMeetingsMo} className="icon icon-mo"/>
                          </div>
                          <span className="label">
                        Schedule
                    </span>
                      </NavLink>
                      <NavLink to="/documents" exact={true} className="navLink navDocuments">
                          <div className="icon-container">
                              <img alt="Documents" src={TopBarIconDocuments} className="icon icon-default"/>
                              <img alt="Documents" src={TopBarIconDocuments} className="icon icon-mo"/>
                          </div>
                          <span className="label">
                        Presentations
                    </span>
                      </NavLink>
                      <NavLink to="/profile" className="navLink navProfile">
                          <div className="icon-container">
                              <img alt="Profile" src={TopBarIconProfile} className="icon icon-default"/>
                              <img alt="Profile" src={TopBarIconProfileMo} className="icon icon-mo"/>
                          </div>
                          <span className="label">
                            Profile
                    </span>
                          <div className={`${!project.hasEverSetVCard ? 'd-flex' : 'd-none'} news-dot justify-content-center align-items-center`}/>
                      </NavLink>
                      <button onClick={() => {history.push('/'); this.props.authorizationLogout()}} className="navLink">
                          <div className="icon-container">
                              <img alt="Logout" src={TopBarIconLogout} className="icon icon-default"/>
                              <img alt="Logout" src={TopBarIconLogoutMo} className="icon icon-mo"/>
                          </div>
                          <span className="label">
                            Logout
                        </span>
                      </button>
                  </Fragment>
                ) : null}
                {this.props.brandLinkTo ? (
                  <a href={this.props.brandLinkTo} target="_blank" rel="noopener noreferrer" className="brand">
                      <div className="logo-container logo-container-mobile d-inline-block d-md-none">
                          <img alt="" src={TopBarLogoMobile} className="logo logo-default"/>
                      </div>
                      <div className="logo-container d-none d-md-block">
                          <img alt="" src={TopBarLogo} className="logo logo-default"/>
                          <img alt="" src={TopBarLogoMo} className="logo logo-mo"/>
                      </div>
                  </a>
                ) : (
                  <Link to="/" className="brand">
                      <div className="logo-container logo-container-mobile d-inline-block d-md-none">
                          <img alt="" src={TopBarLogoMobile} className="logo logo-default"/>
                      </div>
                      <div className="logo-container d-none d-md-block">
                          <img alt="" src={TopBarLogo} className="logo logo-default"/>
                          <img alt="" src={TopBarLogoMo} className="logo logo-mo"/>
                      </div>
                  </Link>
                )}
                {authorization.bearerToken && project.loaded && curPerson && project.projectPersons.length > 1 ? (
                    <div className="person-selector-container">
                        <Dropdown>
                            <Dropdown.Toggle variant="default">
                                <div className="dropdown-toggle-content">
                                    <strong>{curPerson.companyName}</strong><br/>
                                    <i>{curPerson.personName}</i>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {project.projectPersons.filter((pp) => !(pp.personType === curPerson.personType && pp.personId === curPerson.personId)).map((projectPerson, ppI) => (
                                    <Dropdown.Item
                                        onClick={() => this.props.authorizationSetSelectedPerson(projectPerson.personType, projectPerson.personId)}
                                        key={ppI}>
                                        <strong>{projectPerson.companyName}</strong><br/>
                                        <i>{projectPerson.personName}</i>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization,
    project: state.project
});

export default connect(mapStateToProps, {
    authorizationLogout, projectLoadSuccess, authorizationSetSelectedPerson, authorizationSetSelectedDay
})(TopBar);
