import React, {Fragment} from 'react';
import TextInput from "../../elements/form/TextInput";
import {VcardButtonSave, VcardIconClose, VcardIconCloseMo, VcardButtonSaveMo} from "../../elements/helpers/GfxPaths";
import ProfileApi from "../../api/ProfileApi";
import {connect} from "react-redux";
import {projectSetHasSetVCard} from "../../store/project/projectAction";

class VCardPopup extends React.Component {

    state = {
        isOpen: false,
        title: '',
        lastName: '',
        firstName: '',
        position: '',
        company: '',
        street: '',
        zipcode: '',
        city: '',
        country: '',
        email: '',
        phone: '',
        mobile: '',
        fax: ''
    };

    componentDidMount() {
        this.loadDataFromApi();
    }

    loadDataFromApi = () => {
        ProfileApi.getVCard()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    if (data) {
                        this.setState({
                            title: data.title,
                            lastName: data.lastName,
                            firstName: data.firstName,
                            position: data.position,
                            company: data.company,
                            street: data.street,
                            zipcode: data.zipcode,
                            city: data.city,
                            country: data.country,
                            email: data.email,
                            phone: data.phone,
                            mobile: data.mobile,
                            fax: data.fax
                        });
                    } else {
                        this.setState({
                            title: '',
                            lastName: '',
                            firstName: '',
                            position: '',
                            company: '',
                            street: '',
                            zipcode: '',
                            city: '',
                            country: '',
                            email: '',
                            phone: '',
                            mobile: '',
                            fax: ''
                        });
                    }
                }
            });
    };

    saveData = () => {
        ProfileApi.updateVCard(this.state.title, this.state.lastName, this.state.firstName, this.state.position, this.state.company, this.state.street, this.state.zipcode, this.state.city, this.state.country, this.state.email, this.state.phone, this.state.mobile, this.state.fax)
            .then((response) => {
                this.props.projectSetHasSetVCard();
                this.setState({isOpen: false});
                document.body.classList.remove('noScroll');
            })
    };

    render() {
        return (
            <Fragment>
                <button className="openEditVCard-btn" onClick={() => {
                    this.setState({isOpen: true});
                    document.body.classList.add('noScroll');
                }} type="button">
                    Edit VCard
                </button>
                {this.state.isOpen ? (
                    <div className="VCardPopup">
                        <div className="vcard-container">
                            <button className="close-btn" onClick={() => {
                                this.setState({isOpen: false});
                                this.loadDataFromApi();
                                document.body.classList.remove('noScroll');
                            }} type="button">
                                <img src={VcardIconClose} alt="" className="icon icon-default"/>
                                <img src={VcardIconCloseMo} alt="" className="icon icon-mo"/>
                            </button>
                            <h3>
                                Your vCard Data:
                            </h3>

                            <TextInput label="ACADEMIC TITLE" value={this.state.title}
                                       onChange={(e) => this.setState({title: e.target.value})}/>
                            <TextInput label="PRENAME" value={this.state.firstName}
                                       onChange={(e) => this.setState({firstName: e.target.value})}/>
                            <TextInput label="SURNAME" value={this.state.lastName}
                                       onChange={(e) => this.setState({lastName: e.target.value})}/>
                            <TextInput label="POSITION" value={this.state.position}
                                       onChange={(e) => this.setState({position: e.target.value})}/>
                            <TextInput label="COMPANY" value={this.state.company}
                                       onChange={(e) => this.setState({company: e.target.value})}/>
                            <TextInput label="COMPANY STREET / NR" value={this.state.street}
                                       onChange={(e) => this.setState({street: e.target.value})}/>
                            <TextInput label="COMPANY ZIP" value={this.state.zipcode}
                                       onChange={(e) => this.setState({zipcode: e.target.value})}/>
                            <TextInput label="COMPANY CITY" value={this.state.city}
                                       onChange={(e) => this.setState({city: e.target.value})}/>
                            <TextInput label="COUNTRY" value={this.state.country}
                                       onChange={(e) => this.setState({country: e.target.value})}/>
                            <TextInput label="E-MAIL"
                                       type="email" value={this.state.email}
                                       onChange={(e) => this.setState({email: e.target.value})}/>
                            <TextInput label="TELEPHONE" value={this.state.phone}
                                       onChange={(e) => this.setState({phone: e.target.value})}/>
                            <TextInput label="MOBILE" value={this.state.mobile}
                                       onChange={(e) => this.setState({mobile: e.target.value})}/>
                            <TextInput label="FAX" value={this.state.fax}
                                       onChange={(e) => this.setState({fax: e.target.value})}/>
                            <button type="button" onClick={this.saveData}>
                                <img alt="" className="img img-default" src={VcardButtonSave}/>
                                <img alt="" className="img img-mo" src={VcardButtonSaveMo}/>
                            </button>
                        </div>
                    </div>
                ) : null}
            </Fragment>
        )
    }
}

export default connect(null, {
    projectSetHasSetVCard
})(VCardPopup);
