import React, {Fragment} from 'react';
import 'react-notifications/lib/notifications.css';
import moment from 'moment-timezone';
import { renderToStaticMarkup } from "react-dom/server";
import Authentication from "./pages/Authentication";
import {connect} from "react-redux";
import {authorizationLogout} from "./store/authorization/authorizationAction";
import {Route, Router, Switch} from "react-router";
import Meetings from "./pages/Meetings";
import TopBar from "./elements/navigation/TopBar";
import Footer from "./elements/navigation/Footer";
import Profile from "./pages/Profile";
import AxiosHelper from "./config/axiosHelper";
import globalTranslations from "./translation/global";
import {withLocalize} from "react-localize-redux";
import TimezoneSelectOverlay from "./elements/timezone/TimezoneSelectOverlay";
import WelcomeScreen from "./elements/welcome/WelcomeScreen";
import Privacy from "./pages/Privacy";
import Imprint from "./pages/Imprint";
import {NotificationContainer} from "react-notifications";
import Documents from "./pages/Documents";
import OnTouchNoHoverHelper from "./elements/helpers/OnTouchNoHoverHelper";
import Program from "./pages/Program";
import Meeting from "./pages/Meeting";
import {ToastContainer} from "react-toastify";
import (`./themes/${process.env.REACT_APP_THEME_NAME}/theme.scss`);

class App extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: "English", code: "en"}
            ],
            translations: globalTranslations,
            options: { renderToStaticMarkup }
        });
        this.props.addTranslation(globalTranslations);

        moment.tz.setDefault(this.props.authorization.currentTimezone);
    }

    resizeTimeout = null;

    componentWillMount() {
        AxiosHelper.init();
    }

    componentDidMount() {
        this.setVh();
        window.addEventListener('resize', this.setVhResize);

        OnTouchNoHoverHelper.init();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setVhResize);
    }

    setVhResize = () => {
        if(this.resizeTimeout) clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.setVh, 200);
    };

    setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    render() {
        const {authorization} = this.props;
        try {
            return (
                <div className={`App${authorization.bearerToken ? ' authorized' : ''}`}>
                    {authorization.bearerToken ? (
                        <Router history={this.props.history}>
                            <Fragment>
                                <TopBar/>
                                <Switch>
                                    <Route path="/program" component={Program}/>
                                    <Route path="/imprint" component={Imprint}/>
                                    <Route path="/privacy" component={Privacy}/>
                                    <Route path="/profile" component={Profile}/>
                                    <Route path="/documents" component={Documents}/>
                                    <Route path="/meeting/:id" component={Meeting}/>
                                    <Route path="/" component={Meetings}/>
                                </Switch>
                                <Footer/>
                                <TimezoneSelectOverlay/>
                                <WelcomeScreen/>
                                <ToastContainer
                                  position="bottom-left"
                                  autoClose={5000}
                                  hideProgressBar
                                  newestOnTop
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                  theme="light"
                                />
                            </Fragment>
                        </Router>
                    ) : (
                        <Router history={this.props.history}>
                            <Switch>
                                <Route path="/program" render={() => (
                                  <Fragment>
                                      <TopBar brandLinkTo="https://equityforum.de"/>
                                      <div className="program-unauthorized-padding-top"/>
                                      <Program/>
                                      <div className="program-unauthorized-padding-bottom"/>
                                      <Footer/>
                                  </Fragment>
                                )}/>
                                <Route path="/documents" render={() => (
                                  <Fragment>
                                      <TopBar brandLinkTo="https://equityforum.de"/>
                                      <div className="program-unauthorized-padding-top"/>
                                      <Documents/>
                                      <div className="program-unauthorized-padding-bottom"/>
                                      <Footer/>
                                  </Fragment>
                                )}/>
                                <Route path="/imprint" component={Imprint}/>
                                <Route path="/privacy" component={Privacy}/>
                                <Route path="/" component={Authentication}/>
                            </Switch>
                        </Router>
                    )}
                    <NotificationContainer/>
                </div>
            );
        } catch(e) {
            console.log(e);
            localStorage.clear();
            window.location.reload();
        }
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization
});

export default withLocalize(connect(mapStateToProps, {
    authorizationLogout
})(App));
