const IconOptions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29.061" height="29.062" viewBox="0 0 29.061 29.062">
    <g id="Gruppe_3090" data-name="Gruppe 3090" transform="translate(0)">
      <path id="Pfad_1281" data-name="Pfad 1281"
            d="M119.981,0a14.531,14.531,0,1,0,14.53,14.531A14.547,14.547,0,0,0,119.981,0m0,26.731a12.2,12.2,0,1,1,12.2-12.2,12.214,12.214,0,0,1-12.2,12.2"
            transform="translate(-105.45 0)" fill="currentColor"/>
      <path id="Pfad_1282" data-name="Pfad 1282"
            d="M126.454,13.447a6.017,6.017,0,0,0-.491-1.193l1.225-1.225A7.805,7.805,0,0,0,124.755,8.6L123.53,9.822a6.075,6.075,0,0,0-1.193-.492V7.593a7.6,7.6,0,0,0-3.441,0V9.33a6.106,6.106,0,0,0-1.194.492L116.48,8.6a7.8,7.8,0,0,0-2.433,2.433l1.224,1.225a6.072,6.072,0,0,0-.491,1.193h-1.737a7.6,7.6,0,0,0,0,3.441h1.737a6.017,6.017,0,0,0,.491,1.193l-1.224,1.225a7.786,7.786,0,0,0,2.433,2.433l1.224-1.224A6.047,6.047,0,0,0,118.9,21v1.737a7.6,7.6,0,0,0,3.441,0V21a6.072,6.072,0,0,0,1.193-.491l1.225,1.224a7.8,7.8,0,0,0,2.433-2.433l-1.225-1.225a6.017,6.017,0,0,0,.491-1.193h1.737a7.6,7.6,0,0,0,0-3.441Zm-5.838,3.664a1.943,1.943,0,1,1,1.943-1.943,1.943,1.943,0,0,1-1.943,1.943"
            transform="translate(-106.087 -0.637)" fill="currentColor"/>
    </g>
  </svg>
)

export default IconOptions;