import React from 'react';
import moment from 'moment-timezone';

import {TimeZonePopupButtonSave, TimeZonePopupButtonSaveMo, TimeZonePopupButtonStart, TimeZonePopupButtonStartMo} from "../helpers/GfxPaths"
import {connect} from "react-redux";
import {
    authorizationCloseSelectTimezone,
    authorizationSetSelectedTimezone
} from "../../store/authorization/authorizationAction";
import TimezonePicker from "../form/TimeZonePicker/TimeZonePicker";

class TimezoneSelectOverlay extends React.Component {
    state = {
        currentTime: moment()
    };

    currentTimeInterval;

    componentDidMount() {
        this.currentTimeInterval = setInterval(this.updateCurrentTime, 5000);
        if (this.props.authorization.showSelectTimezone) {
            document.body.classList.add(['noScroll']);
        }
    }

    componentWillUnmount() {
        clearInterval(this.currentTimeInterval);
    }

    updateCurrentTime = () => {
        this.setState({
            currentTime: moment()
        });
    };

    changeTimeZone = (val) => {
        this.props.authorizationSetSelectedTimezone(val);
        window.location.reload();
    };

    render() {
        const {authorization} = this.props;
        return authorization.showSelectTimezone ? (
            <div className={'TimezoneSelectOverlay d-flex'}>
                <div className="timezones-container">
                    <div className="select-container">
                        <h3>
                            Your current<br/>
                            timezone is set to:
                        </h3>
                        <div className="current-timezone">
                            <div className="time">
                                {moment(this.state.currentTime).format('HH:mm')}<br/>
                            </div>
                            <div className="location">
                                {authorization.currentTimezone.replace('_', ' ')}
                            </div>
                        </div>
                        <div className="select-timezone">
                            <div className="text">
                                Are you in a different timezone?
                            </div>
                            <TimezonePicker onChange={this.changeTimeZone}/>
                            <button onClick={this.props.authorizationCloseSelectTimezone}>
                                <img alt="" className="img img-default" src={TimeZonePopupButtonStart}/>
                                <img alt="" className="img img-mo" src={TimeZonePopupButtonStartMo}/>
                                <img alt="" className="img img-profile-default d-none" src={TimeZonePopupButtonSave}/>
                                <img alt="" className="img img-mo img-profile-mo d-none" src={TimeZonePopupButtonSaveMo}/>
                            </button>
                        </div>
                    </div>
                    <div className="tip-container">
                        <div className="tipContainer-borderB">
                            <div className="tipContainer-borderW">
                                <h4>
                                    Recommendations for new users:
                                </h4>
                                <div className="text">
                                    Check your profile page to turn on push notifications to remind you about upcoming meetings and
                                    to do a test video conference.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

const mapStateToProps = state => ({
    authorization: state.authorization
});

export default connect(mapStateToProps, {
    authorizationSetSelectedTimezone, authorizationCloseSelectTimezone
})(TimezoneSelectOverlay);
